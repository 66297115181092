import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'
import DepartmentAction from '../../../../component/data-table-action/table_action/super-admin-action/DepartmentAction'
import { departmentListRes, departmentListTodo } from '../../../../redux/slices/super-admin-slices/master/department/DepartmentList'
import DataTable from '../../../../component/data-table/DataTable'

export default function DepartmentList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const departmentList = useSelector(state => state && state.DepartmentList && state.DepartmentList.data && state.DepartmentList.data.data)
  
    useEffect(() => {
        setLoading(true)
        dispatch(departmentListTodo()).then(() => departmentList_res())
    }, [bool])

    const departmentList_res = () => {
        if (departmentListRes && departmentListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'DEPARTMENT',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            width: 120

            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: DepartmentAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">

                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            departmentList && departmentList.length ?
                                <DataTable
                                    rowData={departmentList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                />
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer />

        </>
    )
}
