import React from 'react'
import AddDealerForm from '../../../component/form/super-admin-form/dealer-form/AddDealer'

export default function AddDealer() {
  return (
    <>
     <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Dealer</h3>
                        </div>
                        <AddDealerForm />
                    </div>
                </div>
            </div>
    </>
  )
}
