import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { BiChevronRight } from 'react-icons/bi';

export default function HowToUse() {
    return (
        <div className="container-fluid">
            <div className='layout_head'>
                <h3> FAQ </h3>
            </div>
            <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordion-header-custom"><BiChevronRight className='mb-1 me-1' size={22} />How to login.</Accordion.Header>
                    <Accordion.Body className='bg-light p-4'>
                        <video width="400" height="300" controls>
                            <source src="/faq/login.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0.5">
                    <Accordion.Header className="accordion-header-custom"><BiChevronRight className='mb-1 me-1' size={22} />Understanding dashboard.</Accordion.Header>
                    <Accordion.Body className='bg-light p-4'>
                        <video width="400" height="300" controls>
                            <source src="/faq/dashboard.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to add task for myself</Accordion.Header>
                    <Accordion.Body>
                        <video width="400" height="300" controls>
                            <source src="/faq/add-task-myself.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1.5">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to complete task</Accordion.Header>
                    <Accordion.Body>
                        <video width="400" height="300" controls>
                            <source src="/faq/complete-task.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to add task for others and approve or reject it</Accordion.Header>
                    <Accordion.Body>
                        <video width="400" height="300" controls>
                            <source src="/faq/tasks-for-others.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to find and filter tasks</Accordion.Header>
                    <Accordion.Body>
                    <video width="400" height="300" controls>
                            <source src="/faq/task-list.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to Schedule Meeting</Accordion.Header>
                    <Accordion.Body>
                    <video width="400" height="300" controls>
                            <source src="/faq/schedule-meeting.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4.5">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to Update or Complete Meeting</Accordion.Header>
                    <Accordion.Body>
                    <video width="400" height="300" controls>
                            <source src="/faq/complete-update-meeting.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to add, edit, delete and assign Meeting MOM?</Accordion.Header>
                    <Accordion.Body>
                    <video width="400" height="300" controls>
                            <source src="/faq/mom-task.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="6">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to view and add files in meeting</Accordion.Header>
                    <Accordion.Body>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header> <BiChevronRight className='mb-1 me-1' size={22} />How to cancel meeting</Accordion.Header>
                    <Accordion.Body>

                    </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="8">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to chat</Accordion.Header>
                    <Accordion.Body>
                    <video width="400" height="300" controls>
                            <source src="/faq/chat.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header><BiChevronRight className='mb-1 me-1' size={22} />How to edit profile and change password</Accordion.Header>
                    <Accordion.Body>
                    <video width="400" height="300" controls>
                            <source src="/faq/profile.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />

                            Your browser does not support the video tag.
                        </video>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}
