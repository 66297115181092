import React from 'react'
import UpdateVersionForm from '../../../component/form/admin-form/app-version/UpdateVersion'

export default function UpdateVersion() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Version</h3>
                            <UpdateVersionForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
