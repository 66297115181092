import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addCustomerPermissionRes;
export const addCustomerPermissionTodo = createAsyncThunk('addCustomerPermission', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_customerpermission_module',
            data: data,
            headers: { "Content-Type": "application/json" }
        })
        return addCustomerPermissionRes = res.data

    } catch (error) {
        return addCustomerPermissionRes = error.response.data
    }
})



