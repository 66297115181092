import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addCustomerRes;
export const addCustomerTodo = createAsyncThunk('addCustomer', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_mastercompany',
            data: data,
            headers: { "Content-Type": "multipart/form-data"}
        })
        return addCustomerRes = res.data
        
    } catch (error) {
        return addCustomerRes = error.response.data
    }
})