import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import LoginButton from '../../../buttons/LoginButton'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMail } from "react-icons/ai";
import logo from '../../../../assest/images/rexoteams.png'
import { RiLockPasswordLine } from "react-icons/ri";
import { logInSchema } from './validation';
import { logInRes, logInTodo } from '../../../../redux/slices/admin-slices/login-slice/Login';
import { adminSidebarRes, adminSidebarTodo } from '../../../../redux/slices/admin-slices/admin-sidebar/AdminSidebar';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const adminSidebar = useSelector(state => state && state.AdminSidebar && state.AdminSidebar.data && state.AdminSidebar.data.data)
  const [spiner, setSpiner] = useState(false)
  const location = useLocation()
  // console.log(location)

  const initiaValues = {
    email: '',
    password: ''
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initiaValues,
    validationSchema: logInSchema,

    onSubmit: (values, action) => {
      // console.log(values)
      setSpiner(true)
      dispatch(logInTodo(values)).then(() => loginInFun())
    }
  })

  let mergedData = [];
  let routeURL = []

  const loginInFun = () => {
    if (logInRes && logInRes.status) {
      toast.success(logInRes && logInRes.message, { position: "bottom-right" });
      localStorage.setItem('id', logInRes.data[0].id)
      localStorage.setItem('name', logInRes.data[0].name)
      localStorage.setItem('userRole', logInRes.data[0].userRole)
      localStorage.setItem('profileImage', logInRes.data[0].profileImage)
      localStorage.setItem('customerId', logInRes.data[0].fk_master_comp_id)
      localStorage.setItem('userTypeId', logInRes.data[0].fk_user_type_id)
      localStorage.setItem('type', logInRes.data[0].type)
      dispatch(adminSidebarTodo()).then(() => adminSidebarFun())


      // navigate('/admin/dashboard')
    } else {
      toast.error(logInRes && logInRes.message, { position: "bottom-right" });
      setSpiner(false)
    }
  }
  const adminSidebarFun = () => {
    if (adminSidebarRes && adminSidebarRes.status) {
      navigate('/admin/dashboard')
    } else {
      toast.error("Some Thing Went Wrong....", { position: "bottom-right" });
    }
  }

  adminSidebar && adminSidebar.forEach(item => {
    const moduleId = item && item.customermodule && item.customermodule.fk_master_module_id;
    const moduleIndex = mergedData.findIndex(module => module.fk_master_module_id === moduleId);
    if (moduleIndex === -1) {
      mergedData.push({
        "fk_master_module_id": item && item.customermodule && item.customermodule.fk_master_module_id,
        "fk_master_comp_id": item && item.customermodule && item.customermodule.fk_master_comp_id,
        "module_display_name": item && item.customermodule && item.customermodule.module_display_name,
        "customer_module_icon": item && item.customermodule && item.customermodule.customer_module_icon,
        "menu_cstatus": item && item.customermodule && item.customermodule.menu_cstatus,
        "customeraction": [{
          "fk_cust_master_module_id": item && item.customeraction && item.customeraction.fk_cust_master_module_id,
          "fk_master_module_id": item && item.customeraction && item.customeraction.fk_master_module_id,
          "fk_master_action_id": item && item.customeraction && item.customeraction.fk_master_action_id,
          "fk_master_comp_id": item && item.customeraction && item.customeraction.fk_master_comp_id,
          "action_display_name": item && item.customeraction && item.customeraction.action_display_name,
          "action_url_name": item && item.customeraction && item.customeraction.action_url_name,
          "menu_status": item && item.customeraction && item.customeraction.menu_status,
        }]
      });
    } else {
      mergedData[moduleIndex] && mergedData[moduleIndex].customeraction && mergedData[moduleIndex].customeraction.push({
        "fk_cust_master_module_id": item && item.customeraction && item.customeraction.fk_cust_master_module_id,
        "fk_master_module_id": item && item.customeraction && item.customeraction.fk_master_module_id,
        "fk_master_action_id": item && item.customeraction && item.customeraction.fk_master_action_id,
        "fk_master_comp_id": item && item.customeraction && item.customeraction.fk_master_comp_id,
        "action_display_name": item && item.customeraction && item.customeraction.action_display_name,
        "action_url_name": item && item.customeraction && item.customeraction.action_url_name,
        "menu_status": item && item.customeraction && item.customeraction.menu_status,
      });
    }
  })

  mergedData && mergedData.forEach(module => {
    // Iterate over the customeraction array for each module
    module.customeraction.forEach(action => {
      // Extract action_display_name and action_url_name
      const { action_display_name, action_url_name } = action;
      // Push to routeURL array
      routeURL.push({ action_display_name, action_url_name });
    });
  });
  // console.log("routeURL",routeURL)
  localStorage.setItem("actions", JSON.stringify(routeURL))



  return (
    <>
      <Form onSubmit={handleSubmit}>

        <div className="input-group  input_section" style={{ position: 'relative' }}>
          <span className='input_icon'> <AiOutlineMail className='mb-1 me-2 ' style={{ color: "#ff2b49" }} size={20} /></span>
          <input type="text" className="form-control form-login-input login_placeholder "
            placeholder="Username"
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

        </div>
        {errors.email && touched.email ? (<span className='text-danger ' >{errors.email}</span>) : null}
        <div className="input-group mt-3 input_section" style={{ position: 'relative' }}>
          <span className='input_icon'> <RiLockPasswordLine style={{ color: "#ff2b49" }} className='mb-1 me-2' size={20} /></span>
          <input type="password" className="form-control form-login-input "
            placeholder="Password"
            name='password'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />

        </div>
        {errors.password && touched.password ? (<span className='text-danger text-start' >{errors.password}</span>) : null}

        <div className='mt-4 d-flex justify-content-center'>
          <LoginButton name={'Login'} spiner={spiner} />
        </div>
        <div className='finalLogo_section mt-4'>
          <span style={{color:'grey'}}>Powered By <br /></span>
          <img src={logo} alt="" className='img-fluid finalLogo mt-1' />
        </div>
      </Form>
      <ToastContainer />
    </>
  )
}
