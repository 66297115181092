import React from 'react'
import { Modal } from 'react-bootstrap';
import SaveButton from '../../../component/buttons/SaveButton';
import TextArea from '../../../component/input_filed/TextArea';
import { ApproveRejectTaskRes, ApproveRejectTaskTodo } from '../../../redux/slices/admin-slices/todo/ApproveRejectTask';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';

export default function TodoReject(props) {
    const { data, reject, setReject, bool, setBool } = props
    const handleClose = () => setReject(false);
    const dispatch = useDispatch()
    console.log(data)

    const AddRemarkSchema = Yup.object({
        remark: Yup.string().required("Remark Is Required"),
    });
    const initialValues = {
        remark: '',
        task_id: data?._id,
        status: 'rejected'

    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddRemarkSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            dispatch(ApproveRejectTaskTodo(values)).then(() => add_res(action))
            // console.log(values);
        }
    });
    const add_res = () => {
        if (ApproveRejectTaskRes?.status) {
            toast.success(ApproveRejectTaskRes?.message, { position: 'bottom-right' })
            setBool(!bool)
            setReject(false);
        } else {
            toast.error(ApproveRejectTaskRes?.message, { position: 'bottom-right' })

        }
    }

    return (
        <>
            <Modal show={reject} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Reject Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='p-3'>
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className="row">
                                <div className='col-md-12'>
                                    <TextArea
                                        label_name='Remark'
                                        placeholder=''
                                        rows={'2'}
                                        name='remark'
                                        value={values.remark || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.remark && touched.remark ? (<span className='text-danger form_label' >{errors.remark}</span>) : null}
                                    />

                                </div>
                                <div className=' text-center '>
                                    <SaveButton name={'Save'} />
                                </div>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    )
}
