import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserTypeListRes, adminUserTypeListTodo } from '../../../../redux/slices/admin-slices/admin-master/admin-type/AdminTypeList';
import Loader from '../../../../component/loader/Loader';
import DataTable from '../../../../component/data-table/DataTable';
import AdminUserTypeAction from '../../../../component/data-table-action/table_action/admin-action/admin-master-action/AdminUserTypeAction';



export default function AdminUserTypeList(props) {

    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const adminUserTypeList = useSelector(state => state && state.AdminTypeList && state.AdminTypeList.data && state.AdminTypeList.data.data)

    const userTypeList_res = () => {
        if (adminUserTypeListRes && adminUserTypeListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(adminUserTypeListTodo()).then(() => userTypeList_res())
    }, [bool])


    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: ' USER TYPE ',
            field: 'type',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
     
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge   text-bg-success'>Active</span> : <span className='badge   text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: AdminUserTypeAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
            },
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
           
                        <div className='layout_head'>
                            <h3> User Type List</h3>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <Loader />
                            </div>
                            :
                            adminUserTypeList && adminUserTypeList.length ?
                                < div >
                                    < DataTable
                                        rowData={adminUserTypeList}
                                        columnDefs={columnDefs}
                                        height={'50vh'}
                                    />
                                </div>
                                 :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        } 

                
        </>
    )
}
