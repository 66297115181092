import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Text from '../../../input_filed/Text'
import TextArea from '../../../input_filed/TextArea'
import SingleSelect from '../../../input_filed/SingleSelect'
import SaveButton from '../../../buttons/SaveButton'
import SelectStatus from '../../../input_filed/SelectStatus'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'
import { AddModuleSchema } from './Validation'
import { addModuleRes, addModuleTodo } from '../../../../redux/slices/super-admin-slices/module/AddModule'

export default function ModuleForm() {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const showMenu_option = [
        { "value": '1', "label": "Yes" },
        { "value": '0', "label": "No" }
    ]

    const initialValues = {
        name: '',
        module_url: "",
        priority: "",
        description: "",
        icon: "",
        show_in_menu: "",
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddModuleSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(addModuleTodo(values)).then(() => addModuleFun(action))
        }
    })

    const addModuleFun = (action) => {
        if (addModuleRes && addModuleRes.status) {
            setLoading(false)
            toast.success(addModuleRes && addModuleRes.message, { position: "bottom-right" })
            action.resetForm()
            setTimeout(() => {
                navigate('../superadmin/module-list')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(addModuleRes && addModuleRes.message, { position: "bottom-right" });
        }
    }

    return (
        <>

            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Module Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Module URL'
                                    placeholder=''
                                    name='module_url'
                                    value={values.module_url || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.module_url && touched.module_url ? (<span className='text-danger form_label' >{errors.module_url}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Module Priority'
                                    placeholder=''
                                    name='priority'
                                    value={values.priority || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.priority && touched.priority ? (<span className='text-danger form_label' >{errors.priority}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12'>
                                <TextArea
                                    label_name='Module Description'
                                    placeholder=''
                                    disabled={false}
                                    rows={1}
                                    name='description'
                                    value={values.description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.description && touched.description ? (<span className='text-danger form_label' >{errors.description}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <Text
                                    label_name='Module Icon'
                                    placeholder=''
                                    name='icon'
                                    value={values.icon || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.icon && touched.icon ? (<span className='text-danger form_label' >{errors.icon}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Show In Menu'
                                    disabled={false}
                                    option={showMenu_option}
                                    name='show_in_menu'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('show_in_menu', e.value);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.show_in_menu && touched.show_in_menu ? (<span className='text-danger form_label' >{errors.show_in_menu}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={""}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
