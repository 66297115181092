import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminAddGroupRes;
export const adminAddGroupTodo = createAsyncThunk('adminAddGroup', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_group',
            data: {
                "name":data.name,
                "status":data.status,
                'user_id':data?.users?.map(item => item.value),
                "added_by":localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminAddGroupRes = res.data
        
    } catch (error) {
        return adminAddGroupRes = error.response.data
    }
})


export var adminUpdateGroupRes;
export const adminUpdateGroupTodo = createAsyncThunk('adminUpdateGroup', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_group',
            data: {
                "id":data.id,
                "name":data.name,
                "status":data.status,
                'user_id':data?.users?.map(item => item.value),
                "modified_by":localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminUpdateGroupRes = res.data
        
    } catch (error) {
        return adminUpdateGroupRes = error.response.data
    }
})