import React, { useEffect, useState } from 'react'
import userlogo from '../../assest/images/profile.png'
import { MdDashboard, MdLeaderboard } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as Icons from 'react-icons/fa';
import userLogo from '../../../src/assest/images/profile.png'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, NavLink, json } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { adminSidebarRes, adminSidebarTodo } from '../../redux/slices/admin-slices/admin-sidebar/AdminSidebar';
import { Accordion } from 'react-bootstrap';

export default function AdminSidebar() {

    const dispatch = useDispatch();
    const adminSidebar = useSelector(state => state && state.AdminSidebar && state.AdminSidebar.data && state.AdminSidebar.data.data)
    const [loading, setLoading] = useState(false)
    const [sidebar, setSidebar] = useState([])
    // console.log("adminSidebar", adminSidebar)

    useEffect(() => {
        setLoading(true)
        dispatch(adminSidebarTodo()).then(() => adminSidebarFun())
        // sidebarResponse()
    }, [])

    const Icon = ({ name }) => {
        const trimmedName = name.trim(); // Trim extra spaces from the icon name
        const IconComponent = Icons[trimmedName];
        // console.log("icon", trimmedName);

        if (!IconComponent) {
            // console.error(`Icon "${trimmedName}" not found.`);
            return null;
        }

        return <IconComponent className='mb-1 me-2' style={{ color: 'white' }} size={20} />;
    };

    let mergedData = [];
    let routeURL = []


    const adminSidebarFun = () => {
        if (adminSidebarRes && adminSidebarRes.status) {

            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    adminSidebar && adminSidebar.forEach(item => {
        const moduleId = item && item.customermodule && item.customermodule.fk_master_module_id;
        const moduleIndex = mergedData.findIndex(module => module.fk_master_module_id === moduleId);
        if (moduleIndex === -1) {
            mergedData.push({
                "fk_master_module_id": item && item.customermodule && item.customermodule.fk_master_module_id,
                "fk_master_comp_id": item && item.customermodule && item.customermodule.fk_master_comp_id,
                "module_display_name": item && item.customermodule && item.customermodule.module_display_name,
                "customer_module_icon": item && item.customermodule && item.customermodule.customer_module_icon,
                "menu_cstatus": item && item.customermodule && item.customermodule.menu_cstatus,
                "customeraction": [{
                    "fk_cust_master_module_id": item && item.customeraction && item.customeraction.fk_cust_master_module_id,
                    "fk_master_module_id": item && item.customeraction && item.customeraction.fk_master_module_id,
                    "fk_master_action_id": item && item.customeraction && item.customeraction.fk_master_action_id,
                    "fk_master_comp_id": item && item.customeraction && item.customeraction.fk_master_comp_id,
                    "action_display_name": item && item.customeraction && item.customeraction.action_display_name,
                    "action_url_name": item && item.customeraction && item.customeraction.action_url_name,
                    "menu_status": item && item.customeraction && item.customeraction.menu_status,
                }]
            });
        } else {
            mergedData[moduleIndex] && mergedData[moduleIndex].customeraction && mergedData[moduleIndex].customeraction.push({
                "fk_cust_master_module_id": item && item.customeraction && item.customeraction.fk_cust_master_module_id,
                "fk_master_module_id": item && item.customeraction && item.customeraction.fk_master_module_id,
                "fk_master_action_id": item && item.customeraction && item.customeraction.fk_master_action_id,
                "fk_master_comp_id": item && item.customeraction && item.customeraction.fk_master_comp_id,
                "action_display_name": item && item.customeraction && item.customeraction.action_display_name,
                "action_url_name": item && item.customeraction && item.customeraction.action_url_name,
                "menu_status": item && item.customeraction && item.customeraction.menu_status,
            });
        }
    })

    mergedData && mergedData.forEach(module => {
        // Iterate over the customeraction array for each module
        module.customeraction.forEach(action => {
            // Extract action_display_name and action_url_name
            const { action_display_name, action_url_name } = action;
            // Push to routeURL array
            routeURL.push({ action_display_name, action_url_name });
        });
    });
    // console.log("routeURL",routeURL)
    localStorage.setItem("actions", JSON.stringify(routeURL))



    return (
        <>
            <div id="dashboard-sidebar">
                <div className=" profile-header ">
                    <div className='text-center sidebar-logo-section '>
                  {  localStorage.getItem('profileImage') === '' ? <img src={userLogo} alt="img" className='img-fluid user-img' />:  <img src={process.env.REACT_APP_IMAGE_URL + localStorage.getItem('profileImage')} alt="img" className='img-fluid user-img' />}
                        <h5>{localStorage.getItem('name')} </h5>
                    </div>
                </div>
                <div className="offcanvas-body profile-sidebar-body ">
                    <PerfectScrollbar>
                        {/* <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item new-accordion">
                                <Link to="../admin/dashboard">
                                    <button className=" collapsed acc-btn " type="button" >
                                        <MdDashboard className='mb-1 me-2' size={20} /> Dashboard
                                    </button>
                                </Link>
                            </div>

                            {mergedData && mergedData.filter(data => data.menu_cstatus === '1').map((module, index) => {
                                const moduleId = module && module.fk_master_module_id.toString();
                                return (
                                    <div className="accordion-item new-accordion" key={index}>
                                        <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target={'#flush-master' + `${moduleId}`} aria-expanded="false" aria-controls={'flush-master' + `${moduleId}`} >
                                            <Icon name={module && module.customer_module_icon} />  {module && module.module_display_name}
                                        </button>
                                        <div id={'flush-master' + `${moduleId}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                                            <div className="accordion-body">
                                                <ul className='acc-list' >
                                                    {module && module.customeraction && module.customeraction.filter(data => data.menu_status === '1').map((custVal, index) =>
                                                        <Link to={custVal.action_url_name}>    <li key={index}><BiChevronRight className='mb-1 me-0' size={20} />  {custVal.action_display_name}</li></Link>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div> */}
                        <Accordion >
                            <Accordion.Item className='acc_btn' >
                                <Link to="../admin/dashboard">   <Accordion.Header >  <MdDashboard className='mb-1 me-2' size={20} /> Dashboard</Accordion.Header> </Link>
                            </Accordion.Item >
                            {mergedData && mergedData.filter(data => data.menu_cstatus === '1').map((module, index) => {
                                const moduleId = module && module.fk_master_module_id.toString();
                                return (
                                    <Accordion.Item className='acc_btn' eventKey={index.toString()} key={index}>
                                        <Accordion.Header >  <Icon name={module && module.customer_module_icon} />  {module && module.module_display_name}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="accordion-body">
                                                <ul className='acc-list' >
                                                    {module && module.customeraction && module.customeraction.filter(data => data.menu_status === '1').map((custVal, index) =>
                                                        <NavLink to={custVal.action_url_name} > <li key={index}>  <BiChevronRight className='mb-1 me-0' size={20} />  {custVal.action_display_name}</li></NavLink>
                                                    )}
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>)
                            })}

                        </Accordion>
                    </PerfectScrollbar>
                </div>
            </div>
        </>
    )
}
