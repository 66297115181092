import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var versionDetailsRes;
export const versionDetailsTodo = createAsyncThunk('versionDetails', async (id) => {

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'version_details',
            data: { "deviceType": "" },
            headers: { "Content-Type": "application/json" }
        })
        return versionDetailsRes = res.data

    } catch (error) {
        return versionDetailsRes = error.response.data
    }
})

const versionDetailsSlice = createSlice({
    name: 'versionDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(versionDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(versionDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default versionDetailsSlice.reducer; 