import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var PostCommentRes;
export const PostCommentTodo = createAsyncThunk('PostComment', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'Post_comment_details',
            headers: { "Content-Type": "application/json" },
            data: {
                "post_id":id,
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return PostCommentRes = res.data

    } catch (error) {
        return PostCommentRes = error.response.data
    }
});

const PostCommentSlice = createSlice({
    name: 'PostComment',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(PostCommentTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(PostCommentTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default PostCommentSlice.reducer; 