import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { adminDeleteUserTypeRes, adminDeleteUserTypeTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-type/AdminDeleteType'

export default function AdminUserTypeAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    var permission = JSON.parse(localStorage.getItem('actions'))

    const edit_usertype = () => {
        navigate('../admin/update-usertype', { state: props.data && props.data })
    }

    const del_usertype = () => {

        Swal.fire({
            title: "User Type Delete",
            text: "Are You Sure Want To Delete This User Type ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminDeleteUserTypeTodo(props.data._id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (adminDeleteUserTypeRes && adminDeleteUserTypeRes.status) {
            setBool(!bool)
            Swal.fire(
                "User Type",
                'User Type Deleted Successfully',
                'success'
            )
        } else {
            toast.error(adminDeleteUserTypeRes && adminDeleteUserTypeRes.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                {permission && permission.some(val => val.action_display_name === 'Update User Type') ?
                    <LuFileEdit size={20} className='edit text-success ms-3'
                        onClick={() => edit_usertype()}
                        data-tooltip-id='edit'
                        data-tooltip-content="Edit User Type!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ""}
                {permission && permission.some(val => val.action_display_name === 'Delete User Type') ?
                    <LuTrash2 size={20} className='delete text-danger ms-3'
                        onClick={() => del_usertype()}
                        data-tooltip-id='del'
                        data-tooltip-content="Delete User Type!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ""}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
