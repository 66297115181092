import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaRegComment, FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineGridOn } from 'react-icons/md'
import { useLocation } from 'react-router-dom';
import { PostDetailsRes, PostDetailsTodo } from '../../../redux/slices/admin-slices/post/PostDetails';
import Loader from '../../../component/loader/Loader';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PostCommentRes, PostCommentTodo } from '../../../redux/slices/admin-slices/post/PostCommentList';


export default function PostDetails() {

    const { state } = useLocation()
    var postId = state && state._id
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const detailsData = useSelector(state => state && state.PostDetails && state.PostDetails.data && state.PostDetails.data.data && state.PostDetails.data.data.postData && state.PostDetails.data.data.postData[0])
    const postComment = useSelector(state => state && state.PostComment && state.PostComment.data && state.PostComment.data.data)
    console.log("postComment", postComment)

    useEffect(() => {
        setLoading(true)
        dispatch(PostDetailsTodo(postId)).then(() => details_res())

    }, [])

    const details_res = () => {
        if (PostDetailsRes && PostDetailsRes.status) {
            dispatch(PostCommentTodo(postId)).then(() => comment_res())
        } else {
            setLoading(false)
        }
    }

    const comment_res = () => {
        if (PostCommentRes && PostCommentRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <div className="container-fluid">
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='layout_head'>
                                <h3> Post Details</h3>
                            </div>
                            <div className='details_sidebar'>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <p>User Name </p>
                                    <span>{detailsData && detailsData.users && detailsData.users && detailsData.users[0] && detailsData.users[0].name}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Post Type </p>
                                    <span>{detailsData && detailsData.post_type}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Post Date </p>
                                    <span>{new Date(detailsData && detailsData.post_date).toLocaleDateString('en-GB')}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Likes </p>
                                    <span>{detailsData && detailsData.likesCount}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Comments </p>
                                    <span>{postComment && postComment.length}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Status </p>
                                    <span>{detailsData && detailsData.post_status}</span>
                                </div>
                          
                            </div>
                        </div>
                        <div className="col-md-9">
                            <Tabs
                                defaultActiveKey="Posts"
                                id="fill-tab-example"
                                className="mb-3 piils_btn"
                                fill
                            >
                                <Tab eventKey="Posts" title={<span> Post<MdOutlineGridOn className='mb-1 ms-1' size={18} /></span>}>
                                    <div className="details_section">
                                        <PerfectScrollbar>
                                            <div className='ps-3 pe-3'>
                                                <h4>{detailsData && detailsData.post_title}</h4>
                                                <h6>{detailsData && detailsData.post_description}</h6>
                                                <div className="row ms-0 me-0">
                                                    {detailsData && detailsData.postfiles && detailsData.postfiles.map((value, index) =>
                                                        detailsData.post_type === 'image' ?
                                                            <div className="col-md-4 mt-2">
                                                                <div className="post_img_section">
                                                                    <img src={process.env.REACT_APP_IMAGE_URL + `${value.post_file}`} className='img-fluid post_img' alt="img" />
                                                                </div>
                                                            </div> :
                                                            detailsData.post_type === 'video' ?
                                                                    <div className="col-md-6 mt-2" key={index} >
                                                                        <div className="text-center" >
                                                                            {/* <iframe src={process.env.REACT_APP_IMAGE_URL + `${value.media_name}`} title="YouTube video" allowFullScreen style={{ minWidth: '450px', minHeight: '300px' }}></iframe> */}
                                                                            <video width="400" height="300" controls>
                                                                                <source src={process.env.REACT_APP_IMAGE_URL + `${value.post_file}`} style={{ borderRadius: '15px' }} type="video/mp4" />

                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </div>
                                                                    </div> : <></>
                                                    )}
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </Tab>
                                <Tab eventKey="Comments" title={<span> Comments<FaRegComment className='mb-1 ms-1' size={18} /></span>}>
                                    <div className="details_section">
                                        <PerfectScrollbar>
                                            <div className='ps-3 pe-3'>
                                            {postComment && postComment.length ?
                                                        <div className="row ms-0 me-0">
                                                            {postComment && postComment.map((value1, index) =>
                                                                <div className="col-md-12 mt-2 mb-1" key={index}>
                                                                    <div className='comment_section'>
                                                                        <div className='d-flex align-items-center ' >
                                                                            <h6 style={{ textTransform: "capitalize" }}><FaUserCircle size={22} className='mb-1 me-2' style={{ color: "#4a4c50" }} />{value1.user_id && value1.user_id.name}</h6>
                                                                            <p className='ms-2'>( Date : {value1.createdAt === null ? '-' : new Date(value1.createdAt).toLocaleDateString('en-GB')} ) </p>
                                                                        </div>
                                                                        <div className='mt-1 '>
                                                                            <span style={{ textTransform: "capitalize" }}>{value1.description}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            )}

                                                        </div> :
                                                        <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
                                                            <h3 style={{ color: "#9d9d9d" }}> Post Comments Empty</h3>
                                                        </div>
                                                    }
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                    </div>}
            </div>
        </>
    )
}
