import * as Yup from "yup";

export const PasswordSchema = Yup.object({
    new_psw: Yup.string().required('Password is required'),
    confirm_psw: Yup.string()
    .oneOf([Yup.ref('new_psw'), null], 'Passwords must match')

}) 

export const DealerSchema = Yup.object({

    name: Yup.string().required("Enter Dealer Name"),
    email: Yup.string().required(" Enter Email"),
    psw: Yup.string().required(" Enter  Password"),
    phn: Yup.string().required("Enter Mobile Number"),
    country: Yup.string().required("Select Country"),
    state: Yup.string().required("Select State"),
    city: Yup.string().required("Select City"),
    status: Yup.string().required("Select Status"),

});
export const UpdateDealerSchema = Yup.object({

    name: Yup.string().required("Enter Dealer Name"),
    email: Yup.string().required(" Enter Email"),
    status: Yup.string().required("Select Status"),

});