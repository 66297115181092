import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var actionListRes;
export const actionListTodo = createAsyncThunk('actionList', async (data) => {

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'masteraction_list',
            headers: { "Content-Type": "application/json" },

        })
        return actionListRes = res.data

    } catch (error) {
        return actionListRes = error.response.data
    }
});

const actionListSlice = createSlice({
    name: 'actionList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(actionListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(actionListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default actionListSlice.reducer; 