import React from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

export default function MultiSelect(props) {

    const { label_name, placeholder, disabled, closeMenu, option, name, defaultValue, onChange, onBlur, error } = props

    return (
        <>
            <Form.Group className="mb-3" controlId="">
                <Form.Label className='form_label'>{label_name}</Form.Label>
                <Select
                    closeMenuOnSelect={closeMenu}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    options={option}
                    className='react_select'
                    classNamePrefix="select"
                    name={name}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onBlur={onBlur}
                    isMulti
                />
                {error}
            </Form.Group>
        </>
    )
}
