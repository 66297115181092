import React, { useState } from 'react'
import AdminUserTypeList from './AdminUserTypeList'
import AdminAddTypeForm from '../../../../component/form/admin-form/admin-master-form/admin-type-form/AdminAddTypeForm'

export default function AdminAddUserType() {
    const [ bool, setBool] = useState(false)
  return (
    <>
      <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Add User Type</h3>
                        </div>
                        <AdminAddTypeForm  bool={bool} setBool={setBool}/>
                        <div className='mt-3'>
                            <AdminUserTypeList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
