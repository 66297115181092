import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ApproveRejectTaskRes;
export const ApproveRejectTaskTodo = createAsyncThunk('approved_task', async (data) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'approved_task',
            data: {
                
                "task_id": data?.task_id,
                "status": data?.status,
                "remarks":data?.remark ? data?.remark : '',
                "user_id":localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return ApproveRejectTaskRes = res.data

    } catch (error) {
        return ApproveRejectTaskRes = error.response.data
    }
})