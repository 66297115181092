import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateDealerRes;
export const updateDealerTodo = createAsyncThunk('updateDealer', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_dealer',
            data: {
                "id": data?.id,
                "username": data?.values?.name,
                "email": data?.values?.email,
                "status": data?.values?.status,

            },
            headers: { "Content-Type": "application/json" }
        })
        return updateDealerRes = res.data

    } catch (error) {
        return updateDealerRes = error.response.data
    }
})