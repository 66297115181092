import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DealerPswUpdateRes;
export const DealerPswUpdateTodo = createAsyncThunk('DealerPswUpdate', async (data) => {
console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_dealer_password',
            data: {
                "id":data.id,
                "password":data.new_psw,
                "confpassword":data.confirm_psw,
                // "added_by":localStorage.getItem('id'),
            },
            headers: { "Content-Type": "application/json" }
        })
        return DealerPswUpdateRes = res.data
        
    } catch (error) {
        return DealerPswUpdateRes = error.response.data
    }
})


