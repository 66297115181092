import React, { useState } from 'react'
import Text from '../../../../input_filed/Text';
import SelectStatus from '../../../../input_filed/SelectStatus';
import SaveButton from '../../../../buttons/SaveButton';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DepartmentSchema } from './Validation';
import Loader from '../../../../loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateDepartmentRes, updateDepartmentTodo } from '../../../../../redux/slices/super-admin-slices/master/department/AddDepartment';


export default function UpdateDepartmentForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation();
    var department = state
    console.log(department)

    const initialValues = {
        id: department && department._id,
        department: department && department.name,
        status: department && department.status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: DepartmentSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateDepartmentTodo(values)).then(() => updateDepartment_res(action))
        }
    })

    const updateDepartment_res = () => {
        if (updateDepartmentRes && updateDepartmentRes.status) {
            toast.success(updateDepartmentRes && updateDepartmentRes.message, { position: 'bottom-right' })
            setTimeout(() => {
                navigate('../superadmin/department')
            }, 1500);
        } else {
            toast.error(updateDepartmentRes && updateDepartmentRes.message, { position: 'bottom-right' })
        }
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className='form_layout mt-2'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <Text
                                            label_name='Department'
                                            placeholder=''
                                            name='department'
                                            value={values.department || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.department && touched.department ? (<span className='text-danger form_label' >{errors.department}</span>) : null}
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <SelectStatus
                                            closeMenu={true}
                                            label_name='Status'
                                            disabled={false}
                                            name='status'
                                            defaultValue={department.status}
                                            onChange={(e) =>
                                                setFieldValue('status', e.value)
                                            }
                                            onBlur={handleBlur}
                                            error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                        />
                                    </div>
                                    <div className='col-md-12 text-end mt-2'>
                                        <SaveButton name={'Save'} />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    )
}
