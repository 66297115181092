import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DeleteDealerRes;
export const DeleteDealerTodo = createAsyncThunk('DeleteDealer', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'deleted_dealer',
            data: {
                "id":data,
            },
            headers: { "Content-Type": "application/json" }
        })
        return DeleteDealerRes = res.data
        
    } catch (error) {
        return DeleteDealerRes = error.response.data
    }
})


