import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var TodoCancelRes;
export const TodoCancelTodo = createAsyncThunk('department_delete', async (id) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'complete_task',
            data: {
                
                "task_id": id,
                "status": "cancelled",
                "remarks": '',
                "added_by":localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return TodoCancelRes = res.data

    } catch (error) {
        return TodoCancelRes = error.response.data
    }
})