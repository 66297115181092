import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'
import { designationListRes, designationListTodo } from '../../../../redux/slices/super-admin-slices/master/designation/DesignationList'
import DesignationtAction from '../../../../component/data-table-action/table_action/super-admin-action/DesignationAction'

export default function DesignationList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const designationList = useSelector(state => state && state.DesignationList && state.DesignationList.data && state.DesignationList.data.data)
    console.log("designation", designationList)

    useEffect(() => {
        setLoading(true)
        dispatch(designationListTodo()).then(() => designationList_res())
    }, [bool])

    const designationList_res = () => {
        if (designationListRes && designationListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'DESIGNATION',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            width: 120

            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: DesignationtAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader/>
                            </diV> :
                            designationList && designationList.length ?
                                <DataTable
                                    rowData={designationList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                />
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
              
            <ToastContainer />
        </>
    )
}
