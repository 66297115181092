

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from "yup";
import MultiSelect from '../../../component/input_filed/MultiSelect';
import { useFormik } from 'formik';
import SaveButton from '../../../component/buttons/SaveButton';
import { useDispatch, useSelector } from 'react-redux';
import { AddAttendeeRes, AddAttendeeTodo } from '../../../redux/slices/admin-slices/meeting/AddAttendee';
import { toast } from 'react-toastify';
import Loader from '../../../component/loader/Loader';

export default function AddAttendee(props) {
    const { show, setShow, data, bool, setBool } = props;
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false)
    const userList = useSelector(state => state?.UserList?.data?.data)
    const userIds = data?.attendends?.map(val => val.user_id);
    const UserOpt = userList && userList
        .filter(value => !userIds?.includes(value._id))
        .map(val => ({
            "value": val._id,
            "label": val.name
        }));

    const AddAttendeeSchema = Yup.object({
        user: Yup.array().required(" Select at least One user").min(1, " Select at least One User"),
    });

    const initialValues = {
        meetingId: data?._id,
        user: [],
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddAttendeeSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddAttendeeTodo(values)).then(() => add_res(action))
            // console.log(values);
        }
    });

    const add_res = () => {
        if (AddAttendeeRes?.status) {
            toast.success(AddAttendeeRes?.message, { position: 'bottom-right' })
            setShow(false)
            setLoading(false)
            setBool(!bool)
        } else {
            toast.error(AddAttendeeRes?.message, { position: 'bottom-right' })

        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Attendee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        <div className='d-flex justify-content-center'>
                            <Loader />
                        </div> :
                        <div className='p-3'>
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                <div className='col-md-12'>
                                    <MultiSelect
                                        closeMenu={true}
                                        label_name='User'
                                        disabled={false}
                                        option={UserOpt}
                                        name='user'
                                        defaultValue={""}
                                        onChange={(e) => {
                                            setFieldValue('user', e);
                                        }}
                                        onBlur={handleBlur}
                                        error={errors.user && touched.user ? (<span className='text-danger form_label' >{errors.user}</span>) : null}
                                    />
                                </div>
                                <div className='col-md-12 text-end mt-2'>
                                    <SaveButton name={'Save'} />
                                </div>
                            </form>
                        </div>}

                </Modal.Body>
            </Modal>
        </>
    );
}
