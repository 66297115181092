import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { ActiveAssignPlanRes, ActiveAssignPlanTodo, InactiveAssignPlanRes, InactiveAssignPlanTodo } from '../../../../redux/slices/super-admin-slices/asign-plan/StatusChang'

export default function AssignPlanAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const edit_department = () => {
    //     navigate('../superadmin/update-assign-plan', { state: props.data && props.data })
    // }

    const Inactive_status = () => {
        Swal.fire({
            title: " Status Inactive",
            text: "Are You Sure Want To Inactive This Assign Plan ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(InactiveAssignPlanTodo(props.data)).then(() => inac_res())
            }
        })
    }

    const inac_res = () => {
        if (InactiveAssignPlanRes && InactiveAssignPlanRes.status) {
            setBool(!bool)
            Swal.fire(
                "Assign Plan",
                'Assign Plan Inactive Successfully',
                'success'
            )
        } else {
            toast.error(InactiveAssignPlanRes && InactiveAssignPlanRes.message, { position: "bottom-right" })
        }
    }
    const Active_status = () => {
        Swal.fire({
            title: "Status Active",
            text: "Are You Sure Want To Active This Assign Plan ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(ActiveAssignPlanTodo(props.data)).then(() => actv_res())
            }
        })
    }

    const actv_res = () => {
        if (ActiveAssignPlanRes && ActiveAssignPlanRes.status) {
            setBool(!bool)
            Swal.fire(
                "Assign Plan",
                'Assign Plan Active Successfully',
                'success'
            )
        } else {
            toast.error(ActiveAssignPlanRes && ActiveAssignPlanRes.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                {props.data.enddate <= new Date() ? "": props.data && props.data.status === "Active" ?
                    <LuEyeOff size={20} className='eye text-secondary'
                        onClick={() => Inactive_status()}
                        data-tooltip-id='deactivate'
                        data-tooltip-content="Inactive !"
                        data-tooltip-place="bottom"
                    />
                    :
                    <LuEye size={20} className='eye text-secondary'
                        onClick={() => Active_status()}
                        data-tooltip-id='active'
                        data-tooltip-content="Active!"
                        data-tooltip-place="bottom"
                    /> 
                }

                {/* <LuFileEdit size={20} className='edit text-success ms-3'
            onClick={() => edit_department()}
            data-tooltip-id='edit'
            data-tooltip-content="Edit Assign Plan!"
            data-tooltip-place="bottom"
        /> */}
                {/* <LuBadgeInfo size={20} className='details text-primary ms-3'
        // onClick={() => customer_details()}
        data-tooltip-id='details'
        data-tooltip-content="Customer Details!"
        data-tooltip-place="bottom"
    />*/}
                {/* <LuTrash2 size={20} className='delete text-danger ms-3'
            onClick={() => del_department()}
            data-tooltip-id='del'
            data-tooltip-content="Delete Assign Plan!"
            data-tooltip-place="bottom"
        /> */}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
