import React from 'react'
import { Form } from 'react-bootstrap'

export default function Text(props) {

    const { label_name, placeholder, name, value, onChange, onBlur, error } = props

    return (
        <>
            <Form.Group className="mb-2" >
                {label_name == '' ? '' : <Form.Label className='form_label'>{label_name}</Form.Label>}
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    className='form_input'
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {error}

            </Form.Group>
        </>
    )
}
