import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addActionRes;
export const addActionTodo = createAsyncThunk('addAction', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_masteraction',
            data: {

                "fk_master_module_id": data.module,
                "action_display_name": data.name,
                "action_url_name": data.action_url,
                "description": data.description,
                "action_priority": data.priority,
                "controller_name": data.controller,
                "action_status": data.status,
                "menu_status": data.showInmenu,
                "added_by": localStorage.getItem('id')
            },
            headers: { "Content-Type": "application/json" }
        })
        return addActionRes = res.data

    } catch (error) {
        return addActionRes = error.response.data
    }
})