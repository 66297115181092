import moment from "moment";
import * as Yup from "yup";


export const MeetingSchema = Yup.object({

    title: Yup.string().required("Enter Title "),
    description: Yup.string().required("Enter Description "),
    duration: Yup.string().required("Enter Duration in Hours"),
    start_date: Yup.string().required("Select Start Date"),
    type: Yup.string().required("Select Meeting Type"),
    filter_typ: Yup.string().required("Select Filter Type"),
    group: Yup.string().when('filter_typ', {
        is: 'group',
        then: () => Yup.string().required("Select Group"),
        otherwise: () => Yup.string().notRequired('Select Group'),
    }),
    location: Yup.string().required("Meeting Location is Requird"),
    time: Yup.string().required('Start time is required').test(
        'is-valid-time',
        ' Time cannot be earlier than the current time',
        function (value) {
            const { start_date } = this.parent;
            const selectedDate = moment(start_date, 'YYYY-MM-DD').startOf('day');
            const today = moment().startOf('day');
            const currentTime = moment();

            // If the selected date is today, check if start time is valid
            if (selectedDate.isSame(today, 'day')) {
                const selectedTime = moment(value, 'hh:mm A');
                return selectedTime.isSameOrAfter(currentTime, 'minute');
            }
            return true; // No validation needed if the selected date is not today
        }
    ) ,
    // time: Yup.string().required("Select time"),
    attendees: Yup.array().when('filter_typ', {
        is: 'user',
        then: () => Yup.array().required(" Select at least One user").min(1, " Select at least One User"),
        otherwise: () => Yup.array().notRequired('Select User'),
    }),
});