import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userTypeListRes;
export const userTypeListTodo = createAsyncThunk('userTypeList', async (id) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_usertype_list',
            headers: { "Content-Type": "application/json" },
            data:{
                "company_id":null
            }
            
        })
        return userTypeListRes = res.data

    } catch (error) {
        return userTypeListRes = error.response.data
    }
});

const userTypeListSlice = createSlice({
    name: 'userTypeList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(userTypeListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(userTypeListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default userTypeListSlice.reducer; 