import React from 'react'
import AdminUpdateDesignationForm from '../../../../component/form/admin-form/admin-master-form/admin-designation-form/AdminUpdateDesignationForm'

export default function AdminUpdateDesignation() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>Update Designation</h3>
                            <AdminUpdateDesignationForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
