import React from 'react'
import UpdateDesignationForm from '../../../../component/form/super-admin-form/master/designation-form/UpdateDesignationForm'

export default function UpdateDesignation() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>Update Designation</h3>
                        </div>
                        <UpdateDesignationForm />
                    </div>
                </div>
            </div>
        </>
    )
}
