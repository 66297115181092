import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminDesignationListRes;
export const adminDesignationListTodo = createAsyncThunk('adminDesignationList', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'designation_list',
            headers: { "Content-Type": "application/json" },
         data:{
            
            "company_id":adminRole === 'Admin' ? localStorage.getItem('customerId') : ''
         }
        })
        return adminDesignationListRes = res.data

    } catch (error) {
        return adminDesignationListRes = error.response.data
    }
});

const adminDesignationListSlice = createSlice({
    name: 'adminDesignationList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(adminDesignationListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(adminDesignationListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default adminDesignationListSlice.reducer; 