import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UserListRes;
export const UserListTodo = createAsyncThunk('UserList', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'user_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "webtype": "web",
                'department': data?.values ? data?.values?.department : data?.department ? data?.department : '',
                "designation": data?.values ? data?.values?.designation : data?.designation ? data?.designation : '',
                "status": data?.values ? data?.values?.status : '',
                "id": data.id === '' ? localStorage.getItem('id') : [localStorage.getItem('id')],
                "company_id": localStorage.getItem('customerId'),
                "type": data.type ? data.type : ''
            }
        })
        return UserListRes = res.data

    } catch (error) {
        return UserListRes = error.response.data
    }
});

const UserListSlice = createSlice({
    name: 'UserList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(UserListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(UserListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default UserListSlice.reducer; 