import React, { useEffect, useState } from 'react'
import { FaCircleDot, FaRegClock, FaUserCheck, FaUserLarge } from "react-icons/fa6";
import { MdOutlineGridOn } from "react-icons/md";
import { BsCameraReels } from "react-icons/bs";
import { RiTeamLine } from "react-icons/ri";
import { BiTask } from "react-icons/bi";
import { SlCalender } from "react-icons/sl";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SiTicktick } from "react-icons/si";
import Loader from '../../../component/loader/Loader';
import { dashboardRes, dashboardTodo } from '../../../redux/slices/super-admin-slices/dashboard/Dashboard';
import { AdminDashboardRes, AdminDashboardTodo } from '../../../redux/slices/admin-slices/dashboard/AdminDashboard';
import { FaUserEdit } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';
import { Link } from 'react-router-dom';


export default function AdminDashboard() {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const dashboardData = useSelector(state => state && state.AdminDashboard && state.AdminDashboard.data && state.AdminDashboard.data.data && state.AdminDashboard.data.data[0])

  useEffect(() => {
    setLoading(true)
    dispatch(AdminDashboardTodo()).then(() => dashboardFun())
  }, [])

  const adminId = localStorage.getItem('type')

  const dashboardFun = () => {
    if (AdminDashboardRes && AdminDashboardRes.success) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }


  return (
    <>

      <div className="container-fluid">
        <div className='layout_head'>
          <h3>Dashboard</h3>
        </div>
        {loading ?
          <div className='d-flex justify-content-center mt-5 pt-5'>
            <Loader />
          </div> :

          <div className="row">
            {adminId === 'Admin' ? <div className="col-md-4 mt-3">
              <Link to='/admin/user-list'>  <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < FaUserLarge size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>USER</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.TotalUsers}</h3>
                  </div>
                </div>
              </div></Link>
            </div> : ''}

            <div className="col-md-4 mt-3">
              <Link to='/admin/task-list'>   <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < BiTask size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>TASK</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.Totaltodo}</h3>
                  </div>
                </div>
              </div></Link>
            </div>
            {adminId === 'Admin' ? <></> : <div className="col-md-4 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < BiTask size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>OVERDUE TASK</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.TotalIncompletetaskcount}</h3>
                  </div>
                </div>
              </div>
            </div>}
            <div className="col-md-4 mt-3">
              <Link to='/admin/meeting-list'>  <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < RiTeamLine size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>MEETING</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.Totalmeeting}</h3>
                  </div>
                </div>
              </div></Link>
            </div>
            {adminId === 'Admin' ? "" : <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-4">
                <div className="card dashboard_card2 " >
                  <PerfectScrollbar>
                    <div className='p-3'>
                      <h5>TODAY'S TASK</h5>
                      <hr className='m-1' />
                      {dashboardData?.TodaytaskData?.length ?
                        dashboardData?.TodaytaskData?.map((val, index) => {

                          return (
                            <>
                              <div className='mt-2 mb-1' key={index}>
                                <p> {val?.title} </p>
                                <div className='d-flex justify-content-between align-items-center mt-1'>
                                  <div>
                                    <span style={{textTransform:'capitalize'}}><FaUserEdit size={18} className='mb-1' /> {val?.added_by?.name}</span><br />
                                    <span><SlCalender className='mb-1 me-1' /> Deadline : {new Date(val?.deadline).toLocaleDateString('en-GB')}  </span>
                                  </div>
                                  <h6 style={{textTransform:"uppercase"}} 
                                  className={
                                    val?.status === 'pending' ? "badge pt-2 text-bg-danger" :
                                     val?.status === 'completed' ? "badge pt-2 text-bg-success" : 
                                     val?.status === 'On Approval' ? "badge pt-2 text-bg-warning" : 
                                       val?.status === 'rejected' ? "badge pt-2 text-bg-danger" :
                                       val?.status === 'cancelled' ? "badge pt-2 text-bg-secondary" : ''} >{val?.status}</h6>
                                </div>
                              </div>
                              <hr />
                            </>
                          )
                        })
                        : <div className='d-flex justify-content-center mt-5'>
                          <span>NO TASK FOR TODAY'S</span>
                        </div>

                      }

                    </div>
                  </PerfectScrollbar>
                </div>

              </div>
              <div className="col-md-4">
                <div className="card dashboard_card2 " >
                  <PerfectScrollbar>
                    <div className='p-3'>
                      <h5>OVERDUE TASK</h5>
                      <hr className='m-1' />
                   
                      {dashboardData?.TotalIncompletetask?.length ?
                        dashboardData?.TotalIncompletetask?.map((val, index) => {
                          return (
                            <>    <div className='mt-2 mb-1'>
                        <div >
                          {/* <span><GoDotFill size={12} className='mb-0 me-1' style={{ color: "black" }} /></span> */}
                          <p>{val?.title} </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mt-1'>
                                  <div>
                                    <span style={{textTransform:'capitalize'}}><FaUserEdit size={18} className='mb-1' /> {val?.added_by?.name}</span><br />
                                    <span><SlCalender className='mb-1 me-1' />Deadline : {new Date(val?.deadline).toLocaleDateString('en-GB')} </span>
                                  </div>
                                  <h6 style={{textTransform:"uppercase",backgroundColor: 'orangered' }} className='badge pt-2' >Overdue</h6>
                                </div>
                      </div>
                      <hr />  
                      </>
                          )
                        })

                        : <div className='d-flex justify-content-center mt-5'>
                          <span>NO OVERDUE TASK</span>
                        </div>
                      }

                    </div>
                  </PerfectScrollbar>
                </div>

              </div>
              <div className="col-md-4">
                <div className="card dashboard_card2 " >
                  <PerfectScrollbar>
                    <div className='p-3'>
                      <h5>TODAY'S MEETINGS</h5>
                      <hr className='m-1' />

                      {dashboardData?.TodaymeetingData?.length ?
                        dashboardData?.TodaymeetingData?.map((val, index) => {
                          return (
                            <>    <div className='mt-2 mb-1'>
                              <p> {val?.title}</p>
                              <div className='d-flex justify-content-between align-items-center mt-1'>
                                <div>
                                  <span style={{textTransform:'capitalize'}}><FaUserEdit size={18} className='mb-1' /> {val?.addusers?.[0]?.name}</span><br />
                                  <span><FaRegClock className='mb-1' /> {val?.time}</span>
                                </div>
                                <h6 style={{textTransform:"uppercase"}} 
                                className={val?.status === 'pending' ?"badge pt-2 text-bg-danger" :
                                 val?.status === 'completed' ? "badge pt-2 text-bg-success" :
                                  val?.status === 'cancelled' ? "badge pt-2 text-bg-secondary" :
                                   ''} >{val?.status}</h6>
                              </div>
                            </div>
                              <hr />     </>
                          )
                        })

                        : <div className='d-flex justify-content-center mt-5'>
                          <span>NO MEETINGS FOR TODAY'S</span>
                        </div>
                      }


                    </div>
                  </PerfectScrollbar>
                </div>

              </div>
            </div>
          </div>}
    </div >
       }
      </div >
    </>
  )
}
