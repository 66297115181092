import React, { useEffect, useState } from 'react'
import SelectStatus from '../../../../input_filed/SelectStatus';
import MultiSelect from '../../../../input_filed/MultiSelect';
import Text from '../../../../input_filed/Text';
import SaveButton from '../../../../buttons/SaveButton';
import Loader from '../../../../loader/Loader';
import { useFormik } from 'formik';
import { GroupSchema } from './Validation';
import { useDispatch, useSelector } from 'react-redux';
import { UserListRes, UserListTodo } from '../../../../../redux/slices/admin-slices/User/UserList';
import { Form } from 'react-bootstrap';
import { adminUpdateGroupRes, adminUpdateGroupTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-group/AddGroup';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

export default function UpdateGroupForm() {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const userList = useSelector(state => state?.UserList?.data?.data)
    const { state } = useLocation()
    const navigate = useNavigate()

    const UserOpt = userList && userList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    const groupUser = state?.groupmasterdetails.map(val => (
        { "value": val?.user_details?._id, "label": val?.user_details?.name }
    ))


    useEffect(() => {
        setLoading(true)
        dispatch(UserListTodo({ "type": '' })).then(() => list_res())
    }, [])

    const list_res = () => {
        if (UserListRes?.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const initialValues = {
        id: state?._id,
        name: state?.name,
        users: groupUser,
        status: state?.status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: GroupSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(adminUpdateGroupTodo(values)).then(() => add_res())
        }
    })

    const add_res = () => {
        if (adminUpdateGroupRes && adminUpdateGroupRes.status) {
            toast.success(adminUpdateGroupRes && adminUpdateGroupRes.message, { position: 'bottom-right' })
            setLoading(false)
            setTimeout(() => {
                navigate('../admin/group')
            }, 1500)
        } else {
            setLoading(false)
            toast.error(adminUpdateGroupRes && adminUpdateGroupRes.message, { position: 'bottom-right' })
        }
    }

    return (
        <>
            {loading ?
                <div className='d-flex justify-content-center'>
                    <Loader />
                </div>
                :
                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <div className='form_layout mt-2'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <MultiSelect
                                    closeMenu={false}
                                    label_name='User'
                                    disabled={false}
                                    option={UserOpt ? UserOpt : ''}
                                    name='users'
                                    defaultValue={groupUser}
                                    onChange={(e) => {
                                        setFieldValue('users', e);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.users && touched.users ? (<span className='text-danger form_label' >{errors.users}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={values.status}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>
                    </div>
                </Form>}
            <ToastContainer />
        </>
    )
}
