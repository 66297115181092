import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { date } from "yup";

export var adminPswUpdateRes;
export const adminPswUpdateTodo = createAsyncThunk('adminPswUpdate', async (data) => {
    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'password_update',
        data: {
            "id": data?.id ? data?.id : localStorage.getItem('id'),
            "password": data.new_psw,
            "confpassword": data.confirm_psw,
            "company_id": localStorage.getItem('customerId')
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return adminPswUpdateRes = res.data
})