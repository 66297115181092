import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addUserTypeRes;
export const addUserTypeTodo = createAsyncThunk('addUserType', async (data) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_add_usertype',
            data: {
                "type":data.user_type,
                "status":data.status,
                "added_by":localStorage.getItem('id'),
                "added_type": adminRole === 'Admin' ? 'unlocked' : 'locked',
                "company_id": null
            },
            headers: { "Content-Type":  "application/json"}
        })
        return addUserTypeRes = res.data
        
    } catch (error) {
        return addUserTypeRes = error.response.data
    }
})