import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import { updateActionRes, updateActionTodo } from '../../../../redux/slices/super-admin-slices/action-slice/UpdateAction'
import { moduleListRes, moduleListTodo } from '../../../../redux/slices/super-admin-slices/module/ModuleList'
import Loader from '../../../loader/Loader'
import SingleSelect from '../../../input_filed/SingleSelect'
import Text from '../../../input_filed/Text'
import TextArea from '../../../input_filed/TextArea'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { AddActionSchema } from './Validation'


export default function UpdateActionForm() {

  const [loading, setLoading] = useState(false)
  const [moduleloading, setModuleLoading] = useState(false)
  const moduleList = useSelector(state => state && state.ModuleList && state.ModuleList.data && state.ModuleList.data.data && state.ModuleList.data.data.master)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { state } = useLocation();
  const actionData = state;

  const modul_option = moduleList && moduleList.map(val => (
    { "value": val._id, "label": val.module_display_name }
  ))

  const showMenu_option = [
    { "value": '1', "label": "Yes" },
    { "value": '0', "label": "No" }
  ]

  const initialValues = {
    module: actionData && actionData.fk_master_module_id && actionData.fk_master_module_id._id,
    name: actionData && actionData.action_display_name,
    action_url: actionData && actionData.action_url_name,
    priority: actionData && actionData.action_priority,
    description: actionData && actionData.description,
    showInmenu: actionData && actionData.menu_status,
    controller: actionData && actionData.controller_name,
    status: actionData && actionData.action_status
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: AddActionSchema,

    onSubmit: (values, action) => {
      setLoading(true)
      dispatch(updateActionTodo({ "values": values, "id": actionData._id })).then(() => updateActionFun())
    }
  })

  const updateActionFun = (action) => {
    if (updateActionRes && updateActionRes.status) {
      setLoading(false)
      toast.success(updateActionRes && updateActionRes.message, { position: "bottom-right" })
      setTimeout(() => {
        navigate('../superadmin/action-list')
      }, 1500);
    } else {
      setLoading(false)
      toast.error(updateActionRes && updateActionRes.message, { position: "bottom-right" });
    }
  }

  useEffect(() => {
    setModuleLoading(true)
    dispatch(moduleListTodo()).then(() => moduleListFun())
  }, [])

  const moduleListFun = () => {
    if (moduleListRes && moduleListRes.status) {
      setModuleLoading(false)
    } else {
      setModuleLoading(false)
    }
  }

  return (
    <>
      <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        <div className='form_layout mt-3'>
          {loading || moduleloading ?
            <div className='d-flex justify-content-center p-5'>
              <Loader />
            </div> :
            <div className='row'>
              <div className='col-md-4'>
                <SingleSelect
                  closeMenu={true}
                  label_name='Module'
                  disabled={false}
                  option={modul_option}
                  name='module'
                  defaultValue={modul_option && modul_option.find((option) => option.value === values.module)}
                  onChange={(e) => {
                    setFieldValue('module', e.value);
                  }
                  }
                  onBlur={handleBlur}
                  error={errors.module && touched.module ? (<span className='text-danger form_label' >{errors.module}</span>) : null}
                />
              </div>
              <div className='col-md-4'>
                <Text
                  label_name='Action Name'
                  placeholder=''
                  name='name'
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                />
              </div>
              <div className='col-md-4'>
                <Text
                  label_name='Action URL'
                  placeholder=''
                  name='action_url'
                  value={values.action_url || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.action_url && touched.action_url ? (<span className='text-danger form_label' >{errors.action_url}</span>) : null}
                />
              </div>
              <div className='col-md-4'>
                <Text
                  label_name=' Priority'
                  placeholder=''
                  name='priority'
                  value={values.priority || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.priority && touched.priority ? (<span className='text-danger form_label' >{errors.priority}</span>) : null}
                />
              </div>

              <div className='col-md-4'>
                <SingleSelect
                  closeMenu={true}
                  label_name='Show In Menu'
                  disabled={false}
                  option={showMenu_option}
                  name='showInmenu'
                  defaultValue={showMenu_option && showMenu_option.find((option) => option.value === values.showInmenu)}
                  onChange={(e) => {
                    setFieldValue('showInmenu', e.value);
                  }
                  }
                  onBlur={handleBlur}
                  error={errors.showInmenu && touched.showInmenu ? (<span className='text-danger form_label' >{errors.showInmenu}</span>) : null}
                />
              </div>
              <div className='col-md-4'>
                <Text
                  label_name='Controller'
                  placeholder=''
                  name='controller'
                  value={values.controller || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.controller && touched.controller ? (<span className='text-danger form_label' >{errors.controller}</span>) : null}
                />
              </div>
              <div className='col-md-8'>
                <TextArea
                  label_name=' Description'
                  placeholder=''
                  disabled={false}
                  rows={1}
                  name='description'
                  value={values.description || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.description && touched.description ? (<span className='text-danger form_label' >{errors.description}</span>) : null}
                />
              </div>
              <div className='col-md-4'>
                <SelectStatus
                  closeMenu={true}
                  label_name='Status'
                  disabled={false}
                  name='status'
                  defaultValue={values.status}
                  onChange={(e) =>
                    setFieldValue('status', e.value)
                  }
                  onBlur={handleBlur}
                  error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                />
              </div>
              <div className='col-md-12 text-end mt-2'>
                <SaveButton name={'Save'} />
              </div>
            </div>}
        </div>
      </Form>
      <ToastContainer />
    </>
  )
}
