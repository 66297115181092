import React, { useEffect, useState } from 'react'
import TextArea from '../../../input_filed/TextArea';
import DateInput from '../../../input_filed/Date';
import TimeInput from '../../../input_filed/Time';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Loader from '../../../loader/Loader';
import { Form } from 'react-bootstrap';
import SaveButton from '../../../buttons/SaveButton';
import Text from '../../../input_filed/Text';
import SingleSelect from '../../../input_filed/SingleSelect';
import { TodoSchema } from './Validation';
import { UserListRes, UserListTodo } from '../../../../redux/slices/admin-slices/User/UserList';
import { AddTodoRes, AddTodoTodo } from '../../../../redux/slices/admin-slices/todo/AddTodo';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TimePicker from '../../../input_filed/TimePicker'
import moment from 'moment';
import DatePicker from '../../../input_filed/DatePicker';
import MultiSelect from '../../../input_filed/MultiSelect';

export default function AddTodoForm() {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userList = useSelector(state => state?.UserList?.data?.data)
    // const currentuser = { "value": localStorage.getItem('id'), "label": "Self" }

    const UserOpt = userList && userList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    const taskForOption = [
        { "value": localStorage.getItem('id'), "label": "Self" },
        { "value": 'other', "label": "Other" }
    ]

    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    // const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
    const formattedTime24hr = `${hours}:${minutes.toString().padStart(2, '0')}`;
    // UserOpt?.unshift(currentuser);
// console.log(formattedTime24hr)
    const approve_opt = [
        { "value": 'yes', "label": 'Yes' },
        { "value": 'no', "label": "No" }

    ]

    const initialValues = {
        title: '',
        description: "",
        approved_required: "",
        deadline: "",
        time: '',
        task_for:'',
        // description: "",
        assign_to: [],
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: TodoSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddTodoTodo(values)).then(() => add_res(action))
            console.log(values)
        }
    })

    const add_res = (action) => {
        if (AddTodoRes && AddTodoRes.status) {
            toast.success(AddTodoRes && AddTodoRes.message, { position: 'bottom-right' })
            // action.resetForm()
            setLoading(false)
            setTimeout(() => {
                navigate('/admin/task-list')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(AddTodoRes && AddTodoRes.message, { position: 'bottom-right' })
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(UserListTodo({ "type": "" })).then(() => list_res())
    }, [])

    const list_res = () => {
        if (UserListRes?.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        {loading ?
                            <div className='d-flex justify-content-center'>
                                <Loader />
                            </div>
                            :
                            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                <div className='form_layout mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Text
                                                label_name='Title'
                                                placeholder=''
                                                name='title'
                                                value={values.title || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.title && touched.title ? (<span className='text-danger form_label' >{errors.title}</span>) : null}
                                            />
                                        </div>

                                        <div className='col-md-4'>
                                            {/* <DateInput
                                                label_name='Deadline'
                                                placeholder=''
                                                name='deadline'
                                                min={new Date().toISOString().split("T")[0] }
                                                value={values.deadline || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.deadline && touched.deadline ? (<span className='text-danger form_label' >{errors.deadline}</span>) : null}
                                            /> */}
                                            <DatePicker
                                                label_name='Deadline'
                                                placeholder={'Choose Date'}
                                                name='deadline'
                                                option={{
                                                    altInput: true,
                                                    altFormat: "F j, Y",
                                                    dateFormat: 'Y-m-d',
                                                    minDate: new Date().toISOString().split("T")[0],
                                                    maxDate: '',
                                                    disable: [],
                                                    mode: "single",
                                                    defaultDate: [],
                                                    conjunction: "",

                                                }}
                                                value={values.deadline || ''}
                                                onChange={(e) => {
                                                    setFieldValue('deadline', moment(e[0]).format("YYYY-MM-DD"))
                                                    setFieldValue('time', '')
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.deadline && touched.deadline ? (<span className='text-danger form_label' >{errors.deadline}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <TimePicker
                                                label_name=' Time'
                                                placeholder='Choose Time'
                                                name='time'
                                                option={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: 'G:i K', // Customize date format as needed
                                                    time_24hr: false,
                                                    minTime: '', // Before dates disabled
                                                    maxTime: '', // After dates disabled
                                                    disable: [], // Disable particular date ranges

                                                }}
                                                onChange={(e) =>
                                                    setFieldValue('time', moment(e[0]).format('hh:mm A'))
                                                }
                                                onBlur={handleBlur}
                                                error={errors.time && touched.time ? (<span className='text-danger form_label' >{errors.time}</span>) : null}
                                            />
                                        </div>

                                        <div className='col-md-4'>
                                            <SingleSelect
                                                closeMenu={true}
                                                label_name='Task For'
                                                disabled={false}
                                                option={taskForOption ? taskForOption : ''}
                                                name='task_for'
                                                defaultValue={""}
                                                onChange={(e) => {
                                                    setFieldValue('task_for', e.value);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.task_for && touched.task_for ? (<span className='text-danger form_label' >{errors.task_for}</span>) : null}
                                            />
                                        </div>
                                        {values.task_for === 'other' ?
                                            <div className='col-md-4'>
                                                <MultiSelect
                                                    closeMenu={false}
                                                    label_name='Assign To'
                                                    disabled={false}
                                                    option={UserOpt ? UserOpt : ''}
                                                    name='assign_to'
                                                    defaultValue={""}
                                                    onChange={(e) => {
                                                        setFieldValue('assign_to', e);
                                                    }
                                                    }
                                                    onBlur={handleBlur}
                                                    error={errors.assign_to && touched.assign_to ? (<span className='text-danger form_label' >{errors.assign_to}</span>) : null}
                                                />
                                            </div> : ""}
                                        {values.task_for === 'other' ? <div className='col-md-4'>
                                            <SingleSelect
                                                closeMenu={true}
                                                label_name='Closure Approval Required'
                                                disabled={false}
                                                option={approve_opt ? approve_opt : ''}
                                                name='approved_required'
                                                defaultValue={""}
                                                onChange={(e) => {
                                                    setFieldValue('approved_required', e.value);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.approved_required && touched.approved_required ? (<span className='text-danger form_label' >{errors.approved_required}</span>) : null}
                                            />
                                        </div> : ''}

                                        <div className='col-md-12 text-end mt-2'>
                                            <SaveButton name={'Save'} />
                                        </div>
                                    </div>
                                </div>
                            </Form>}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
