import React,{useState} from 'react'
import GroupList from './GroupList'
import AddGroupForm from '../../../../component/form/admin-form/admin-master-form/admin-group-form/AddGroup'

export default function AddGroup() {
    const [bool, setBool] = useState(false)

    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Group</h3>
                        </div>
                        <AddGroupForm bool={bool} setBool={setBool} />
                        <div className='mt-2'>
                        <div className='layout_head'>
                            <h3> Group List</h3>
                        </div>
                        <GroupList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
