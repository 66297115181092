import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteCustomerRes;
export const deleteCustomerTodo = createAsyncThunk('deleteCustomer', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'delete_mastercompany',
            data: {
                "id": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return deleteCustomerRes = res.data

    } catch (error) {
        return deleteCustomerRes = error.response.data
    }
})