import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ActiveAssignPlanRes;
export const ActiveAssignPlanTodo = createAsyncThunk('ActiveAssignPlan', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_assign_subscription',
            data: {
                "id":data._id,
                "comapany_id":data.fk_master_comp_id,
                "status":'Active',
                "modified_by":localStorage.getItem('id'),
            },
            headers: { "Content-Type": "application/json" }
        })
        return ActiveAssignPlanRes = res.data
        
    } catch (error) {
        return ActiveAssignPlanRes = error.response.data
    }
})

export var InactiveAssignPlanRes;
export const InactiveAssignPlanTodo = createAsyncThunk('InactiveAssignPlan', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_assign_subscription',
            data: {
                "id":data._id,
                "comapany_id":data.fk_master_comp_id,
                "status":'Inactive',
                "modified_by":localStorage.getItem('id'),
            },
            headers: { "Content-Type": "application/json" }
        })
        return InactiveAssignPlanRes = res.data
        
    } catch (error) {
        return InactiveAssignPlanRes = error.response.data
    }
})