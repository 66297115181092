import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var superAdminLoginRes;
export const superAdminLoginTodo = createAsyncThunk('superAdminLogin', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_admin_login',
            data: {
                "username": data.email,
                "password": data.password,
            },
            headers: { "Content-Type": "application/json" }
        })
        return superAdminLoginRes = res.data
        
    } catch (error) {
        return superAdminLoginRes = error.response.data
    }
})
