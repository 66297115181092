import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminDepartmentListRes;
export const adminDepartmentListTodo = createAsyncThunk('adminDepartmentList', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'department_list',
            headers: { "Content-Type": "application/json" },
         data:{
            "company_id": localStorage.getItem('customerId') 
         }
        })
        return adminDepartmentListRes = res.data

    } catch (error) {
        return adminDepartmentListRes = error.response.data
    }
});

const adminDepartmentListSlice = createSlice({
    name: 'adminDepartmentList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(adminDepartmentListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(adminDepartmentListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default adminDepartmentListSlice.reducer; 