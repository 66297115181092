import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updatePlanRes;
export const updatePlanTodo = createAsyncThunk('updatePlan', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_plan',
            data: {
                "id": data.id,
                "name": data.name,
                "days": data.validity,
                "amount": data.amount,
                "modified_by": localStorage.getItem('id'),

            },
            headers: { "Content-Type": "application/json" }
        })
        return updatePlanRes = res.data

    } catch (error) {
        return updatePlanRes = error.response.data
    }
})