import React, { useEffect, useRef, useState } from 'react'
import DataTablePagination from '../../../component/data-table/DataTablePagination'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../component/loader/Loader'
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu'
import { Button, Form } from 'react-bootstrap'
import SaveButton from '../../../component/buttons/SaveButton'
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList'
import { useFormik } from 'formik'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/light.css"
import { IoRefreshOutline } from 'react-icons/io5'
import { TodoListRes, TodoListTodo } from '../../../redux/slices/admin-slices/todo/TodoList'
import TodoAction from '../../../component/data-table-action/table_action/admin-action/TodoAction'
import TodoDetails from './TodoDetails'
import { ToastContainer } from 'react-toastify'
import { TbWorldDown } from 'react-icons/tb'
import { VscWordWrap } from 'react-icons/vsc'
import { adminDepartmentListTodo } from '../../../redux/slices/admin-slices/admin-master/admin-department/AdminDepartmentList'
import moment from 'moment'
import DatePicker from '../../../component/input_filed/DatePicker'
import { Link, useLocation } from 'react-router-dom'
import AddButton from '../../../component/buttons/AddButton'
import TodoReject from './TodoReject'

export default function TodoList() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const todoList = useSelector(state => state?.TodoList?.data?.data?.[0]?.resData)
    const currentDate = useSelector(state => state?.TodoList?.data?.data?.[0]?.currentDate)
    const userList = useSelector(state => state && state.UserList && state.UserList.data && state.UserList.data.data)
    const TotalCount = useSelector(state => state?.TodoList?.data?.data?.[0]?.totalmeetingcount?.[0]?.total)
    const [pageLimit, setPageLimit] = useState(10)
    const [pageSize, setPageSize] = useState(0)
    const [filterLoad, setFilterLoad] = useState(false)
    const [bool, setBool] = useState(false)
    const [details, setDetails] = useState(false)
    const [reject, setReject] = useState(false)
    const [data, setData] = useState('')
    const departmentList = useSelector(state => state?.AdminDepartmentList?.data?.data)
    const admin = localStorage.getItem('type')
    var permission = JSON.parse(localStorage.getItem('actions'))
    const location = useLocation()

    const user_option = userList && userList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    //   {admin === 'Admin' ? <></> : user_option?.push(currentuser);}
    const department_opt = departmentList && departmentList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    const type_option = [
        { "value": 'task', "label": "Task" },
        { "value": 'meeting', "label": "Meeting" },
        { "value": 'all', "label": "All" }
    ]
    const status_option = [
        { "value": 'completed', "label": "Completed" },
        { "value": 'On Approval', "label": "On Approval" },
        { "value": 'pending', "label": "Pending" },
        { "value": 'cancelled', "label": "Cancelled" },
        { "value": 'rejected', "label": "Reject" },
        { "value": 'overdue', "label": "Overdue" },

    ]

    // const paginationInc = todoList && todoList.length
    console.log("TotalCount", TotalCount)

    useEffect(() => {
        setLoading(true)
        dispatch(TodoListTodo({ "filtertype": '1', "pageLimit": pageLimit, "pageSize": pageSize })).then(() => todolist_res())
    }, [bool])

    useEffect(() => {
        dispatch(TodoListTodo({ "filtertype": '1', "pageLimit": pageLimit, "pageSize": pageSize }))
    }, [pageLimit, pageSize])


    const todolist_res = () => {
        if (TodoListRes && TodoListRes.status) {
            dispatch(UserListTodo({ "type": '1', 'id': '' })).then(() => userList_res())

        } else {
            setLoading(false)
        }
    }
    const userList_res = () => {
        if (UserListRes && UserListRes.status) {
            dispatch(adminDepartmentListTodo()).then((department_res) => {
                if (department_res?.payload?.status) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }

    const initialValues = {
        user: '',
        date: "",
        department: '',
        type: "",
        status: ""

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,

        onSubmit: (values, action) => {
            setFilterLoad(true)
            dispatch(TodoListTodo({ values, "filtertype": '1', "pageLimit": pageLimit, "pageSize": pageSize })).then(() => filter_res())
        }
    })

    const filter_res = () => {
        if (TodoListRes && TodoListRes.status) {
            setFilterLoad(false)
        } else {
            setFilterLoad(false)
        }
    }

    const handleSearch = (data) => {
        dispatch(TodoListTodo({ "title": data, "filtertype": '1', "pageLimit": pageLimit, "pageSize": pageSize })).then(() => filter_res())
    }



    const selectRef = useRef();
    const selectTypeRef = useRef();
    const selectDepartRef = useRef();
    const selectStatusRef = useRef();
    const handleReset = () => {
        values.date = ""
        selectRef && selectRef.current && selectRef.current.clearValue();
        selectTypeRef && selectTypeRef.current && selectTypeRef.current.clearValue();
        selectDepartRef && selectDepartRef.current && selectDepartRef.current.clearValue();
        selectStatusRef && selectStatusRef.current && selectStatusRef.current.clearValue();
        setBool(!bool)
    };
    const getRowHeight = params => params.node.group ? 50 : 23;

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: `${pageLimit * pageSize} + (node.rowIndex + 1)`,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 70
        },
        {
            headerName: 'TITLE ',
            field: 'title',
            // cellRenderer: params => <p className='mb-0' >{params?.data?.title}</p>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            sortable: true,
            floatingFilter: false,
        },
        // {
        //     headerName: ' DESCRIPTION ',
        //     field: 'description',
        //     filter: 'agTextColumnFilter',
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        // },
        // {
        //     headerName: 'DURATION (IN HOURS)',
        //     field: 'duration',
        //     filter: 'agTextColumnFilter',
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        // },
        {
            headerName: 'ASSIGN BY',
            field: 'assignbyuser.name',
            // cellRenderer: params => <span>{params?.data?.assignbyuser?.name}</span>,
            cellRenderer: params => params?.data?.assignbyuser?.name === 'Admin' ? <span style={{ textTransform: 'capitalize' }}>{params?.data?.assignbyuser?.name}</span> : <p className='mb-0' style={{ lineHeight: '1.6', textTransform: 'capitalize' }}>{params?.data?.assignbyuser?.name}  <span style={{ color: "grey" }}> ({params?.data?.assignbyuser?.designations?.[0]?.name},{params?.data?.assignbyuser?.departments?.[0]?.name})</span></p>,
            filter: 'agTextColumnFilter',
            wrapText: true,
            autoHeight: true,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,

        },
        {
            headerName: 'ASSIGN TO',
            field: 'assign_to.name',
            // cellRenderer: params => <span>{params?.data?.assign_to?.name}  ({params?.data?.assign_to?.designation_id?.name},{params?.data?.assign_to?.department_id?.name})</span>,
            cellRenderer: params => params?.data?.assigntouser?.name === 'Admin' ? <span style={{ textTransform: 'capitalize' }}>{params?.data?.assigntouser?.name}</span> : <p className='mb-0' style={{ lineHeight: '1.6', textTransform: "capitalize" }}>{params?.data?.assigntouser?.name}  <span style={{ color: "grey" }}> ({params?.data?.assigntouser?.designations?.[0]?.name},{params?.data?.assigntouser?.departments?.[0]?.name})</span></p>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            wrapText: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // hide: admin === 'User'

        },

        {
            headerName: 'DEADLINE',
            field: 'deadline',
            filter: 'agTextColumnFilter',
            cellRenderer: params => new Date(params.data.deadline).toLocaleDateString('en-GB'),
            suppressMenu: true,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 120

        },
        {
            headerName: 'TIME',
            field: 'time',
            filter: 'agTextColumnFilter',
            // cellRenderer: params => new Date(params.data.deadline).toLocaleDateString('en-GB'),
            suppressMenu: true,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 120

        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params =>

                params.data.status === 'completed' ? <span className='badge text-bg-success'>COMPLETED</span> :
                    params.data.status === 'cancelled' ? <span className='badge' style={{ backgroundColor: '#f16c4d' }}>CANCELLED</span> :
                        params.data.status === 'On Approval' ? <span className='badge text-bg-warning'>On Approval</span> :
                            params.data.status === 'rejected' ? <span className='badge text-bg-danger'>Rejected</span> :
                                new Date(currentDate).toLocaleDateString('en-GB') > new Date(params.data.deadline).toLocaleDateString('en-GB') && params.data.status === 'pending' ? <span className='badge ' style={{ backgroundColor: 'orangered' }}>OVERDUE</span> :
                                    params.data.status === 'pending' ? <span className='badge text-bg-danger'>PENDING</span> : '',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 120
        },
        {
            headerName: 'UPDATED DATE',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params =>
                params.data.status === 'completed' ? new Date(params.data.completedate).toLocaleDateString('en-GB')
                    : params.data.status === 'cancelled' ? new Date(params.data.canceldate).toLocaleDateString('en-GB')
                        : params.data.status === 'On Approval' ? new Date(params.data.on_approval_date).toLocaleDateString('en-GB')
                            : params.data.status === 'rejected' ? new Date(params.data.reject_date).toLocaleDateString('en-GB')
                                : params.data.status === 'pending' ? '-' : '',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },

        {
            field: 'ACTIONS',
            cellRenderer: TodoAction,
            cellRendererParams: {
                // data: data,
                setData: setData,
                details: details,
                bool: bool,
                setBool: setBool,
                reject: reject,
                setReject: setReject,
                setDetails: setDetails
            },
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
            maxWidth: 150
        }
    ]


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='d-flex justify-content-between align-items-center layout_head'>
                            <div className='d-flex align-items-center'>
                                <h3> Task List</h3>
                                {localStorage.getItem('type') === 'User' ? <div className='d-flex ms-3 align-items-center'>
                                    {permission && permission.some(val => val.action_display_name === 'Task List') ? <div>
                                        <Link to='/admin/task-list'><Button type='button' variant="" className={location.pathname === '/admin/task-list' ? 'rout_btn_act ps-3 pe-3' : 'rout_btn ps-3 pe-3'}>My Task</Button></Link>
                                    </div> : ""}
                                    {permission && permission.some(val => val.action_display_name === 'Task Assigned') ? <div className='ms-2'>
                                        <Link to='/admin/task-assigned '>  <Button type='button' variant="" className={location.pathname === '/admin/task-assigned' ? 'rout_btn_act ps-3 pe-3' : 'rout_btn ps-3 pe-3'}>Assigned Task</Button></Link>
                                    </div> : ""}
                                </div> : ""}
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search Title"
                                style={{ width: "250px" }}
                                onChange={(e) => handleSearch(e.target.value)}
                                className="form-control form_input"
                            />
                        </div>
                        {/* <div className="col-md-12"> */}

                        {/* </div> */}
                    </div>
                    {loading ?
                        <div>
                            <Loader />
                        </div>
                        :

                        <>
                            <div className=" col-md-12 ">
                                <form onSubmit={handleSubmit}>
                                    <div className="row ">
                                        {localStorage.getItem('type') === 'User' ?
                                            <div className='col-md-4'></div> : ''}
                                        {localStorage.getItem('type') === 'Admin' ?
                                            <div className='col-md-2 p-1'>
                                                <Select
                                                    closeMenu={true}
                                                    ref={selectRef}
                                                    placeholder={' User'}
                                                    disabled={false}
                                                    options={user_option}
                                                    name='user'
                                                    className='react_select'
                                                    classNamePrefix="select"
                                                    defaultValue={""}
                                                    onChange={(e) => { setFieldValue('user', e && e.value); }}
                                                    onBlur={handleBlur}
                                                />
                                            </div> : ""}
                                        {localStorage.getItem('type') === 'Admin' ? <div className='col-md-2 p-1'>
                                            <Select
                                                closeMenu={true}
                                                ref={selectDepartRef}
                                                placeholder={' Department'}
                                                disabled={false}
                                                options={department_opt}
                                                name='department'
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                onChange={(e) => { setFieldValue('department', e && e.value); }}
                                                onBlur={handleBlur}
                                            />
                                        </div> : ""}
                                        <div className='col-md-2 p-1'>
                                            <Select
                                                closeMenu={true}
                                                ref={selectTypeRef}
                                                placeholder={' Type'}
                                                disabled={false}
                                                options={type_option}
                                                name='type'
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                onChange={(e) => { setFieldValue('type', e && e.value); }}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className='col-md-2 p-1 '>
                                            {/* <Form.Control
                                                        type="date"
                                                        placeholder='Select Date'
                                                        name='date'
                                                        className='form_input'
                                                        style={{ width: '135px' }}
                                                        value={values.date || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    /> */}
                                            <DatePicker
                                                label_name=''
                                                placeholder={'Choose Date'}
                                                option={{
                                                    altInput: true,
                                                    altFormat: "F j, Y",
                                                    dateFormat: 'Y-m-d',
                                                    minDate: '',
                                                    maxDate: '',
                                                    disable: [],
                                                    mode: "single",
                                                    defaultDate: [],
                                                    conjunction: "",
                                                }}
                                                name='date'
                                                value={values.date}
                                                onChange={(e) =>
                                                    setFieldValue('date', moment(e[0]).format("YYYY-MM-DD"))
                                                }
                                                onBlur={handleBlur}
                                                error={''}
                                            />
                                        </div>
                                        <div className='col-md-2 p-1'>
                                            <Select
                                                closeMenu={true}
                                                ref={selectStatusRef}
                                                placeholder={'Select Status'}
                                                disabled={false}
                                                options={status_option}
                                                name='status'
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                onChange={(e) => { setFieldValue('status', e && e.value); }}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className='col-md-2 p-1'>
                                            <div className='d-flex align-items-center'>
                                                <SaveButton name={'SEARCH'} />
                                                <span className='ms-2 refresh_btn' onClick={handleReset} ><IoRefreshOutline style={{ color: 'white' }} size={25} /></span>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>


                            {
                                filterLoad ?
                                    <div>
                                        <Loader />
                                    </div> :
                                    todoList && todoList.length ?
                                        <div className=''>
                                            <DataTablePagination
                                                rowData={todoList}
                                                columnDefs={columnDefs}
                                                getRowHeight={getRowHeight}
                                                height={'65vh'}
                                            />
                                            <div className='text-end pgn_tbl_footer mb-2'>
                                                <div className='d-flex justify-content-end align-items-center'>
                                                    <div className="d-flex align-items-center">
                                                        <label htmlFor="" className="form-label form_label" style={{ marginBottom: 0 }}>Page Size</label>
                                                        <select className="form-select form_input ms-2" style={{ width: '12vh' }} onChange={(e) => { setPageLimit(e.target.value); }}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                    </div>
                                                    <div className='d-flex align-items-center ms-3 table_footer_pagination' style={{ marginRight: '15px' }}>
                                                        <button type='button' disabled={pageSize == 0 ? true : false} onClick={() => { setPageSize(prev => prev - 1); }} className='btn pg_btn'><span className='me-2'><LuChevronLeft style={{ marginBottom: '2px' }} size={20} /></span></button>
                                                        <p><span>{(pageSize * pageLimit) + 1}</span> - <span>{TotalCount < (pageSize + 1) * pageLimit ? TotalCount : (pageSize + 1) * pageLimit}</span> of <span>{TotalCount}</span></p>
                                                        <button type='button' disabled={TotalCount <= (pageSize + 1) * pageLimit ? true : false} onClick={() => { setPageSize(prev => prev + 1); }} className='btn pg_btn'>   <span className='ms-2'><LuChevronRight style={{ marginBottom: '2px' }} size={20} /></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className='empty_section mt-3'>
                                            <span>DATA NOT FOUND</span>
                                        </div>
                            }
                        </>}
                </div>
            </div>
            <TodoDetails details={details} setDetails={setDetails} data={data} bool={bool} setBool={setBool} />
            <TodoReject reject={reject} setReject={setReject} data={data} bool={bool} setBool={setBool} />
            <ToastContainer />
        </>
    )
}
