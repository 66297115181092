import React, { useEffect, useState } from 'react'
import Loader from '../../../loader/Loader';
import { Form } from 'react-bootstrap';
import SaveButton from '../../../buttons/SaveButton';
import SingleSelect from '../../../input_filed/SingleSelect';
import DateInput from '../../../input_filed/Date';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { customerListRes, customerListTodo } from '../../../../redux/slices/super-admin-slices/customer/CustomerList';
import { PlanListTodo } from '../../../../redux/slices/super-admin-slices/plan/PlanList';
import { addAssignPlanRes, addAssignPlanTodo } from '../../../../redux/slices/super-admin-slices/asign-plan/AddAssignPlan';
import { toast } from 'react-toastify';

export default function AddAssignPlanForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const customer = useSelector(state => state?.CustomerList?.data?.data)
    const plan = useSelector(state => state?.PlanList?.data?.data)
    const [loading, setLoading] = useState(false)

    const customer_option = customer && customer.map(val => (
        { "value": val._id, "label": val.company_name }
    ))
    const plan_option = plan && plan.map(val => (
        { "value": val._id, "label": val.plan_name }
    ))

    const initialValues = {
        customer: '',
        plan: "",
        start_date: ""
    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        // validationSchema: DesignationSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(addAssignPlanTodo(values)).then(() => add_res(action))
        }
    })

    const add_res = (action) => {
        if (addAssignPlanRes?.status) {
            setLoading(false)
            setBool(!bool)
            toast.success(addAssignPlanRes?.message, { position: "bottom-right" })
            action.resetForm()

        } else {
            setLoading(false)
            toast.error(addAssignPlanRes?.message, { position: "bottom-right" });
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(customerListTodo()).then(() => list_res())
    }, [])

    const list_res = () => {
        if (customerListRes?.status) {
            dispatch(PlanListTodo()).then((plan_res) => {
                if (plan_res?.payload?.status) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>

                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Customer'
                                    disabled={false}
                                    option={customer_option ? customer_option : []}
                                    name='customer'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('customer', e.value);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.customer && touched.customer ? (<span className='text-danger form_label' >{errors.customer}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Plan'
                                    disabled={false}
                                    option={plan_option ? plan_option : []}
                                    name='plan'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('plan', e.value);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.plan && touched.plan ? (<span className='text-danger form_label' >{errors.plan}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <DateInput
                                    label_name='Start Date'
                                    placeholder=''
                                    name='start_date'
                                    min={new Date().toISOString().split('T')[0]}
                                    value={values.start_date || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.start_date && touched.start_date ? (<span className='text-danger form_label' >{errors.start_date}</span>) : null}
                                />
                            </div>


                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
        </>
    )
}
