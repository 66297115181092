import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addDealerRes;
export const addDealerTodo = createAsyncThunk('addDealer', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_dealer',
            data: {
                "username":data.name,
                "email":data.email,
                "password":data.psw,
                "phone":data.phn,
                "country":data.country,
                "state":data.state,
                "city":data.city,
                "status":data.status,
                // "added_by":localStorage.getItem('id'),
            },
            headers: { "Content-Type": "application/json" }
        })
        return addDealerRes = res.data
        
    } catch (error) {
        return addDealerRes = error.response.data
    }
})


