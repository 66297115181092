import React from 'react'
import AdminUpdateStaffForm from '../../../../component/form/admin-form/admin-master-form/admin-staff-form/AdminUpdateStaffForm'

export default function AdminUpdateStaff() {
  return (
   <>
      <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update User</h3>
                            <AdminUpdateStaffForm  />
                        </div>
                    </div>
                </div>
            </div>
   </>
  )
}
