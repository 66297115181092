import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaRegComment, FaUserCircle, FaVideo } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineGridOn } from 'react-icons/md'
import { useLocation } from 'react-router-dom';
import Loader from '../../../component/loader/Loader';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ReelsDetailsRes, ReelsDetailsTodo } from '../../../redux/slices/admin-slices/reels/ReelsDetails'
import { ReelsCommentRes, ReelsCommentTodo } from '../../../redux/slices/admin-slices/reels/ReelsCommentList'

export default function ReelsDetails() {

    const { state } = useLocation()
    var reelsId = state && state._id
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const detailsData = useSelector(state => state && state.ReelsDetails && state.ReelsDetails.data && state.ReelsDetails.data.data && state.ReelsDetails.data.data.reelsData && state.ReelsDetails.data.data.reelsData[0])
    const reelsComment = useSelector(state => state && state.ReelsComment && state.ReelsComment.data && state.ReelsComment.data.data)
    console.log("reelsComment", reelsComment)

    useEffect(() => {
        setLoading(true)
        dispatch(ReelsDetailsTodo(reelsId)).then(() => details_res())

    }, [])

    const details_res = () => {
        if (ReelsDetailsRes && ReelsDetailsRes.status) {
            dispatch(ReelsCommentTodo(reelsId)).then(() => comment_res())
        } else {
            setLoading(false)
        }
    }

    const comment_res = () => {
        if (ReelsCommentRes && ReelsCommentRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="container-fluid">
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='layout_head'>
                                <h3> Post Details</h3>
                            </div>
                            <div className='details_sidebar'>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <p>User Name </p>
                                    <span>{detailsData && detailsData.user_id && detailsData.user_id.name}</span>
                                </div>

                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p> Date </p>
                                    <span>{new Date(detailsData && detailsData.reel_date).toLocaleDateString('en-GB')}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Likes </p>
                                    {/* <span>{detailsData && detailsData.likesCount}</span> */}
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Comments </p>
                                    {/* <span>{postComment.length}</span> */}
                                </div>
                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                    <p>Status </p>
                                    <span>{detailsData && detailsData.reel_status}</span>
                                </div>
                                {/* <div className='mt-2'>
                                    <p>Title</p>
                                    <span>{detailsData && detailsData.reel_title} </span>
                                </div>
                                <div className='mt-2'>
                                    <p>Description</p>
                                    <span>{detailsData && detailsData.reel_description}</span>
                                </div> */}

                            </div>
                        </div>
                        <div className="col-md-9">
                            <Tabs
                                defaultActiveKey="Reels"
                                id="fill-tab-example"
                                className="mb-3 piils_btn"
                                fill
                            >
                                <Tab eventKey="Reels" title={<span> Reels<FaVideo className='mb-1 ms-1' size={18} /></span>}>
                                    <div className="details_section pe-3 ps-3">
                                        <h4>{detailsData && detailsData.reel_title}</h4>
                                        <h6>{detailsData && detailsData.reel_description}</h6>
                                        <div className="col-md-6 mt-2"  >
                                            <div className="text-center" >
                                                {/* <iframe src={process.env.REACT_APP_IMAGE_URL + `${value.media_name}`} title="YouTube video" allowFullScreen style={{ minWidth: '450px', minHeight: '300px' }}></iframe> */}
                                                <video width="400" height="300" controls>
                                                    <source src={process.env.REACT_APP_IMAGE_URL + `${detailsData && detailsData.reel_file}`} style={{ borderRadius: '15px' }} type="video/mp4" />

                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Comments" title={<span> Comments<FaRegComment className='mb-1 ms-1' size={18} /></span>}>
                                    <div className="details_section">
                                        <PerfectScrollbar>
                                            <div className='ps-3 pe-3'>
                                            {reelsComment && reelsComment.length ?
                                                        <div className="row ms-0 me-0">
                                                            {reelsComment && reelsComment.map((value1, index) =>
                                                                <div className="col-md-12 mt-2 mb-1" key={index}>
                                                                    <div className='comment_section'>
                                                                        <div className='d-flex align-items-center ' >
                                                                            <h6 style={{ textTransform: "capitalize" }}><FaUserCircle size={22} className='mb-1 me-2' style={{ color: "#4a4c50" }} />{value1.user_id && value1.user_id.name}</h6>
                                                                            <p className='ms-2'>( Date : {value1.createdAt === null ? '-' : new Date(value1.createdAt).toLocaleDateString('en-GB')} ) </p>
                                                                        </div>
                                                                        <div className='mt-1 '>
                                                                            <span style={{ textTransform: "capitalize" }}>{value1.description}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            )}

                                                        </div> :
                                                        <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
                                                            <h3 style={{ color: "#9d9d9d" }}> Reels Comments Empty</h3>
                                                        </div>
                                                    }
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>}
            </div>
        </>
    )
}
