import React, { useEffect, useState } from 'react'
import TimeInput from '../../../input_filed/Time'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Loader from '../../../loader/Loader'
import { Form } from 'react-bootstrap'
import Text from '../../../input_filed/Text'
import DateInput from '../../../input_filed/Date'
import TextArea from '../../../input_filed/TextArea'
import SaveButton from '../../../buttons/SaveButton'
import { MeetingSchema } from './Validation'
import { AddMeetingRes, AddMeetingTodo } from '../../../../redux/slices/admin-slices/meeting/AddMeeting'
import { ToastContainer, toast } from 'react-toastify'
import { UserListRes, UserListTodo } from '../../../../redux/slices/admin-slices/User/UserList'
import MultiSelect from '../../../input_filed/MultiSelect'
import TimePicker from '../../../input_filed/TimePicker'
import moment from 'moment'
import DatePicker from '../../../input_filed/DatePicker'
import SingleSelect from '../../../input_filed/SingleSelect'
import { GroupListTodo } from '../../../../redux/slices/admin-slices/admin-master/admin-group/GroupList'

export default function AddMeetingForm() {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userList = useSelector(state => state?.UserList?.data?.data)
    const GroupList = useSelector(state => state && state.GroupList && state.GroupList.data && state.GroupList.data.data?.[0]?.groupData)

    const UserOpt = userList && userList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    // const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
    const formattedTime24hr = `${hours}:${minutes.toString().padStart(2, '0')}`;

    const GroupOpt = GroupList && GroupList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    const type_opt = [
        { 'value': 'online', 'label': 'Online' },
        { "value": 'offline', 'label': 'Offline' }
    ]
    const filter_type_opt = [
        { 'value': 'user', 'label': 'User' },
        { "value": 'group', 'label': 'Group' }
    ]


    const initialValues = {
        title: '',
        description: "",
        start_date: "",
        attendees: [],
        duration: "",
        time: '',
        type: '',
        location: "",
        filter_typ: '',
        group: ''
    }


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: MeetingSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddMeetingTodo(values)).then(() => add_res(action))

        }
    })

    const add_res = (action) => {
        if (AddMeetingRes?.status) {
            toast.success(AddMeetingRes?.message, { position: 'bottom-right' })
            setLoading(false)
            setTimeout(() => {
                navigate('/admin/meeting-list')
            }, 1000);
        } else {
            setLoading(false)
            toast.error(AddMeetingRes?.message, { position: 'bottom-right' })
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(UserListTodo({ "type": '' })).then(() => list_res())
    }, [])

    const list_res = () => {
        if (UserListRes?.status) {
            dispatch(GroupListTodo()).then((group_res => {
                if (group_res?.payload?.data?.status) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }))
        } else {
            setLoading(false)
        }
    }
    return (
        <>

            {loading ?
                <div className='d-flex justify-content-center'>
                    <Loader />
                </div>
                :
                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <div className='form_layout mt-2'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Text
                                    label_name='Title'
                                    placeholder=''
                                    name='title'
                                    value={values.title || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.title && touched.title ? (<span className='text-danger form_label' >{errors.title}</span>) : null}
                                />
                            </div>
                            <div className='col-md-6'>
                                <TextArea
                                    label_name='Description'
                                    placeholder=''
                                    rows={'1'}
                                    name='description'
                                    value={values.description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.description && touched.description ? (<span className='text-danger form_label' >{errors.description}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Meeting Type'
                                    disabled={false}
                                    option={type_opt}
                                    name='type'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('type', e.value);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.type && touched.type ? (<span className='text-danger form_label' >{errors.type}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                <Text
                                    label_name={'Meeting Location'}
                                    placeholder=''
                                    name='location'
                                    value={values.location || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.location && touched.location ? (<span className='text-danger form_label' >{errors.location}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                {/* <DateInput
                                    // label_name='Start Date'
                                    // placeholder=''
                                    // name='start_date'
                                    // min={new Date().toISOString().split("T")[0]}
                                    // value={values.start_date || ''}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // error={errors.start_date && touched.start_date ? (<span className='text-danger form_label' >{errors.start_date}</span>) : null}
                                /> */}
                                <DatePicker
                                    label_name='Start Date'
                                    placeholder={'Choose Date'}
                                    name='start_date'
                                    option={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: 'Y-m-d',
                                        minDate: new Date().toISOString().split("T")[0],
                                        maxDate: '',
                                        disable: [],
                                        mode: "single",
                                        defaultDate: [],
                                        conjunction: "",
                                        static: true
                                    }}
                                    value={values.start_date || ''}
                                    onChange={(e) => {
                                        setFieldValue('start_date', moment(e[0]).format("YYYY-MM-DD"))
                                        setFieldValue('time', '')
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.start_date && touched.start_date ? (<span className='text-danger form_label' >{errors.start_date}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                {/* <TimeInput
                                                label_name='Time'
                                                placeholder=''
                                                name='time'
                                                value={values.time || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.time && touched.time ? (<span className='text-danger form_label' >{errors.time}</span>) : null}
                                            /> */}
                                <TimePicker
                                    label_name='Start Time'
                                    placeholder=''
                                    option={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: 'G:i K', // Customize date format as needed
                                        time_24hr: false,
                                        minTime:  '', // Before dates disabled
                                        maxTime: '', // After dates disabled
                                        disable: [], // Disable particular date ranges
                                        static: true

                                    }}
                                    name='time'
                                    onChange={(e) =>
                                        setFieldValue('time', moment(e[0]).format('hh:mm A'))
                                    }
                                    onBlur={handleBlur}
                                    error={errors.time && touched.time ? (<span className='text-danger form_label' >{errors.time}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                <Text
                                    label_name='Duration in Hours'
                                    placeholder=''
                                    name='duration'
                                    value={values.duration || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.duration && touched.duration ? (<span className='text-danger form_label' >{errors.duration}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Filter Type'
                                    disabled={false}
                                    option={filter_type_opt}
                                    name='filter_typ'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('filter_typ', e.value);
                                        setFieldValue('attendees', []);
                                        setFieldValue('group', '');
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.filter_typ && touched.filter_typ ? (<span className='text-danger form_label' >{errors.filter_typ}</span>) : null}
                                />
                            </div>
                            {values?.filter_typ === 'user' ?
                                <div className='col-md-3'>
                                    <MultiSelect
                                        closeMenu={false}
                                        label_name='Attendees User'
                                        disabled={false}
                                        option={UserOpt ? UserOpt : ''}
                                        name='attendees'
                                        defaultValue={""}
                                        onChange={(e) => {
                                            setFieldValue('attendees', e);
                                        }
                                        }
                                        onBlur={handleBlur}
                                        error={errors.attendees && touched.attendees ? (<span className='text-danger form_label' >{errors.attendees}</span>) : null}
                                    />
                                </div> : ''}
                            {values?.filter_typ === 'group' ?
                                <div className='col-md-3'>
                                    <SingleSelect
                                        closeMenu={true}
                                        label_name='Select Group'
                                        disabled={false}
                                        option={GroupOpt}
                                        name='group'
                                        defaultValue={""}
                                        onChange={(e) => {
                                            setFieldValue('group', e.value);
                                        }
                                        }
                                        onBlur={handleBlur}
                                        error={errors.group && touched.group ? (<span className='text-danger form_label' >{errors.group}</span>) : null}
                                    />
                                </div> : ''}
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>
                    </div>
                </Form >}

            <ToastContainer />
        </>
    )
}
