import React from 'react'
import CustomerPermissionForm from '../../../component/form/super-admin-form/permission-form/CustomerPermissionForm'

export default function CustomerPermission() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>Customer Permission</h3>
                        </div>
                        <CustomerPermissionForm/>
                    </div>
                </div>
            </div>
        </>
    )
}
