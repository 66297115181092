import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DeleteGroupRes;
export const DeleteGroupTodo = createAsyncThunk('deleted_group', async (id) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'deleted_group',
            data: {
                "id":id,
                "company_id": localStorage.getItem('customerId') 

            },
            headers: { "Content-Type": "application/json" }
        })
        return DeleteGroupRes = res.data

    } catch (error) {
        return DeleteGroupRes = error.response.data
    }
})