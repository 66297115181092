import { configureStore } from "@reduxjs/toolkit";
import CountryList from "./slices/country-state-city/CountryList";
import StateList from "./slices/country-state-city/StateList";
import CityList from "./slices/country-state-city/CityList";
import Dashboard from "./slices/super-admin-slices/dashboard/Dashboard";
import ModuleList from "./slices/super-admin-slices/module/ModuleList";
import ActionList from "./slices/super-admin-slices/action-slice/ActionList";
import CustomerList from "./slices/super-admin-slices/customer/CustomerList";
import UserTypeList from "./slices/super-admin-slices/user-type/UserTypeList";
import CustomerModuleList from "./slices/super-admin-slices/permission/customer-permission/CustomerModuleList";
import UserPermissionModuleList from "./slices/super-admin-slices/permission/user-permission/UserPermissionModuleList";
import AdminSidebar from "./slices/admin-slices/admin-sidebar/AdminSidebar";
import DesignationList from "./slices/super-admin-slices/master/designation/DesignationList";
import DepartmentList from "./slices/super-admin-slices/master/department/DepartmentList";
import StaffList from "./slices/super-admin-slices/master/staff/StaffList";
import AdminDesignationList from "./slices/admin-slices/admin-master/admin-designation/AdminDesignationList";
import AdminDepartmentList from "./slices/admin-slices/admin-master/admin-department/AdminDepartmentList";
import AdminTypeList from "./slices/admin-slices/admin-master/admin-type/AdminTypeList";
import AdminStaffList from "./slices/admin-slices/admin-master/admin-staff/AdminStaffList";
import PostList from "./slices/admin-slices/post/PostList";
import ReelsList from "./slices/admin-slices/reels/ReelsList";
import UserList from "./slices/admin-slices/User/UserList";
import MeetingList from "./slices/admin-slices/meeting/MeetingList";
import TodoList from "./slices/admin-slices/todo/TodoList";
import PostDetails from "./slices/admin-slices/post/PostDetails";
import PostCommentList from "./slices/admin-slices/post/PostCommentList";
import ReelsDetails from "./slices/admin-slices/reels/ReelsDetails";
import ReelsCommentList from "./slices/admin-slices/reels/ReelsCommentList";
import TodoDetails from "./slices/admin-slices/todo/TodoDetails";
import MeetingDetails from "./slices/admin-slices/meeting/MeetingDetails";
import ChatList from "./slices/admin-slices/chat/ChatList";
import AdminUserPermission from "./slices/admin-slices/permission/AdminUserPermission";
import AdminDashboard from "./slices/admin-slices/dashboard/AdminDashboard";
import DealerList from "./slices/super-admin-slices/dealer/DealerList";
import PlanList from "./slices/super-admin-slices/plan/PlanList";
import AssignPlanList from "./slices/super-admin-slices/asign-plan/AssignPlanList";
import SuperAdminDashboard from "./slices/super-admin-slices/superadmin-dashboard/SuperAdminDashboard";
import VersionDetails from "./slices/admin-slices/admin-app-version/VersionDetails";
import GroupList from "./slices/admin-slices/admin-master/admin-group/GroupList";
import ChatUserList from "./slices/admin-slices/User/ChatUserList";



const Store = configureStore({
    reducer: {

        // -------SUPER ADMIN STORE-------------
        SuperAdminDashboard: SuperAdminDashboard,
        CountryList: CountryList,
        StateList: StateList,
        CityList: CityList,
        ModuleList: ModuleList,
        ActionList: ActionList,
        CustomerList: CustomerList,
        UserTypeList: UserTypeList,
        CustomerModuleList: CustomerModuleList,
        UserPermissionList: UserPermissionModuleList,
        DesignationList: DesignationList,
        DepartmentList: DepartmentList,
        StaffList: StaffList,
        DealerList: DealerList,
        PlanList: PlanList,
        AssignPlanList: AssignPlanList,

        // -------------ADMIN STORE----------------
        Dashboard: Dashboard,
        AdminSidebar: AdminSidebar,
        AdminDashboard: AdminDashboard,
        AdminDesignationList: AdminDesignationList,
        AdminDepartmentList: AdminDepartmentList,
        GroupList:GroupList,
        AdminTypeList: AdminTypeList,
        AdminStaffList: AdminStaffList,
        UserList: UserList,
        ChatUserList : ChatUserList,
        PostList: PostList,
        PostDetails: PostDetails,
        PostComment: PostCommentList,
        ReelsList: ReelsList,
        ReelsDetails: ReelsDetails,
        ReelsComment: ReelsCommentList,
        MeetingList: MeetingList,
        MeetingDetails: MeetingDetails,
        TodoList: TodoList,
        TodoDetails: TodoDetails,
        ChatList: ChatList,
        AdminUserPermissionList: AdminUserPermission,
        VersionDetails: VersionDetails,
    }
})
export default Store;