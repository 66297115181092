import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom';
import AdminLogin from '../pages/admin-pages/login/AdminLogin';
import AdminProtectedRoutes from '../AdminProtectedRoute';
import AdminDashboard from '../pages/admin-pages/dashboard/AdminDashboard';
import AdminAddDepartment from '../pages/admin-pages/admin-master/admin-department/AdminAddDepartment';
import AdminUpdateDepartment from '../pages/admin-pages/admin-master/admin-department/AdminUpdateDepartment';
import AdminAddDesignation from '../pages/admin-pages/admin-master/admin-designation/AdminAddDesignation';
import AdminUpdateDesignation from '../pages/admin-pages/admin-master/admin-designation/AdminUpdateDesignation';
import AdminAddUserType from '../pages/admin-pages/admin-master/admin-type/AdminAddUserType';
import AdminUpdateUserType from '../pages/admin-pages/admin-master/admin-type/AdminUpdateUserType';
import AdminAddStaff from '../pages/admin-pages/admin-master/admin-user/AdminAddStaff';
import AdminUpdateStaff from '../pages/admin-pages/admin-master/admin-user/AdminUpdateStaff';
import PostList from '../pages/admin-pages/post/PostList';
import ReelsList from '../pages/admin-pages/reels/ReelsList';
import MeetingList from '../pages/admin-pages/meeting/MeetingList';
import TodoList from '../pages/admin-pages/Todo/TodoList';
import PostDetails from '../pages/admin-pages/post/PostDetails';
import ReelsDetails from '../pages/admin-pages/reels/ReelsDetails';
import TodoDetails from '../pages/admin-pages/Todo/TodoDetails';
import MeetingDetails from '../pages/admin-pages/meeting/MeetingDetails';
import ChatList from '../pages/admin-pages/chat/ChatList';
import AdminUserPermission from '../pages/admin-pages/permission/AdminUserPermission';

import SuperAdminLogin from '../pages/super-admin-pages/super-admin-login/SuperAdminLogin';
import SuperAdminProtectedRoutes from '../SuperAdminProtectedRoute';
import SuperAdminDashboard from '../pages/super-admin-pages/dashboard/SuperAdminDashboard';
import AddDepartment from '../pages/super-admin-pages/master/department/AddDepartment';
import UpdateDepartment from '../pages/super-admin-pages/master/department/UpdateDepartment';
import AddDesignation from '../pages/super-admin-pages/master/designation/AddDesignation';
import UpdateDesignation from '../pages/super-admin-pages/master/designation/UpdateDesignation';
import AddStaff from '../pages/super-admin-pages/master/staff/AddStaff';
import UpdateStaff from '../pages/super-admin-pages/master/staff/UpdateStaff';
import AddUserType from '../pages/super-admin-pages/user-type/AddUserType';
import UpdateUserType from '../pages/super-admin-pages/user-type/UpdateUserType';
import AddCustomer from '../pages/super-admin-pages/customer/AddCustomer';
import UpdateCustomer from '../pages/super-admin-pages/customer/UpdateCustomer';
import CustomerList from '../pages/super-admin-pages/customer/CustomerList';
import AddModule from '../pages/super-admin-pages/module/AddModule';
import ModuleList from '../pages/super-admin-pages/module/ModuleList';
import UpdateModule from '../pages/super-admin-pages/module/UpdateModule';
import AddAction from '../pages/super-admin-pages/actions/AddAction';
import ActionList from '../pages/super-admin-pages/actions/ActionList';
import UpdateAction from '../pages/super-admin-pages/actions/UpdateAction';
import CustomerPermission from '../pages/super-admin-pages/permission/CustomerPermission';
import UserPermission from '../pages/super-admin-pages/permission/UserPermission';
import PageNotFound from './PageNotFound';
import ProtectedRoutes from '../ProtectedRoute';
import AddDealer from '../pages/super-admin-pages/dealer/AddDealer';
import UpdateDealer from '../pages/super-admin-pages/dealer/UpdateDealer';
import DealerList from '../pages/super-admin-pages/dealer/DealerList';
import AddPlan from '../pages/super-admin-pages/plan/AddPlan';
import UpdatePlan from '../pages/super-admin-pages/plan/UpdatePlan';
import AddAssignPlan from '../pages/super-admin-pages/assign-plan/AddAssignPlan';
import UpdateAssignPlan from '../pages/super-admin-pages/assign-plan/UpdateAssignPlan';
import AddTodo from '../pages/admin-pages/Todo/AddTodo';
import AddMeeting from '../pages/admin-pages/meeting/AddMeeting';
import UpdateVersion from '../pages/admin-pages/app-version/UpdateVersion';
import AdminStaffList from '../pages/admin-pages/admin-master/admin-user/AdminStaffList';
import TaskAssigned from '../pages/admin-pages/Todo/TaskAssigned';
import AddGroup from '../pages/admin-pages/admin-master/add-group/AddGroup';
import UpdateGroup from '../pages/admin-pages/admin-master/add-group/UpdateGroup';
import HowToUse from '../pages/admin-pages/how-to-use/HowToUse';

export default function AdminRoute() {
    return (
        <>
            <Routes>
                <Route path='/admin/login' element={<AdminLogin />} />
                <Route path='/superadmin/login' element={<SuperAdminLogin />} />
                <Route path='/' element={<Navigate replace to='../admin/login' />} />
                {/* <Route path='/.well-known\acme-challenge\1PtaY0QJmlPnUPwq35T_z_eL0hp3UekAY5y9OTI9I5E'/> */}
                <Route path='*' element={<PageNotFound />} />
                <Route element={<ProtectedRoutes />}>

                    {/* <Route path='/' element={<Navigate replace to='../admin/dashboard' />} /> */}
                    <Route path='/admin/dashboard' element={<AdminDashboard />} />

                    <Route path='/admin/department' element={<AdminAddDepartment />} />
                    <Route path='/admin/update-department' element={<AdminUpdateDepartment />} />

                    <Route path='/admin/designation' element={<AdminAddDesignation />} />
                    <Route path='/admin/update-designation' element={<AdminUpdateDesignation />} />

                    <Route path='/admin/usertype' element={<AdminAddUserType />} />
                    <Route path='/admin/update-usertype' element={<AdminUpdateUserType />} />

                    <Route path='/admin/user-list' element={<AdminStaffList />} />
                    <Route path='/admin/add-user' element={<AdminAddStaff />} />
                    <Route path='/admin/update-user' element={<AdminUpdateStaff />} />

                    <Route path='/admin/post-list' element={<PostList />} />
                    <Route path='/admin/post-details' element={<PostDetails />} />

                    <Route path='/admin/reels-list' element={<ReelsList />} />
                    <Route path='/admin/reels-details' element={<ReelsDetails />} />

                    <Route path='/admin/add-meeting' element={<AddMeeting />} />
                    <Route path='/admin/meeting-list' element={<MeetingList />} />
                    <Route path='/admin/meeting-details' element={<MeetingDetails />} />

                    <Route path='/admin/add-task' element={<AddTodo />} />
                    <Route path='/admin/task-list' element={<TodoList />} />
                    <Route path='/admin/task-assigned' element={<TaskAssigned />} />
                    {/* <Route path='/admin/todo-details' element={<TodoDetails />} /> */}

                    <Route path='/admin/chat-list' element={<ChatList />} />

                    <Route path='/admin/user-permission' element={<AdminUserPermission />} />

                    <Route path='/admin/update-version' element={<UpdateVersion />} />

                    <Route path='/admin/group' element={<AddGroup />} />
                    <Route path='/admin/update-group' element={<UpdateGroup />} />


                    <Route path='/admin/faq-videos' element={<HowToUse />} />




                    {/* --------------------SUPER-ADMIN-ROUTE------------------------ */}
                    <Route path='/superadmin/dashboard' element={<SuperAdminDashboard />} />

                    <Route path='/superadmin/department' element={<AddDepartment />} />
                    <Route path='/superadmin/update-department' element={<UpdateDepartment />} />

                    <Route path='/superadmin/designation' element={<AddDesignation />} />
                    <Route path='/superadmin/update-designation' element={<UpdateDesignation />} />

                    <Route path='/superadmin/staff' element={<AddStaff />} />
                    <Route path='/superadmin/update-staff' element={<UpdateStaff />} />

                    <Route path='/superadmin/user-type' element={<AddUserType />} />
                    <Route path='/superadmin/update-usertype' element={<UpdateUserType />} />


                    <Route path='/superadmin/add-customer' element={<AddCustomer />} />
                    <Route path='/superadmin/update-customer' element={<UpdateCustomer />} />
                    <Route path='/superadmin/customer-list' element={<CustomerList />} />

                    <Route path='/superadmin/add-module' element={<AddModule />} />
                    <Route path='/superadmin/module-list' element={<ModuleList />} />
                    <Route path='/superadmin/update-module' element={<UpdateModule />} />

                    <Route path='/superadmin/add-action' element={<AddAction />} />
                    <Route path='/superadmin/action-list' element={<ActionList />} />
                    <Route path='/superadmin/update-action' element={<UpdateAction />} />

                    <Route path='/superadmin/customer-permission' element={<CustomerPermission />} />
                    <Route path='/superadmin/user-permission' element={<UserPermission />} />

                    <Route path='/superadmin/add-dealer' element={<AddDealer />} />
                    <Route path='/superadmin/update-dealer' element={<UpdateDealer />} />
                    <Route path='/superadmin/dealer-list' element={<DealerList />} />

                    <Route path='/superadmin/plan' element={<AddPlan />} />
                    <Route path='/superadmin/update-plan' element={<UpdatePlan />} />

                    <Route path='/superadmin/assign-plan' element={<AddAssignPlan />} />
                    <Route path='/superadmin/update-assign-plan' element={<UpdateAssignPlan />} />

                </Route>
            </Routes>
        </>
    )
}
