import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'
import DataTable from '../../../../component/data-table/DataTable'
import { adminDepartmentListRes, adminDepartmentListTodo } from '../../../../redux/slices/admin-slices/admin-master/admin-department/AdminDepartmentList'
import AdminDepartmentAction from '../../../../component/data-table-action/table_action/admin-action/admin-master-action/AdminDepartmentAction'

export default function AdminDepartmentList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const departmentList = useSelector(state => state && state.AdminDepartmentList && state.AdminDepartmentList.data && state.AdminDepartmentList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(adminDepartmentListTodo()).then(() => departmentList_res())
    }, [bool])

    const departmentList_res = () => {
        if (adminDepartmentListRes && adminDepartmentListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'DEPARTMENT',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge   text-bg-success'>ACTIVE</span> : <span className='badge   text-bg-danger'>INACTIVE</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            width: 120

            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: AdminDepartmentAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                departmentList && departmentList.length ?
                    <DataTable
                        rowData={departmentList}
                        columnDefs={columnDefs}
                        height={'70vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

            {/* <ToastContainer /> */}

        </>
    )
}

