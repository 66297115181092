import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../../../loader/Loader'
import SelectStatus from '../../../../input_filed/SelectStatus'
import Text from '../../../../input_filed/Text'
import SaveButton from '../../../../buttons/SaveButton'
import { DesignationSchema } from '../../../super-admin-form/master/designation-form/Validation'
import { adminAddDesignationRes, adminAddDesignationTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-designation/AddDesignation'

export default function AdminAddDesignationForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const initialValues = {
        designation: '',
        status: ""
    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: DesignationSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(adminAddDesignationTodo(values)).then(() => addDesignation_res(action))
        }
    })

    const addDesignation_res = (action) => {
        if (adminAddDesignationRes && adminAddDesignationRes.status) {
            setLoading(false)
            setBool(!bool)
            toast.success(adminAddDesignationRes && adminAddDesignationRes.message, { position: "bottom-right" })
            action.resetForm()

        } else {
            setLoading(false)
            toast.error(adminAddDesignationRes && adminAddDesignationRes.message, { position: "bottom-right" });
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Designation'
                                    placeholder=''
                                    name='designation'
                                    value={values.designation || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.designation && touched.designation ? (<span className='text-danger form_label' >{errors.designation}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
            {/* <ToastContainer /> */}
        </>
    )
}
