import React from 'react'
import UpdateDepartmentForm from '../../../../component/form/super-admin-form/master/department/UpdateDepartmentForm'

export default function UpdateDepartment() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className='layout_head'>
              <h3>Update Department</h3>
              <UpdateDepartmentForm />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
