import React, { useEffect, useRef, useState } from 'react'
import DataTablePagination from '../../../component/data-table/DataTablePagination'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../component/loader/Loader'
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import SaveButton from '../../../component/buttons/SaveButton'
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList'
import { useFormik } from 'formik'
import moment from 'moment';
import { IoRefreshOutline } from 'react-icons/io5'
import { MeetingListRes, MeetingListTodo } from '../../../redux/slices/admin-slices/meeting/MeetingList'
import MeetingAction from '../../../component/data-table-action/table_action/admin-action/MeetingAction'
import { ToastContainer } from 'react-toastify'
import { adminDepartmentListTodo } from '../../../redux/slices/admin-slices/admin-master/admin-department/AdminDepartmentList'
import { adminDesignationListTodo } from '../../../redux/slices/admin-slices/admin-master/admin-designation/AdminDesignationList'
import DatePicker from '../../../component/input_filed/DatePicker'

export default function MeetingList() {

    const dispatch = useDispatch()
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(false)
    const meetingList = useSelector(state => state?.MeetingList?.data?.data?.[0]?.meetingData)
    const userList = useSelector(state => state && state.UserList && state.UserList.data && state.UserList.data.data)
    const TotalCount = useSelector(state => state?.MeetingList?.data?.data?.[0]?.totalmeetingcount?.[0]?.total)
    const currentDate = useSelector(state=> state?.MeetingList?.data?.data?.[0]?.currentDate)
    const [pageLimit, setPageLimit] = useState(10)
    const [pageSize, setPageSize] = useState(0)
    const [filterLoad, setFilterLoad] = useState(false)
    const departmentList = useSelector(state => state?.AdminDepartmentList?.data?.data)
    // const admin = localStorage.getItem('type')
    // const currentuser = { "value": localStorage.getItem('id'), "label": "Self" }

    const user_option = userList && userList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    console.log(currentDate)

    const department_opt = departmentList && departmentList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    //  user_option?.push(currentuser);

    const status_option = [
        { "value": 'completed', "label": "Completed" },
        { "value": 'incompleted', "label": "Incompleted" },
        { "value": 'pending', "label": "Pending" },
        { "value": 'cancelled', "label": "Cancelled" },
        { "value": 'overdue', "label": "Overdue" },
    ]

    const paginationInc = meetingList && meetingList.length

    useEffect(() => {
        setLoading(true)
        dispatch(MeetingListTodo({ "pageLimit": pageLimit, "pageSize": pageSize })).then(() => meetinglist_res())
    }, [bool])

    useEffect(() => {
        dispatch(MeetingListTodo({ "pageLimit": pageLimit, "pageSize": pageSize }))
    }, [pageLimit, pageSize])


    const meetinglist_res = () => {
        if (MeetingListRes && MeetingListRes.status) {
            dispatch(UserListTodo({ "type": '1', 'id': '' })).then(() => userList_res())

        } else {
            setLoading(false)
        }
    }
    const userList_res = () => {
        if (UserListRes && UserListRes.status) {
            // setLoading(false)
            dispatch(adminDepartmentListTodo()).then((department_res) => {
                if (department_res?.payload?.status) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }

    const initialValues = {
        user: '',
        department: '',
        date: "",
        status: '',
        // pageLimit: pageLimit,
        // pageSize: pageSize

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,

        onSubmit: (values, action) => {
            setFilterLoad(true)
            dispatch(MeetingListTodo({ values, "pageLimit": pageLimit, "pageSize": pageSize })).then(() => filter_res())
        }
    })

    const filter_res = () => {
        if (MeetingListRes && MeetingListRes.status) {
            setFilterLoad(false)
        } else {
            setFilterLoad(false)
        }
    }

    const handleSearch = (data) => {
        dispatch(MeetingListTodo({ "title": data })).then(() => filter_res())
    }

    const selectRef = useRef();
    const selectDepartRef = useRef();
    const selectStatusRef = useRef();
    const handleReset = () => {
        values.date = ""
        selectRef && selectRef.current && selectRef.current.clearValue();
        selectDepartRef && selectDepartRef.current && selectDepartRef.current.clearValue();
        selectStatusRef && selectStatusRef.current && selectStatusRef.current.clearValue();
        setBool(!bool)
    };

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: `${pageLimit * pageSize} + (node.rowIndex + 1)`,
            filter: 'false',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 80
        },
        {
            headerName: ' MEETING TITLE ',
            field: 'title',
            cellRenderer: params => <p className='mb-0 ' >{params?.data?.title}</p>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            wrapText: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },
        {
            headerName: ' OWNER ',
            field: 'addusers.name',
            // cellRenderer: params => <span>{params?.data?.addusers?.name}</span>,
            cellRenderer: params => params?.data?.addusers?.designations?.length ? <p className='mb-0' style={{ lineHeight: '1.6', textTransform: 'capitalize' }}>{params?.data?.addusers?.name}  <span style={{ color: "grey" }}> ({params?.data?.addusers?.designations?.[0]?.name},{params?.data?.addusers?.departments?.[0]?.name})</span></p> : <span>{params?.data?.addusers?.name}</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            wrapText: true,
            autoHeight: true,
            width: '150px',
            filter: true,
            sortable: true,
            floatingFilter: false,
        },

        {
            headerName: 'DATE',
            field: 'date',
            filter: 'agTextColumnFilter',
            cellRenderer: params => new Date(params.data.date).toLocaleDateString('en-GB'),
            suppressMenu: true,
            filter: true,
            sortable: true,
            maxWidth: 130,
            floatingFilter: false,
        },
        {
            headerName: 'TIME',
            field: 'time',
            // cellRenderer: params => moment(params && params.data && params.data.time, "HH:mm:ss").format('hh:mm A'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            maxWidth: 130,
            sortable: true,
            floatingFilter: false,
        },

        {
            headerName: 'DURATION',
            field: 'duration',
            cellRenderer: params => <span>{params?.data?.duration} Hrs</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            suppressMenu: true,
            filter: true,
            maxWidth: 130,
            sortable: true,
            floatingFilter: false,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params =>
                params?.data?.status === 'completed' ? <span className='badge text-bg-success'>COMPLETED</span>
                    : params?.data?.status === 'incompleted' ? <span className='badge text-bg-danger'>INCOMPLETED</span>
                        : params?.data?.status === 'cancelled' ? <span className='badge' style={{backgroundColor:'#f16c4d'}}>CANCELLED</span>
                            : new Date(currentDate).toLocaleDateString('en-GB') > new Date(params.data.date).toLocaleDateString('en-GB') && params?.data?.status === 'pending' ? <span className='badge ' style={{ backgroundColor: 'orangered' }}>OVERDUE</span>
                                : params?.data?.status === 'pending' ? <span className='badge text-bg-danger'>PENDING</span> : "",
            suppressMenu: true,
            maxWidth: 130,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },

        {
            field: 'ACTIONS',
            cellRenderer: MeetingAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
            },
            maxWidth: 130,
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='d-flex justify-content-between align-items-center layout_head'>
                            <h3 className='mt-1'> Meeting List</h3>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search Title"
                                style={{ width: "250px" }}
                                onChange={(e) => handleSearch(e.target.value)}
                                className="form-control form_input"
                            />
                        </div>
                        {loading ?
                            <div className='mt-5 pt-5'>
                                <Loader />
                            </div>
                            :

                            <div >
                                <div className=" row align-items-center">

                                    <div className=" col-md-12 ">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row  ">
                                                <div className='col-md-2 p-1'></div>
                                                {localStorage.getItem('type') === 'Admin' ? "" : <div className='col-md-2 p-1'></div>}
                                                <div className='col-md-2 p-1'>
                                                    <Select
                                                        closeMenu={true}
                                                        ref={selectRef}
                                                        placeholder={'Select User'}
                                                        disabled={false}
                                                        options={user_option}
                                                        name='user'
                                                        className='react_select'
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        onChange={(e) => { setFieldValue('user', e && e.value); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                {/* <div className='col-md-2 p-1'>
                                                    <Select
                                                        closeMenu={true}
                                                        ref={selectRef}
                                                        placeholder={' Designation'}
                                                        disabled={false}
                                                        options={designation_opt}
                                                        name='designation'
                                                        className='react_select'
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        onChange={(e) => { setFieldValue('designation', e && e.value); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div> */}
                                                {localStorage.getItem('type') === 'Admin' ? <div className='col-md-2 p-1'>
                                                    <Select
                                                        closeMenu={true}
                                                        ref={selectDepartRef}
                                                        placeholder={' Department'}
                                                        disabled={false}
                                                        options={department_opt}
                                                        name='department'
                                                        className='react_select'
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        onChange={(e) => { setFieldValue('department', e && e.value); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div> : ''}
                                                <div className='col-md-2 p-1' >
                                                    {/* <Form.Control
                                                        type="date"
                                                        placeholder='Select Date'
                                                        name='date'
                                                        style={{ width: '135px' }}
                                                        className='form_input'
                                                        value={values.date || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    /> */}
                                                    <DatePicker
                                                        label_name=''
                                                        placeholder={'Choose Date'}
                                                        style={{ width: "150px" }}
                                                        option={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            dateFormat: 'Y-m-d',
                                                            minDate: '',
                                                            maxDate: '',
                                                            disable: [],
                                                            mode: "single",
                                                            defaultDate: [],
                                                            conjunction: "",
                                                        }}
                                                        name='date'
                                                        value={values.date}
                                                        onChange={(e) =>
                                                            setFieldValue('date', moment(e[0]).format("YYYY-MM-DD"))
                                                        }
                                                        onBlur={handleBlur}
                                                        error={''}
                                                    />
                                                </div>
                                                <div className='col-md-2 p-1'>
                                                    <Select
                                                        closeMenu={true}
                                                        ref={selectStatusRef}
                                                        placeholder={' Status'}
                                                        disabled={false}
                                                        options={status_option}
                                                        name='status'
                                                        className='react_select'
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        onChange={(e) => { setFieldValue('status', e && e.value); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                <div className='col-md-2 p-1'>
                                                    <div className='d-flex align-items-center'>
                                                        <SaveButton name={'Search'} />
                                                        <span className='ms-1 refresh_btn' onClick={handleReset} ><IoRefreshOutline style={{ color: 'white' }} size={25} /></span>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {
                                    filterLoad ?
                                        <div>
                                            <Loader />
                                        </div> :
                                        meetingList && meetingList.length ?
                                            <div className='mt-2'>
                                                <DataTablePagination
                                                    rowData={meetingList}
                                                    columnDefs={columnDefs}
                                                    height={'65vh'}
                                                />
                                                <div className='text-end pgn_tbl_footer mb-2'>
                                                    <div className='d-flex justify-content-end align-items-center'>
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="" className="form-label form_label" style={{ marginBottom: 0 }}>Page Size</label>
                                                            <select className="form-select form_input ms-2" style={{ width: '12vh' }} onChange={(e) => { setPageLimit(e.target.value); }}>
                                                                <option value="10">10</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className='d-flex align-items-center ms-3 table_footer_pagination' style={{ marginRight: '15px' }}>
                                                            <button type='button' disabled={pageSize == 0 ? true : false} onClick={() => { setPageSize(prev => prev - 1); }} className='btn pg_btn'><span className='me-2'><LuChevronLeft style={{ marginBottom: '2px' }} size={20} /></span></button>
                                                            <p><span>{(pageSize * pageLimit) + 1}</span> - <span>{TotalCount < (pageSize + 1) * pageLimit ? TotalCount : (pageSize + 1) * pageLimit}</span> of <span>{TotalCount}</span></p>
                                                            <button type='button' disabled={TotalCount <= (pageSize + 1) * pageLimit ? true : false} onClick={() => { setPageSize(prev => prev + 1); }} className='btn pg_btn'>   <span className='ms-2'><LuChevronRight style={{ marginBottom: '2px' }} size={20} /></span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='empty_section mt-3'>
                                                <span>DATA NOT FOUND</span>
                                            </div>
                                }
                            </div>}
                    </div>
                </div>
            </div >
        </>
    )
}
