import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ReelsCommentRes;
export const ReelsCommentTodo = createAsyncThunk('ReelsComment', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'reel_comment_details',
            headers: { "Content-Type": "application/json" },
            data: {
                "reel_id":id,
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return ReelsCommentRes = res.data

    } catch (error) {
        return ReelsCommentRes = error.response.data
    }
});

const ReelsCommentSlice = createSlice({
    name: 'ReelsComment',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ReelsCommentTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(ReelsCommentTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ReelsCommentSlice.reducer; 