import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var SubmitAttandanceRes;
export const SubmitAttandanceTodo = createAsyncThunk('SubmitAttandance', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_attendence',
            data: {
                "meeting_id": data?.meeting_id,
                "data":data?.data,
                "added_by":localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return SubmitAttandanceRes = res.data
        
    } catch (error) {
        return SubmitAttandanceRes = error.response.data
    }
})


