import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import Loader from '../../../component/loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import AssignPlanAction from '../../../component/data-table-action/table_action/super-admin-action/AssignPlanAction'
import { AssignPlanListRes, AssignPlanListTodo } from '../../../redux/slices/super-admin-slices/asign-plan/AssignPlanList'

export default function AssignPlanList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const assignPlanList = useSelector(state => state?.AssignPlanList?.data?.data)
    console.log(assignPlanList)

    useEffect(() => {
        setLoading(true)
        dispatch(AssignPlanListTodo()).then(() => List_res())
    }, [bool])

    const List_res = () => {
        if (AssignPlanListRes && AssignPlanListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'CUSTOMER NAME',
            field: 'mastercompanies[0].company_name',
            cellRenderer: params => params?.data?.mastercompanies?.[0]?.company_name,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'PLAN NAME',
            field: 'subscriptionmasters[0].plan_name',
            cellRenderer: params => params?.data?.subscriptionmasters[0].plan_name,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'START DATE',
            field: 'startdate',
            cellRenderer: params => params.data.startdate === null ? '-' : new Date(params.data.startdate).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'END DATE',
            field: 'enddate',
            cellRenderer: params => params.data.enddate === null ? '-' : new Date(params.data.enddate).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: AssignPlanAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>

            {loading ?
                <div className='d-flex justify-content-center mt-5'>
                    <Loader />
                </div>
                :
                assignPlanList && assignPlanList.length ?
                < div >
                    < DataTable
                        rowData={assignPlanList}
                        columnDefs={columnDefs}
                        height={'70vh'}
                    />
                </div>
                :
                <div className='empty_section'>
                    <span>DATA NOT FOUND</span>
                </div>
            }


        </>
    )
}
