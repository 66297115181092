import React, { useState } from 'react'
import StaffList from './StaffList'
import StaffForm from '../../../../component/form/super-admin-form/master/staff-form/StaffForm'

export default function AddStaff() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Staff</h3>
                            <StaffForm bool={bool} setBool={setBool} />
                        </div>
                        <div className='layout_head mt-3'>
                            <h3> Staff List</h3>
                            <StaffList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
