import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'

import AdminDesignationtAction from '../../../../component/data-table-action/table_action/admin-action/admin-master-action/AdminDesignationAction'
import { adminDesignationListRes, adminDesignationListTodo } from '../../../../redux/slices/admin-slices/admin-master/admin-designation/AdminDesignationList'

export default function AdminDesignationList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const admindesignationList = useSelector(state => state && state.AdminDesignationList && state.AdminDesignationList.data && state.AdminDesignationList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(adminDesignationListTodo()).then(() => designationList_res())
    }, [bool])

    const designationList_res = () => {
        if (adminDesignationListRes && adminDesignationListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'DESIGNATION',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge   text-bg-success'>ACTIVE</span> : <span className='badge   text-bg-danger'>INACTIVE</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            width: 120

            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: AdminDesignationtAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>

            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                admindesignationList && admindesignationList.length ?
                    <DataTable
                        rowData={admindesignationList}
                        columnDefs={columnDefs}
                        height={'70vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

            <ToastContainer />
        </>
    )
}
