import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var PostDetailsRes;
export const PostDetailsTodo = createAsyncThunk('PostDetails', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'post_details_byid',
            headers: { "Content-Type": "application/json" },
            data: {
                "id":id,
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return PostDetailsRes = res.data

    } catch (error) {
        return PostDetailsRes = error.response.data
    }
});

const PostDetailsSlice = createSlice({
    name: 'PostDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(PostDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(PostDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default PostDetailsSlice.reducer; 