import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddTodoRes;
export const AddTodoTodo = createAsyncThunk('AddTodo', async (data) => {
    // const adminRole = localStorage.getItem('name')
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_task',
            data: {
                "meeting_id": "",
                "title": data.title,
                // "description": data.description,
                "assign_to": data?.task_for === 'other' ? data?.assign_to?.map(item => item.value) : [data?.task_for],
                // "duration":data.duration,
                "approved_required": data?.task_for === 'other' ? data.approved_required :'no',
                "deadline": data.deadline,
                "time": data.time,
                "added_by":localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return AddTodoRes = res.data
        
    } catch (error) {
        return AddTodoRes = error.response.data
    }
})


// export var adminUpdateDepartmentRes;
// export const adminUpdateDepartmentTodo = createAsyncThunk('adminUpdateDepartment', async (data) => {
//     const adminRole = localStorage.getItem('name')
//     try {
//         const res = await axios({
//             method: "post",
//             url: process.env.REACT_APP_API_URL + 'update_department',
//             data: {
//                 "id":data.id,
//                 "name":data.department,
//                 "status":data.status,
//                 "modified_by":localStorage.getItem('id'),
//                 "company_id":adminRole === 'Admin' ? localStorage.getItem('customerId') : null
//             },
//             headers: { "Content-Type": "application/json" }
//         })
//         return adminUpdateDepartmentRes = res.data
        
//     } catch (error) {
//         return adminUpdateDepartmentRes = error.response.data
//     }
// })