import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminAddDesignationRes;
export const adminAddDesignationTodo = createAsyncThunk('adminAddDesignation', async (data) => {
   
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_designation',
            data: {
                "name": data.designation,
                "status": data.status,
                "added_by": localStorage.getItem('id'),
                "company_id":adminRole === 'Admin' ? localStorage.getItem('customerId') : null
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminAddDesignationRes = res.data

    } catch (error) {
        return adminAddDesignationRes = error.response.data
    }
})

export var adminUpdateDesignationRes;
export const adminUpdateDesignationTodo = createAsyncThunk('adminUpdateDesignation', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_designation',
            data: {
                "id":data.id,
                "name": data.designation,
                "status": data.status,
                "modified_by": localStorage.getItem('id'),
                "company_id":adminRole === 'Admin' ? localStorage.getItem('customerId') :null
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminUpdateDesignationRes = res.data

    } catch (error) {
        return adminUpdateDesignationRes = error.response.data
    }
})