import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { adminAddStaffRes, adminAddStaffTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-staff/AddUpdateStaff';
import { adminUserTypeListTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-type/AdminTypeList';
import Loader from '../../../../loader/Loader';
import Text from '../../../../input_filed/Text';
import Email from '../../../../input_filed/Email';
import Password from '../../../../input_filed/Password';
import SingleSelect from '../../../../input_filed/SingleSelect';
import SelectStatus from '../../../../input_filed/SelectStatus';
import SaveButton from '../../../../buttons/SaveButton';
import { StaffSchema } from '../../../super-admin-form/master/staff-form/Validation';
import File from '../../../../input_filed/File';
import { AddUserRes, AddUserTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-user/AdminAddUser';
import { adminDepartmentListTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-department/AdminDepartmentList';
import { adminDesignationListTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-designation/AdminDesignationList';
import { json, useNavigate } from 'react-router-dom';



export default function AdminAddStaffForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const userTypeList = useSelector(state => state?.AdminTypeList?.data?.data)
    const departmentList = useSelector(state => state?.AdminDepartmentList?.data?.data)
    const designationList = useSelector(state => state?.AdminDesignationList?.data?.data)

    const navigate = useNavigate()

    const usertype_opt = userTypeList && userTypeList.map(val => (
        { "value": val._id, "label": val.type }
    ))
    const department_opt = departmentList && departmentList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    const designation_opt = designationList && designationList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    // const type_opt = [
    //     { 'value': 'admin', 'label': 'Admin' },
    //     { "value": 'user', 'label': 'User' }
    // ]


    const initialValues = {
        name: '',
        email: "",
        // bio: "",
        password: "",
        confirm_psw: "",
        profileImg: "",
        designation: '',
        department: '',
        type: '',
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: StaffSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddUserTodo(bodyFormData)).then(() => add_res())
            action.resetForm()
            // console.log(values)
        }
    })

    const add_res = () => {
        if (AddUserRes?.status) {
            toast.success(AddUserRes?.message, { position: 'bottom-right' })
            setLoading(false)
            setTimeout(() => {
                navigate('../admin/user-list')
            }, 1500)
            setBool(!bool)
        } else {
            toast.error(AddUserRes?.message, { position: 'bottom-right' })
            setLoading(false)
        }
    }

    let bodyFormData = new FormData();

    bodyFormData.append("name", values.name)
    bodyFormData.append("email", values.email)
    bodyFormData.append("password", values.password)
    bodyFormData.append("confirmPassword", values.confirm_psw)
    // bodyFormData.append("bio", values.bio)
    bodyFormData.append("user_type", JSON.stringify(values.type) )
    bodyFormData.append("department_id", values.department)
    bodyFormData.append("designation_id", values.designation)
    bodyFormData.append("added_by", localStorage.getItem('id'))
    bodyFormData.append("company_id", localStorage.getItem('customerId'))
    bodyFormData.append("status", values.status)
    bodyFormData.append("path", "category")
    bodyFormData.append("profileImage", values.profileImg)

    useEffect(() => {
        setLoading(true)
        dispatch(adminDepartmentListTodo()).then((res) => {
            if (res?.payload?.status) {
                dispatch(adminDesignationListTodo()).then((designation_res) => {
                    if (designation_res?.payload?.status) {
                        dispatch(adminUserTypeListTodo()).then((type_res) => {
                            if (type_res?.payload?.status) {
                                setLoading(false)
                            } else {
                                setLoading(false)
                            }
                        })
                    } else {
                        setLoading(false)
                    }
                })
            } else {
                setLoading(false)
            }
        })
    }, [])

    return (
        <>

            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-2'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Email
                                    label_name='Email'
                                    placeholder=''
                                    name='email'
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email ? (<span className='text-danger form_label' >{errors.email}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-3'>
                                <File
                                    name='profileImg'
                                    label_name={'Profile Image '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("profileImg", e.currentTarget.files[0])}
                                // error={errors.profileImg && touched.profileImg ? (<span className='text-danger form_label' >{errors.profileImg}</span>) : null}
                                />

                            </div>
                            {/* <div className='col-md-4'>
                                <Text
                                    label_name='Bio'
                                    placeholder=''
                                    name='bio'
                                    value={values.bio || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.bio && touched.bio ? (<span className='text-danger form_label' >{errors.bio}</span>) : null}
                                />
                            </div> */}
                            <div className='col-md-4'>
                                <Password
                                    label_name='Password'
                                    placeholder=''
                                    name='password'
                                    value={values.password || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password ? (<span className='text-danger form_label' >{errors.password}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Password
                                    label_name='Confirm Password'
                                    placeholder=''
                                    name='confirm_psw'
                                    value={values.confirm_psw || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.confirm_psw && touched.confirm_psw ? (<span className='text-danger form_label' >{errors.confirm_psw}</span>) : null}
                                />
                            </div>
                         
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Department'
                                    disabled={false}
                                    option={department_opt}
                                    name='department'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('department', e.value);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.department && touched.department ? (<span className='text-danger form_label' >{errors.department}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Designation'
                                    disabled={false}
                                    option={designation_opt}
                                    name='designation'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('designation', e.value);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.designation && touched.designation ? (<span className='text-danger form_label' >{errors.designation}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Type'
                                    disabled={false}
                                    option={usertype_opt}
                                    name='type'
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFieldValue('type', e);
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.type && touched.type ? (<span className='text-danger form_label' >{errors.type}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>

            <ToastContainer />
        </>
    )
}


