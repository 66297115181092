import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Text from '../../../input_filed/Text'
import File from '../../../input_filed/File'
import Email from '../../../input_filed/Email'
import Password from '../../../input_filed/Password'
import SingleSelect from '../../../input_filed/SingleSelect'
import SaveButton from '../../../buttons/SaveButton'
import SelectStatus from '../../../input_filed/SelectStatus'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'
import { addCustomerRes, addCustomerTodo } from '../../../../redux/slices/super-admin-slices/customer/AddCustomer'
import { CustomerSchema } from './Validation'
import { countryListRes, countryListTodo } from '../../../../redux/slices/country-state-city/CountryList'
import { stateListRes, stateListTodo } from '../../../../redux/slices/country-state-city/StateList'
import { cityListRes, cityListTodo } from '../../../../redux/slices/country-state-city/CityList'


export default function CustomerForm() {

    const [loading, setLoading] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const [stateLoading, setStateLoading] = useState(false)
    const [cityLoading, setCityLoading] = useState(false)

    const stateRef = useRef()
    const cityRef = useRef()

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const countryList = useSelector(state => state && state.CountryList && state.CountryList.data && state.CountryList.data.data)
    const stateList = useSelector(state => state && state.StateList && state.StateList.data && state.StateList.data.data)
    const cityList = useSelector(state => state && state.CityList && state.CityList.data && state.CityList.data.data)

    const country_option = countryList && countryList.map(val => (
        { "value": val.id, "label": val.name }
    ))

    const state_option = stateList && stateList.map(val => (
        { "value": val.id, "label": val.state }
    ))

    const city_option = cityList && cityList.map(val => (
        { "value": val.id, "label": val.city }
    ))

    const initialValues = {
        name: '',
        short_code: "",
        admin_name: "",
        admin_email: "",
        admin_psw: "",
        admin_phn: "",
        customer_logo: '',
        country: "",
        state: "",
        city: "",
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: CustomerSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(addCustomerTodo(bodyFormData)).then(() => addcustomer_res(action))
        }
    })

    let bodyFormData = new FormData();

    bodyFormData.append("company_name", values.name)
    bodyFormData.append("cust_code", values.short_code)
    bodyFormData.append("contact_person", values.admin_name)
    bodyFormData.append("email_id", values.admin_email)
    bodyFormData.append("password", values.admin_psw)
    bodyFormData.append("phone_no", values.admin_phn)
    bodyFormData.append("ccountry", values.country)
    bodyFormData.append("cstate", values.state)
    bodyFormData.append("ccity", values.city)
    bodyFormData.append("status", values.status)
    bodyFormData.append("path", "category")
    bodyFormData.append("cust_logo", values.customer_logo)
    bodyFormData.append("added_by", localStorage.getItem('id'))

    const addcustomer_res = (action) => {
        if (addCustomerRes && addCustomerRes.status) {
            setLoading(false)
            toast.success(addCustomerRes && addCustomerRes.message, { position: "bottom-right" })
            action.resetForm()
            setTimeout(() => {
                navigate('../superadmin/customer-list')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(addCustomerRes && addCustomerRes.message, { position: "bottom-right" });
        }
    }
    const country_res = () => {
        if (countryListRes && countryListRes.status) {
            setFormLoading(false)
        } else {
            setFormLoading(false)
        }
    }

    const load_state = (id) => {
        if (id) {
            setStateLoading(true)
            dispatch(stateListTodo(id)).then(() => state_res())
            stateRef && stateRef.current && stateRef.current.setValue([])
            cityRef && cityRef.current && cityRef.current.setValue([])
        }
    }

    const state_res = (res) => {
        if (stateListRes && stateListRes.status) {
            setStateLoading(false)
        } else {
            setStateLoading(false)
        }
    }

    const load_city = (id) => {
        if (id) {
            setCityLoading(true)
            dispatch(cityListTodo(id)).then(() => city_res())
            cityRef && cityRef.current && cityRef.current.setValue([])
        }
    }

    const city_res = () => {
        if (cityListRes && cityListRes.status) {
            setCityLoading(false)
        } else {
            setCityLoading(false)
        }
    }

    useEffect(() => {
        setFormLoading(true)
        dispatch(countryListTodo()).then(() => country_res())
    }, [])


    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading || formLoading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Customer Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <div style={{ position: "relative" }}>
                                    <Form.Label className='form_label'>Unique Short Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=''
                                        name='short_code'
                                        className='form_input'
                                        value={values.short_code || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    /> <span className='uniq_code'>-EMP-001</span>
                                </div>
                                {errors.short_code && touched.short_code ? (<span className='text-danger form_label' >{errors.short_code}</span>) : null}

                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Admin Name'
                                    placeholder=''
                                    name='admin_name'
                                    value={values.admin_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.admin_name && touched.admin_name ? (<span className='text-danger form_label' >{errors.admin_name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Email
                                    label_name='Admin Email'
                                    placeholder=''
                                    name='admin_email'
                                    value={values.admin_email || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.admin_email && touched.admin_email ? (<span className='text-danger form_label' >{errors.admin_email}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Password
                                    label_name='Admin Password'
                                    placeholder=''
                                    name='admin_psw'
                                    value={values.admin_psw || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.admin_psw && touched.admin_psw ? (<span className='text-danger form_label' >{errors.admin_psw}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Admin Phone'
                                    placeholder=''
                                    name='admin_phn'
                                    value={values.admin_phn || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.admin_phn && touched.admin_phn ? (<span className='text-danger form_label' >{errors.admin_phn}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Country'
                                    disabled={false}
                                    option={country_option ? country_option : []}
                                    name='country'
                                    defaultValue={''}
                                    onChange={(e) => {

                                        load_state(e.value);
                                        setFieldValue('country', e.value);
                                        setFieldValue('state', '');
                                        setFieldValue('city', '');
                                    }}
                                    onBlur={handleBlur}
                                    error={errors.country && touched.country ? (<span className='text-danger form_label' >{errors.country}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    reference={stateRef}
                                    label_name='State'
                                    disabled={false}
                                    option={state_option ? state_option : []}
                                    name='state'
                                    defaultValue={''}
                                    onChange={(e) => {
                                        load_city(e.value);
                                        setFieldValue('state', e.value);
                                        setFieldValue('city', '');
                                    }
                                    }
                                    loading={stateLoading}
                                    onBlur={handleBlur}
                                    error={errors.state && touched.state ? (<span className='text-danger form_label' >{errors.state}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SingleSelect
                                    closeMenu={true}
                                    reference={cityRef}
                                    label_name='City'
                                    disabled={false}
                                    option={city_option ? city_option : []}
                                    name='city'
                                    defaultValue={''}
                                    onChange={(e) => {
                                        setFieldValue('city', e.value);
                                    }
                                    }
                                    loading={cityLoading}
                                    onBlur={handleBlur}
                                    error={errors.city && touched.city ? (<span className='text-danger form_label' >{errors.city}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-3'>
                                <File
                                    name='customer_logo'
                                    label_name={'Customer Logo '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("customer_logo", e.currentTarget.files[0])}
                                    error={errors.customer_logo && touched.customer_logo ? (<span className='text-danger form_label' >{errors.customer_logo}</span>) : null}
                                />

                            </div>
                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
