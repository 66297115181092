import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteActionRes;
export const deleteActionTodo = createAsyncThunk('deleteAction', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'delete_masterAction',
            data: {
                "id": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return deleteActionRes = res.data

    } catch (error) {
        return deleteActionRes = error.response.data
    }
})