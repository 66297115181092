import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminAddDepartmentRes;
export const adminAddDepartmentTodo = createAsyncThunk('adminAddDepartment', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_department',
            data: {
                "name":data.department,
                "status":data.status,
                "added_by":localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminAddDepartmentRes = res.data
        
    } catch (error) {
        return adminAddDepartmentRes = error.response.data
    }
})


export var adminUpdateDepartmentRes;
export const adminUpdateDepartmentTodo = createAsyncThunk('adminUpdateDepartment', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_department',
            data: {
                "id":data.id,
                "name":data.department,
                "status":data.status,
                "modified_by":localStorage.getItem('id'),
                "company_id":adminRole === 'Admin' ? localStorage.getItem('customerId') : null
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminUpdateDepartmentRes = res.data
        
    } catch (error) {
        return adminUpdateDepartmentRes = error.response.data
    }
})