// import React from 'react'

// export default function AdminUserPermission() {
//     return (
//         <>
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-md-12">
//                         <div className='layout_head'>
//                             <h3>User Permission</h3>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import SingleSelect from '../../../component/input_filed/SingleSelect';
import Loader from '../../../component/loader/Loader';
import { userPermissionListTodo } from '../../../redux/slices/super-admin-slices/permission/user-permission/UserPermissionModuleList';
import { customerListTodo } from '../../../redux/slices/super-admin-slices/customer/CustomerList';
import { userTypeListTodo } from '../../../redux/slices/super-admin-slices/user-type/UserTypeList';
import { UserSchema } from '../../../component/form/super-admin-form/permission-form/Validation';
import { addUserPermissionRes, addUserPermissionTodo } from '../../../redux/slices/super-admin-slices/permission/user-permission/AddUserPermission';
import SaveButton from '../../../component/buttons/SaveButton';
import { adminUserTypeListTodo } from '../../../redux/slices/admin-slices/admin-master/admin-type/AdminTypeList';
import { adminUserPermissionListTodo } from '../../../redux/slices/admin-slices/permission/AdminUserPermission';
import { adminAddUserPermissionRes, adminAddUserPermissionTodo } from '../../../redux/slices/admin-slices/permission/AdminAddUserPermission';



export default function AdminUserPermission() {

    const dispatch = useDispatch();
    const customerList = useSelector(state => state && state.CustomerList && state.CustomerList.data && state.CustomerList.data.data);
    const userList = useSelector(state => state && state.AdminTypeList && state.AdminTypeList.data && state.AdminTypeList.data.data)
    const userPermissionModule = useSelector(state => state && state.AdminUserPermissionList && state.AdminUserPermissionList.data && state.AdminUserPermissionList.data.data && state.AdminUserPermissionList.data.data[0])
    const [companmyId, setCompanmyId] = useState()
    const [loading, setLoading] = useState(false)
    const [typeId, setTypeId] = useState()
    const [checkedItems, setCheckedItems] = useState([]);
    const [bool, setBool] = useState(false)
    console.log("userPermissionModule", userPermissionModule)

    const customer_option = customerList?.map(val => ({
        value: val._id,
        label: val.company_name
    }));

    const user_option = userList?.map(val => ({
        value: val._id,
        label: val.type
    })
    )

    const permissionFunction = (id) => {
        setCheckedItems([])
        dispatch(adminUserPermissionListTodo({"typeId": id })).then((res) => {
            if (res && res.payload.status) {
                res && res.payload && res.payload.data && res.payload.data[0] && res.payload.data[0].customermodules.map(val => {
                    val && val.customeractions && val.customeractions.map(userVal => {
                        if (userVal && userVal.userpermisssionaction && userVal.userpermisssionaction.length) {
                            setCheckedItems((prev) => [...prev, {
                                "fk_customer_module_id": val && val._id,
                                "fk_customer_action_id": userVal && userVal._id
                            }])
                        }
                    })
                })
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        // dispatch(customerListTodo());
        dispatch(adminUserTypeListTodo())
    }, [bool]);


    const handleParentCheckboxChange = (parentData) => {
        console.log("parent", parentData)
        var parentId = document.getElementById(parentData._id).checked
        // console.log(parentId)

        if (parentId) {
            for (var i = 0, len = checkedItems.length; i < len; i++) {

                if (checkedItems[i].fk_customer_module_id == parentData._id) {
                    setCheckedItems((current) =>
                        current.filter((val) => val.fk_customer_module_id !== parentData._id)
                    )
                }
            }
            parentData.customeractions.map((val) => {
                // console.log(val)
                setCheckedItems((prev) => [...prev, { "fk_customer_module_id": parentData._id, "fk_customer_action_id": val._id }])
            })
        } else {
            for (var i = 0, len = checkedItems.length; i < len; i++) {

                if (checkedItems[i].fk_customer_module_id == parentData._id) {
                    setCheckedItems((current) =>
                        current.filter((val) => val.fk_customer_module_id !== parentData._id)
                    )
                }
            }
        }
    }

    const handleChildCheckboxChange = (childData) => {
        console.log("childData", childData)
        setCheckedItems((prev) => [...prev, childData])

        for (var i = 0, len = checkedItems.length; i < len; i++) {

            if (checkedItems[i].fk_customer_action_id == childData.fk_customer_action_id) {
                setCheckedItems((current) =>
                    current.filter((val) => val.fk_customer_action_id !== childData.fk_customer_action_id)
                )
            }
        }
    }

    const initialValues = {
        customer_name: companmyId,
        user_typ: typeId,
        user_modules: {
            "company_id": localStorage.getItem('customerId'),
            "user_type_id": typeId,
            "data": checkedItems
        }, // You can directly assign defaultValues here
    };


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        // validationSchema: UserSchema,
        enableReinitialize: true,
        onSubmit: values => {
            console.log(values)
            dispatch(adminAddUserPermissionTodo(values)).then(() => userPermissionFun())
        }
    })
    const userPermissionFun = () => {
        if (adminAddUserPermissionRes && adminAddUserPermissionRes.status) {
            toast.success(adminAddUserPermissionRes && adminAddUserPermissionRes.message, { position: 'bottom-right' })
            setBool(!bool)
        } else {
            toast.error(adminAddUserPermissionRes && adminAddUserPermissionRes.message, { position: 'bottom-right' })
        }
    }
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-2'>
                    <div className='row'>
                        {/* <div className='col-md-4'>
                            <SingleSelect
                                closeMenu={true}
                                label_name='Customer Name'
                                disabled={false}
                                option={customer_option || []}
                                name='customer_name'
                                defaultValue={''}
                                onChange={e => {
                                    setFieldValue('customer_name', e.value);
                                    setCompanmyId(e.value)
                                    // setCheckedItems([])
                                    // permissionFunction()
                                }}
                                onBlur={handleBlur}
                                error={errors.customer_name && touched.customer_name ? (<span className='text-danger form_label' >{errors.customer_name}</span>) : null}
                            />
                        </div> */}
                        <div className='col-md-4'>
                            <SingleSelect
                                closeMenu={true}
                                label_name='User Type'
                                disabled={false}
                                option={user_option || []}
                                name='user_typ'
                                defaultValue={''}
                                onChange={e => {
                                    setFieldValue('user_typ', e.value);
                                    setTypeId(e.value)
                                    // setCheckedItems([])
                                    setLoading(true)
                                    permissionFunction(e.value)
                                }}
                                onBlur={handleBlur}
                                error={errors.user_typ && touched.user_typ ? (<span className='text-danger form_label' >{errors.user_typ}</span>) : null}
                            />
                        </div>
                    </div>
                    <div className='row'>

                        <div>
                            <div className='row mt-2'>
                                {
                                    loading ?
                                        <div className='d-flex justify-content-between'>
                                            <Loader />
                                        </div>
                                        :
                                        userPermissionModule && userPermissionModule.customermodules && userPermissionModule.customermodules.length ?
                                            userPermissionModule && userPermissionModule.customermodules && userPermissionModule.customermodules.map((val, insdex) => (
                                                <div className='col-md-4 mt-2' key={val._id}>
                                                    <div className='card permission_card h-100'>
                                                        <div className='card-header permission_card_head'>
                                                            <Form.Check
                                                                type='checkbox'
                                                                id={val._id}
                                                                label={val.module_display_name}
                                                                className='menu_check'
                                                                checked={checkedItems && checkedItems.some(checkval =>
                                                                    checkval.fk_customer_module_id == val._id
                                                                )}
                                                                onChange={() => handleParentCheckboxChange(val)}
                                                            />
                                                        </div>
                                                        <div className='card-body permission_card_body'>
                                                            {val.customeractions.map((child_val, index) => (
                                                                <Form.Check
                                                                    key={child_val._id}
                                                                    type='checkbox'
                                                                    id={child_val._id}
                                                                    label={child_val.action_display_name}
                                                                    className='sub_menu_check'
                                                                    checked={checkedItems && checkedItems.some(checkval =>
                                                                        checkval.fk_customer_action_id == child_val._id
                                                                    )}
                                                                    onChange={() => handleChildCheckboxChange({ "fk_customer_module_id": val._id, "fk_customer_action_id": child_val._id })}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='d-flex justify-content-center'>
                                                <p className='p-5'> Permission Not Allowed</p>
                                            </div>
                                }
                            </div>
                        </div>
                        <div className='text-end mt-3 mb-3'>
                            <SaveButton name={'SAVE'} />
                        </div>
                    </div>
                </div>
            </Form>
            <ToastContainer />
        </>

    )
}

