import React, { useEffect, useState } from 'react'
import { FaUserLarge } from "react-icons/fa6";
import { MdOutlineGridOn } from "react-icons/md";
import { BsCameraReels } from "react-icons/bs";
// import { RiTeamLine } from "react-icons/ri";
import { BiTask } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../component/loader/Loader';
import { dashboardRes, dashboardTodo } from '../../../redux/slices/super-admin-slices/dashboard/Dashboard';
import { SuperAdmindashboardRes, SuperAdmindashboardTodo } from '../../../redux/slices/super-admin-slices/superadmin-dashboard/SuperAdminDashboard';


export default function SuperAdminDashboard() {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const dashboardData = useSelector(state => state?.SuperAdminDashboard?.data?.data)
  console.log(dashboardData)

  useEffect(() => {
    setLoading(true)
    dispatch(SuperAdmindashboardTodo()).then(() => dashboardFun())
  }, [])

  const dashboardFun = () => {
    if (SuperAdmindashboardRes && SuperAdmindashboardRes.status) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }


  return (
    <>

      <div className="container-fluid">
        <div className='layout_head'>
          <h3>Dashboard</h3>
        </div>
        {loading ?
          <div className='d-flex justify-content-center mt-5 pt-5'>
            <Loader />
          </div> :

          <div className="row">
            <div className="col-md-3 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < FaUserLarge size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>CUSTOMER</h4>
                    <h3 className='text-center'>{dashboardData?.Totalcompany}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < FaUserLarge size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>DEALER</h4>
                    <h3 className='text-center'>{dashboardData?.Totaldealer}</h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < MdOutlineGridOn size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>POST</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.Totelposts}</h3>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < BsCameraReels size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>REELS</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.Totelreels}</h3>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < RiTeamLine size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>MEETING</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.Toteltodo}</h3>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3 mt-3">
              <div className="card dashboard_card text-center  h-100" >
                <div className='d-flex justify-content-around align-items-center '>
                  <div className=''>
                    <span className='dashboard_icon'>
                      < BiTask size={35} style={{ color: "#e42256d0" }} />
                    </span>
                  </div>
                  <div className=''>
                    <h4>TASK</h4>
                    <h3 className='text-center'>{dashboardData && dashboardData.TotelUsers}</h3>
                  </div>
                </div>
              </div>
            </div> */}
          </div>}
      </div>
    </>
  )
}
