import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import Email from '../../../input_filed/Email'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { updateDealerRes, updateDealerTodo } from '../../../../redux/slices/super-admin-slices/dealer/UpdateDealer'
import { UpdateDealerSchema } from './Validation'

export default function UpdateDealerForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { state } = useLocation()
    console.log(state)

    const initialValues = {

        name: state?.username,
        email: state?.email,
        status: state?.status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdateDealerSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateDealerTodo({ values, id: state?._id })).then(() => add_res(action))
        }
    })


    const add_res = (action) => {
        if (updateDealerRes && updateDealerRes.status) {
            setLoading(false)
            toast.success(updateDealerRes && updateDealerRes.message, { position: "bottom-right" })
            action.resetForm()
            setTimeout(() => {
                navigate('../superadmin/dealer-list')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateDealerRes && updateDealerRes.message, { position: "bottom-right" });
        }
    }


    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>

                            <div className='col-md-4'>
                                <Text
                                    label_name='Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Email
                                    label_name='Email'
                                    placeholder=''
                                    name='email'
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email ? (<span className='text-danger form_label' >{errors.email}</span>) : null}
                                />
                            </div>


                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={values.status}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
