import React from 'react'
import UserPermissionForm from '../../../component/form/super-admin-form/permission-form/UserPermissionForm'

export default function UserPermission() {
  return (
    <>
     <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>User Permission</h3>
                        </div>
                        <UserPermissionForm/>
                    </div>
                </div>
            </div>
    </>
  )
}
