import React from 'react'
import UpdateModueForm from '../../../component/form/super-admin-form/module-form/UpdateModueForm'

export default function UpdateModule() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Module</h3>
                        </div>
                        <UpdateModueForm />
                    </div>
                </div>
            </div>
        </>
    )
}
