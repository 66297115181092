import React from 'react'
import AddMeetingForm from '../../../component/form/admin-form/admin-meeting/AddMeetingForm'

export default function AddMeeting() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Add Meeting  </h3>
                            <AddMeetingForm/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
