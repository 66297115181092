import moment from "moment";
import * as Yup from "yup";

export const AddMinutesSchema = Yup.object().shape({
    title: Yup.string().required("Topic"),
    data: Yup.array().of(
        Yup.object().shape({
            subtopic_desc: Yup.string().required("Enter Sub Topic"),
            user_id: Yup.array().required(" Select at least One User").min(1, " Select at least One User"),
            deadline: Yup.string().required("Select Date"),
            time: Yup.string().required('Start time is required').test(
                'is-valid-time',
                ' Time cannot be earlier than the current time',
                function (value) {
                    const { deadline } = this.parent;
                    const selectedDate = moment(deadline, 'YYYY-MM-DD').startOf('day');
                    const today = moment().startOf('day');
                    const currentTime = moment();
        
                    // If the selected date is today, check if start time is valid
                    if (selectedDate.isSame(today, 'day')) {
                        const selectedTime = moment(value, 'hh:mm A');
                        return selectedTime.isSameOrAfter(currentTime, 'minute');
                    }
                    return true; // No validation needed if the selected date is not today
                }
            ) ,
            approved_required: Yup.string().required("Select Approval Required"),

        })
    ),

})

export const UpdateMinutesSchema = Yup.object().shape({
    title: Yup.string().required("Topic"),
    data: Yup.array().of(
        Yup.object().shape({
            subtopic_desc: Yup.string().required("Enter Sub Topic"),
            user_id: Yup.array().required(" Select at least One User").min(1, " Select at least One User"),
            deadline: Yup.string().required("Select Date"),
            time: Yup.string().required('Start time is required').test(
                'is-valid-time',
                ' Time cannot be earlier than the current time',
                function (value) {
                    const { deadline } = this.parent;
                    const selectedDate = moment(deadline, 'YYYY-MM-DD').startOf('day');
                    const today = moment().startOf('day');
                    const currentTime = moment();
        
                    // If the selected date is today, check if start time is valid
                    if (selectedDate.isSame(today, 'day')) {
                        const selectedTime = moment(value, 'hh:mm A');
                        return selectedTime.isSameOrAfter(currentTime, 'minute');
                    }
                    return true; // No validation needed if the selected date is not today
                }
            ) ,
            approved_required: Yup.string().required("Select Approval Required"),

        })
    ),

})

export const ExcelUploadSchema = Yup.object().shape({

    data: Yup.array().of(
        Yup.object().shape({
            topic: Yup.string().required("Topic"),
            subtopic: Yup.array().of(
                Yup.object().shape({
                    subtopic_desc: Yup.string().required("Enter Sub Topic"),
                    user_id: Yup.array().required(" Select at least One User").min(1, " Select at least One User"),
                    deadline: Yup.string().required("Select Date"),
                    time: Yup.string().required("Select Time"),
                    approved_required: Yup.string().required("Select Approval Required"),
                })
            )

        })
    ),

})