import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ChatListRes;
export const ChatListTodo = createAsyncThunk('ChatList', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'chat_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "type": localStorage.getItem('type') === 'Admin' ? '1' : '',
                "sender_id": localStorage.getItem('type') === 'Admin' ? '' : id,
                "user_id": localStorage.getItem('type') === 'Admin' ? id : localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return ChatListRes = res.data

    } catch (error) {
        return ChatListRes = error.response.data
    }
});

const ChatListSlice = createSlice({
    name: 'ChatList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ChatListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(ChatListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ChatListSlice.reducer; 