import moment from "moment";
import * as Yup from "yup";


export const TodoSchema = Yup.object({

    title: Yup.string().required("Enter Title "),
    // description: Yup.string().required("Enter Description "),
    // duration: Yup.string().required("Enter Duration in Hours"),
    deadline: Yup.string().required("Select Deadline"),
    time: Yup.string().required('Start time is required').test(
        'is-valid-time',
        ' Time cannot be earlier than the current time',
        function (value) {
            const { deadline } = this.parent;
            const selectedDate = moment(deadline, 'YYYY-MM-DD').startOf('day');
            const today = moment().startOf('day');
            const currentTime = moment();

            // If the selected date is today, check if start time is valid
            if (selectedDate.isSame(today, 'day')) {
                const selectedTime = moment(value, 'hh:mm A');
                return selectedTime.isSameOrAfter(currentTime, 'minute');
            }
            return true; // No validation needed if the selected date is not today
        }
    ) ,
    // time: Yup.string().required("Select time"),
    task_for: Yup.string().required("Select Task For"),
    assign_to: Yup.string().when('task_for', {
        is: 'other',
        then: () => Yup.array().min(1, " Select at least One User").required(" Select at least One user"),
        otherwise: () => Yup.array().notRequired(),
    }),
    approved_required: Yup.string().when('task_for', {
        is: 'other',
        then: () => Yup.string().required("Select Approval"),
        otherwise: () => Yup.string().notRequired(),
    }),
    // assign_to: Yup.array().required(" Select at least One user").min(1, " Select at least One User"),
    // approved_required: Yup.string().required("Select Approval"),
});