import React, { useState } from 'react'
import SaveButton from '../../../buttons/SaveButton';
import Text from '../../../input_filed/Text';
import Loader from '../../../loader/Loader';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePlanRes, updatePlanTodo } from '../../../../redux/slices/super-admin-slices/plan/UpdatePlan';
import { ToastContainer, toast } from 'react-toastify';
import { PlanSchema } from './Validation';

export default function UpdatePlanForm() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { state } = useLocation()
    const navigate = useNavigate()

    const initialValues = {
        id: state?._id,
        name: state?.plan_name,
        validity: state?.validity,
        amount: state?.amount
    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: PlanSchema,
        enableReinitialize: 'true',

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updatePlanTodo(values)).then(() => update_res(action))
        }
    })

    const update_res = (action) => {
        if (updatePlanRes?.status) {
            setLoading(false)
            toast.success(updatePlanRes?.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../superadmin/plan')
            }, 1500);
            action.resetForm()

        } else {
            setLoading(false)
            toast.error(updatePlanRes?.message, { position: "bottom-right" });
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Plan Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Plan Validity'
                                    placeholder=''
                                    name='validity'
                                    value={values.validity || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.validity && touched.validity ? (<span className='text-danger form_label' >{errors.validity}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Amount'
                                    placeholder=''
                                    name='amount'
                                    value={values.amount || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.amount && touched.amount ? (<span className='text-danger form_label' >{errors.amount}</span>) : null}
                                />
                            </div>


                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
                <ToastContainer/>
            </Form>
        </>
    )
}
