import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminAddTypeRes;
export const adminAddTypeTodo = createAsyncThunk('adminAddType', async (data) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_usertype',
            data: {
                "type":data.user_type,
                "status":data.status,
                "added_by":localStorage.getItem('id'),
                "added_type": adminRole === 'Admin' ? 'unlocked' : 'locked',
                "company_id":  localStorage.getItem('customerId')
            },
            headers: { "Content-Type":  "application/json"}
        })
        return adminAddTypeRes = res.data
        
    } catch (error) {
        return adminAddTypeRes = error.response.data
    }
})

export var adminUpdateTypeRes;
export const adminUpdateTypeTodo = createAsyncThunk('adminUpdateType', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_usertype',
            data: {
                'id': data && data.id,
                "type": data && data.values && data.values.user_type,
                "status": data && data.values && data.values.status,
                "added_by": localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminUpdateTypeRes = res.data

    } catch (error) {
        return adminUpdateTypeRes = error.response.data
    }
})