import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addPlanRes;
export const addPlanTodo = createAsyncThunk('addPlan', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_plan',
            data: {
                "name":data.name,
                "days":data.validity,
                "amount": data.amount,
                "added_by":localStorage.getItem('id'),
            },
            headers: { "Content-Type": "application/json" }
        })
        return addPlanRes = res.data
        
    } catch (error) {
        return addPlanRes = error.response.data
    }
})


