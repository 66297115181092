import React, { useEffect, useState } from 'react'
import SaveButton from '../../../buttons/SaveButton';
import { ToastContainer, toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { versionDetailsRes, versionDetailsTodo } from '../../../../redux/slices/admin-slices/admin-app-version/VersionDetails';
import { updateVersionTodo } from '../../../../redux/slices/admin-slices/admin-app-version/UpdateVersion';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Loader from '../../../loader/Loader';
import Text from '../../../input_filed/Text';
import { UpdateVersionSchema } from './Validation';

export default function UpdateVersionForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const version = useSelector(state => state?.VersionDetails?.data?.data)
    console.log(version)

    let initialValues = {
        type: "",
        andriod_id:version?.[1]?._id,
        ios_id:version?.[0]?._id,
        andriod_version:version?.[1]?.version,
        ios_version:version?.[0]?.version
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema : UpdateVersionSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateVersionTodo(values)).then((res) => updateVersion_res(res))
        },
    })
    const updateVersion_res = (res) => {

        if ( res?.payload?.data?.status) {
            toast.success( res?.payload?.data?.message, { position: 'bottom-right' })
            setLoading(false)

        } else {
            toast.error( res?.payload?.data?.message, { position: 'bottom-right' })
            setLoading(false)

        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(versionDetailsTodo()).then(() => versionList_Res())
    }, [])

    const versionList_Res = () => {
        if (versionDetailsRes && versionDetailsRes.success) {
            setLoading(false)
            initialValues.andriod_version = versionDetailsRes && versionDetailsRes.data && versionDetailsRes.data[0] && versionDetailsRes.data[0].version
            initialValues.ios_version = versionDetailsRes && versionDetailsRes.data && versionDetailsRes.data[1] && versionDetailsRes.data[1].version
        } else {
            setLoading(false)
        }
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className='form_layout mt-2'>
                                {loading ?
                                    <div className='d-flex justify-content-center '>
                                        <Loader />
                                    </div> :

                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Text
                                                name='andriod_version'
                                                label_name={version?.[1]?.type}
                                                value={values.andriod_version}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.andriod_version && touched.andriod_version ? (<span className='text-danger form_label' >{errors.andriod_version}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <Text
                                                name='ios_version'
                                                label_name={version?.[0]?.type}
                                                value={values.ios_version}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ios_version && touched.ios_version ? (<span className='text-danger form_label' >{errors.ios_version}</span>) : null}
                                            />
                                        </div>

                                        <div className='col-md-12 text-end mt-2'>
                                            <SaveButton name={'Save'} />
                                        </div>
                                    </div>}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    )
}
