import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { actionListRes, actionListTodo } from '../../../redux/slices/super-admin-slices/action-slice/ActionList'
import ActionTableAction from '../../../component/data-table-action/table_action/super-admin-action/ActionTableAction'
import Loader from '../../../component/loader/Loader'
import DataTable from '../../../component/data-table/DataTable'


export default function ActionList() {
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch()
    const actionList = useSelector(state => state && state.ActionList && state.ActionList.data && state.ActionList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(actionListTodo()).then(() => moduleListFun())
    }, [bool])

    const moduleListFun = () => {
        if (actionListRes && actionListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'MODULE',
            field: 'fk_master_module_id.module_display_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'ACTION NAME',
            field: 'action_display_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DESCRIPTION',
            field: 'description',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'PRIORITY',
            field: 'action_priority',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
     
        {
            headerName: 'STATUS',
            field: 'action_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.action_status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: ActionTableAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Action List</h3>
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            actionList && actionList.length ?
                                <DataTable
                                    rowData={actionList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                /> :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
