import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminDeleteDesignationRes;
export const adminDeleteDesignationTodo = createAsyncThunk('adminDeleteDesignation', async (id) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'delete_designation',
            data: {
                "id":id,
                "company_id":adminRole === 'Admin' ? localStorage.getItem('customerId') : null
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminDeleteDesignationRes = res.data

    } catch (error) {
        return adminDeleteDesignationRes = error.response.data
    }
})