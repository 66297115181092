import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Loader from '../../../component/loader/Loader';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { MeetingDetailsRes, MeetingDetailsTodo } from '../../../redux/slices/admin-slices/meeting/MeetingDetails';
import moment from 'moment';
import { FaFileDownload } from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import Swal from 'sweetalert2'
import { MdOutlineAdd } from 'react-icons/md';
import * as Yup from "yup";
import { Tooltip } from 'react-tooltip'
import AddAttendee from './AddAttendee';
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList';
import { ToastContainer, toast } from 'react-toastify';
import { FaDownload } from "react-icons/fa6";
import { Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import SaveButton from '../../../component/buttons/SaveButton';
import { SubmitAttandanceRes, SubmitAttandanceTodo } from '../../../redux/slices/admin-slices/meeting/SubmitAttendance';
import AddMinutes from './AddMinutes';
import AddFile from './AddFile';
import { LuBadgeInfo } from 'react-icons/lu';
import MOMView from './MOMView';
import { GiCancel } from 'react-icons/gi';
import { DeleteMOMRes, DeleteMOMTodo } from '../../../redux/slices/admin-slices/meeting/DeleteMom';
import { FaRegFileExcel } from 'react-icons/fa6';
import xlsx from "json-as-xlsx"
import { BiShow } from 'react-icons/bi';
import ExportExcel from './ExportExcel';
import * as XLSX from 'xlsx';
import { FaFileUpload } from "react-icons/fa";
import { format } from 'date-fns';
import UploadExcel from './UploadExcel';
// import {excelFormate} from '../../../assest/images/'



export default function MeetingDetails() {

    const { state } = useLocation()
    var todoId = state && state._id
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const detailsData = useSelector(state => state && state.MeetingDetails && state.MeetingDetails.data && state.MeetingDetails.data.data && state.MeetingDetails.data.data[0])
    const currentDate = detailsData?.currentDate
    const [show, setShow] = useState(false)
    const [minute, setMinute] = useState(false)
    const [view, setView] = useState(false)
    const [excel, setExcel] = useState(false)
    const [file, setFile] = useState(false)
    const [bool, setBool] = useState(false)
    const [permissionCheck, setPermissionCheck] = useState([])
    const [data, setData] = useState('')
    const [momData, setMomData] = useState('')
    const [momLoading, setMomLoading] = useState(true)
    const [uploadModel, setUploadModel] = useState(false)
    const [excelData, setExcelData] = useState([])
    const [key, setKey] = useState('Attendees');

    // console.log(detailsData)
    // const handleExcel = () => {
    //     setExcel(true)
    // }

    const handleAttendee = (data) => {
        setShow(true)
        setData(detailsData)
        dispatch(UserListTodo({ 'type': '' }))
    }

    const handleMinute = (data) => {
        setMomLoading(true)
        dispatch(UserListTodo({ 'type': '' })).then(() => {
            if (UserListRes && UserListRes.status) {
                setData(detailsData)
                setMomLoading(false)
                setMinute(true)
            }
        })
    }

    const handleView = (data) => {
        setMomLoading(true)
        dispatch(UserListTodo({ 'type': '' })).then(() => {
            if (UserListRes && UserListRes.status) {
                setData(detailsData)
                setMomData(data)
                setMomLoading(false)
                setView(true)
            }
        })
    }

    const handleFile = (data) => {
        setFile(true)
        setData(detailsData)

    }

    useEffect(() => {
        setLoading(true)
        dispatch(MeetingDetailsTodo(todoId)).then(() => details_res())

    }, [bool])

    const details_res = () => {
        if (MeetingDetailsRes && MeetingDetailsRes.status) {
            setPermissionCheck('')
            setPermissionCheck(MeetingDetailsRes?.data?.[0]?.attendends?.filter(val => val.attendence_status === 'present'))
            setLoading(false)

        } else {
            setLoading(false)
        }
    }

    const mom_delete = (id) => {
        Swal.fire({
            title: "MOM Delete",
            text: "Are You Sure Want To Delete This MOM ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteMOMTodo({ "id": id, "meetingId": detailsData?._id })).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (DeleteMOMRes && DeleteMOMRes.status) {
            setBool(!bool)
            Swal.fire(
                "MOM",
                'MOM Delete Successfully',
                'success'
            )
        } else {
            toast.error(DeleteMOMRes && DeleteMOMRes.message, { position: "bottom-right" })
        }
    }



    const initialValues = {
        attendance: [],
    }

    const { values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            const selectedItems = permissionCheck;
            const approveData = selectedItems.map((val, index) => ({
                user_id: val.user_id
            }));
            dispatch(SubmitAttandanceTodo({ 'data': approveData, 'meeting_id': detailsData?._id })).then(() => sub_res())
            action.resetForm()
            // console.log(values)

        }
    })

    const sub_res = () => {
        if (SubmitAttandanceRes?.status) {
            toast.success(SubmitAttandanceRes?.message, { position: 'bottom-right' })
            setBool(!bool)
            setPermissionCheck('')
        } else {
            toast.error(SubmitAttandanceRes?.message, { position: 'bottom-right' })
        }
    }

    const handleCheckboxChange = (data) => {

        const permissionExists = permissionCheck.some((val) => val._id === data._id);

        if (permissionExists) {
            setPermissionCheck((current) =>
                current.filter((val) => val._id !== data._id)
            )
        } else {
            setPermissionCheck((current) => [...current, data]);
        }

    }

    // const momDataExcel = []
    // console.log(momDataExcel)

    // const DataRender = detailsData?.meetingminutes.forEach((value) => {
    //     value.subtopics.forEach((subtopic) => {
    //         momDataExcel.push({
    //             topic: value.topic,
    //             subtopic_desc: subtopic.subtopic_desc,
    //             user_id: subtopic?.user_details?.name,
    //             deadline: subtopic.deadline,
    //             status: subtopic?.todos?.status
    //         });
    //     });
    // });

    // const handleExcel = () => {
    //     let exceldata = [
    //         {
    //             columns: [
    //                 { label: "Topic", value: "topic" },
    //                 { label: "Subtopic ", value: "subtopic_desc" },
    //                 { label: "Status", value: "status" },
    //                 { label: "User Name", value: "user_id" },
    //                 { label: "Deadline", value: "deadline" }
    //             ],
    //             content: momDataExcel
    //         },

    //     ]
    //     let settings = {
    //         fileName: "ExcelSheet1",
    //     }
    //     xlsx(exceldata, settings)
    // }

    const momDataExcel = [];

    // console.log(momDataExcel);

    const DataRender = detailsData?.meetingminutes.forEach((value, index) => {
        value.subtopics.forEach((subtopic) => {
            momDataExcel.push({
                sn: index + 1,
                topic: value.topic,
                subtopic_desc: subtopic.subtopic_desc,
                user_id: subtopic?.user_details?.name,
                deadline: subtopic?.todos?.deadline,
                time: subtopic.todos?.time,
                status: subtopic?.todos?.status
            });
        });
    });

    //     const XLSX = require('xlsx');
    //     const workbook = XLSX.utils.book_new();
    //     const worksheetData = [];

    //     // Prepare worksheet data with merged cells
    //     let previousTopic = "";
    //     momDataExcel.forEach((row, index) => {
    //         if (row.topic !== previousTopic) {
    //             previousTopic = row.topic;
    //         } else {
    //             row.topic = ""; // Clear topic for merging
    //             row.sn = ''; // Clear SN for merging
    //         }
    //         worksheetData.push([row.sn, row.topic, row.subtopic_desc, row.user_id, row.deadline, row.status]);
    //     });

    //     // Add headings
    //     const headings = [
    //         [`Meeting Name: ${detailsData ? detailsData.title : ''}`],
    //         [`Meeting Description: ${detailsData ? detailsData.desc : ''}`],
    //         [`Date: ${detailsData ? new Date(detailsData.date).toLocaleDateString('en-GB') : ''}`],
    //         [],
    //         ["SN", "Topic Discussed", "Action Plan", "Responsibility", "DOC", "Status"]
    //     ];

    //     // Combine headings with worksheet data
    //     const worksheet = XLSX.utils.aoa_to_sheet([
    //         ...headings,
    //         ...worksheetData
    //     ]);

    //     // Merge cells for headings
    //     worksheet['!merges'] = [
    //         { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }, // Merge "Meeting Name"
    //         { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } }, // Merge "Branch"
    //         { s: { r: 2, c: 0 }, e: { r: 2, c: 5 } }  // Merge "Date"
    //     ];

    //     // Merge cells for SN and Topic Discussed columns
    //     let mergeRanges = [];
    //     let mergeStart = headings.length; // Start after heading rows
    //     let currentSN = "";
    //     let currentTopic = "";

    //     worksheetData.forEach((row, index) => {
    //         const rowIndex = index + mergeStart;

    //         // Handle SN column merging
    //         if (row[0] === currentSN && currentSN !== "") {
    //             mergeRanges.push({ s: { r: mergeStart, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
    //         } else {
    //             currentSN = row[0];
    //             mergeStart = rowIndex;
    //         }

    //         // Handle Topic Discussed column merging
    //         if (row[1] === currentTopic && currentTopic !== "") {
    //             mergeRanges.push({ s: { r: mergeStart, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
    //         } else {
    //             currentTopic = row[1];
    //             mergeStart = rowIndex;
    //         }
    //     });

    //     // Final merge for the last set of rows
    //     if (currentSN !== "" || currentTopic !== "") {
    //         if (worksheetData.length + headings.length > mergeStart) {
    //             mergeRanges.push({ s: { r: mergeStart, c: 0 }, e: { r: worksheetData.length + headings.length, c: 0 } });
    //             mergeRanges.push({ s: { r: mergeStart, c: 1 }, e: { r: worksheetData.length + headings.length, c: 1 } });
    //         }
    //     }

    //     worksheet['!merges'] = [...worksheet['!merges'], ...mergeRanges];

    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //     XLSX.writeFile(workbook, 'ExcelSheet1.xlsx');
    // };
    // -----------------------------------------EXCEL DOWNLOAD CODE---------------------------------------------


    const handleExcel = () => {
        const XLSX = require('xlsx');
        const workbook = XLSX.utils.book_new();
        const worksheetData = [];

        // Prepare worksheet data with merged cells
        let previousTopic = "";
        let previousSN = "";
        momDataExcel.forEach((row, index) => {
            if (row.topic !== previousTopic) {
                previousTopic = row.topic;
            } else {
                row.topic = ""; // Clear topic for merging
                row.sn = ''; // Clear SN for merging
            }
            worksheetData.push([row.sn, row.topic, row.subtopic_desc, row.user_id, row.deadline, row.time, row.status]);
        });

        // Add headings
        const headings = [
            ['Company Name : Deccan Sales & Service Pvt. Ltd.'],
            [`Meeting Name: ${detailsData ? detailsData.title : ''}`],
            [`Meeting Description: ${detailsData ? detailsData.desc : ''}`],
            [`Date: ${detailsData ? new Date(detailsData.date).toLocaleDateString('en-GB') : ''}`],
            [],
            ["Sn", "Topic Discussed", "Action Plan", "Responsibility", "DOC", "Time", "Status"]
        ];

        // Combine headings with worksheet data
        const worksheet = XLSX.utils.aoa_to_sheet([
            ...headings,
            ...worksheetData
        ]);

        // Merge cells for headings
        worksheet['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }, // Merge "Company Name"
            { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } }, // Merge "Meeting Name"
            { s: { r: 2, c: 0 }, e: { r: 2, c: 5 } },  // Merge "Meeting Description"
            { s: { r: 3, c: 0 }, e: { r: 3, c: 5 } },  // Merge "Date"
            { s: { r: 4, c: 0 }, e: { r: 4, c: 5 } }  // Merge "time"
        ];

        // Merge cells for SN and Topic Discussed columns
        let mergeRanges = [];
        let mergeStartSN = headings.length; // Start after heading rows for SN column
        let mergeStartTopic = headings.length; // Start after heading rows for Topic column
        let currentSN = "";
        let currentTopic = "";

        worksheetData.forEach((row, index) => {
            const rowIndex = index + headings.length;

            // Handle SN column merging
            if (row[0] !== "" && row[0] === currentSN) {
                // Extend the merge range
                mergeRanges.push({ s: { r: mergeStartSN, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
            } else {
                if (currentSN !== "") {
                    // Complete the merge range for previous SN
                    mergeRanges.push({ s: { r: mergeStartSN, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
                }
                currentSN = row[0];
                mergeStartSN = rowIndex;
            }

            // Handle Topic Discussed column merging
            if (row[1] !== "" && row[1] === currentTopic) {
                // Extend the merge range
                mergeRanges.push({ s: { r: mergeStartTopic, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
            } else {
                if (currentTopic !== "") {
                    // Complete the merge range for previous Topic
                    mergeRanges.push({ s: { r: mergeStartTopic, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
                }
                currentTopic = row[1];
                mergeStartTopic = rowIndex;
            }
        });

        // Final merge for the last set of rows
        if (currentSN !== "") {
            mergeRanges.push({ s: { r: mergeStartSN, c: 0 }, e: { r: worksheetData.length + headings.length - 1, c: 0 } });
        }
        if (currentTopic !== "") {
            mergeRanges.push({ s: { r: mergeStartTopic, c: 1 }, e: { r: worksheetData.length + headings.length - 1, c: 1 } });
        }

        worksheet['!merges'] = [...worksheet['!merges'], ...mergeRanges];

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'ExcelSheet1.xlsx');
    };

    // ---------------------------------------------------------------------------------------------------------------


    // --------------------------------------EXCEL UPLOAD CODE--------------------------------------------------------------


    const [jsonData, setJsonData] = useState(null);
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                // Format the date and time fields
                const formattedJson = json.map(row => {
                    const formattedRow = { ...row };
                    for (let key in formattedRow) {
                        if (formattedRow.hasOwnProperty(key)) {
                            if (isExcelDate(formattedRow[key])) {
                                formattedRow[key] = formatExcelDate(formattedRow[key]);
                            } else if (isExcelTime(formattedRow[key])) {
                                formattedRow[key] = formatExcelTime(formattedRow[key]);
                            }
                        }
                    }
                    return formattedRow;
                });

                const transformedData = transformData(formattedJson);
                setJsonData(formattedJson);
                setExcelData(transformedData);
                setData(detailsData)
                setUploadModel(true);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    // Check if a value is an Excel date (numeric value)
    const isExcelDate = (value) => {
        return typeof value === 'number' && value > 25569 && value < 60000; // Rough range for Excel serial dates
    };

    // Check if a value is an Excel time (numeric value but smaller range)
    const isExcelTime = (value) => {
        return typeof value === 'number' && value < 1; // Excel time is typically a fraction of a day
    };

    // Convert Excel date to JavaScript date and format it
    const formatExcelDate = (excelDate) => {
        const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
        return format(jsDate, 'yyyy-MM-dd');
    };

    // Convert Excel time to a human-readable time (hh:mm:ss)
    const formatExcelTime = (excelTime) => {
        const totalSeconds = Math.round(excelTime * 86400); // Convert to total seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // Function to transform data into the desired structure
    const transformData = (jsonData) => {
        const transformedData = [];
        let currentMainTopic = null;

        jsonData?.forEach(item => {
            if (item.Sn) {
                // If SN exists, it's a main topic
                if (currentMainTopic) {
                    // If there is a current main topic, push it to the transformed data
                    transformedData.push(currentMainTopic);
                }
                // Create a new main topic
                currentMainTopic = {
                    Sn: item.Sn,
                    topic_discussed: item["Topic Discussed"],
                    subtopic: [
                        {
                            "action_plan": item["Action Plan"],
                            "responsibility": item["Responsibility"],
                            "doc": item["DOC"],
                            "time": item["Time"],
                            "status": item["Status"]
                        }
                    ]
                };
            } else if (currentMainTopic) {
                // If SN doesn't exist, it's a subtopic of the current main topic
                currentMainTopic.subtopic.push({
                    "action_plan": item["Action Plan"],
                    "responsibility": item["Responsibility"],
                    "doc": item["DOC"],
                    "time": item["Time"],
                    "status": item["Status"]
                });
            }
        });

        // Push the last main topic
        if (currentMainTopic) {
            transformedData.push(currentMainTopic);
        }

        return transformedData;
    };


    //   --------------------------------------------------------------------------------------------------------------


    return (
        <>
            <div className="container-fluid">
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">

                        <div className="col-md-3">
                            <div className='layout_head'>
                                <h3> Meeting Details </h3>
                            </div>
                            <div className='details_sidebar p-0'>
                                <PerfectScrollbar >
                                    <div className='p-3'>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Owner </p>
                                            <span>{detailsData && detailsData.added_user?.name}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center  mt-2'>
                                            <p>Meeting Date </p>
                                            <span>{new Date(detailsData && detailsData.date).toLocaleDateString('en-GB')}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center  mt-2'>
                                            <p>Meeting Time </p>
                                            <span>{detailsData && detailsData.time}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center  mt-2'>
                                            <p>Status </p>
                                            {/* <span>{detailsData && detailsData.status}</span> */}
                                            {new Date(currentDate).toLocaleDateString('en-GB') > new Date(detailsData?.date).toLocaleDateString('en-GB') ?
                                                <h6 className='badge pt-2  ' style={{ backgroundColor: 'orangered' }}>OVERDUE</h6> :
                                                detailsData?.status === 'completed' ? <h6 className='badge pt-2  text-bg-success'>COMPLETED</h6> :
                                                    detailsData?.status === 'incompleted' ? <h6 className='badge pt-2  text-bg-danger'>INCOMPLETED</h6> :
                                                        detailsData?.status === 'pending' ? <h6 className='badge pt-2  text-bg-danger'>PENDING</h6> :
                                                            detailsData?.status === 'cancelled' ? <h6 className='badge pt-2' style={{ backgroundColor: '#f16c4d' }}>CANCELLED</h6> :
                                                                ""}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center  mt-2'>
                                            <p>Meeting Type </p>
                                            <span>{detailsData && detailsData.meeting_type}</span>
                                        </div>
                                        <div className='mt-2'>
                                            {detailsData?.meeting_type === 'online' ? <p>Meeting Link</p> : <p>Meeting Location </p>}
                                            {detailsData?.meeting_type === 'online' ? <span><a href={detailsData?.meeting_location} target='/'>{detailsData?.meeting_location}</a></span>
                                                : <span>{detailsData && detailsData.meeting_location}</span>}
                                        </div>
                                        <div className='mt-2'>
                                            <p>Title </p>
                                            <span>{detailsData && detailsData.title}</span>
                                        </div>
                                        <div className='mt-2'>
                                            <p>Description </p>
                                            <span>{detailsData && detailsData.desc}</span>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="col-md-9">

                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3 piils_btn"
                                fill
                            >
                                <Tab eventKey="Attendees" title={<span> Attendees</span>}>
                                    <div className="details_section">
                                        <PerfectScrollbar >
                                            <div className='p-3'>
                                                {detailsData?.status === 'cancelled' || detailsData?.status === 'completed' ? "" : detailsData?.added_by === localStorage.getItem('id') ? <div className=' text-end me-3 mb-2'>
                                                    <span className='add_attend' data-tooltip-id='add'
                                                        data-tooltip-content="Add Attendee !"
                                                        data-tooltip-place="bottom"
                                                        onClick={() => handleAttendee()}
                                                    >
                                                        <MdOutlineAdd className='mb-1' size={35} style={{ color: 'white' }} />
                                                    </span>
                                                </div> : ''}
                                                <Tooltip id='add' className=' tooltip_msg' />
                                                <div className='ps-3 pe-3'>
                                                    <Form onSubmit={handleSubmit}>
                                                        <Table striped bordered hover className='boot_table'>
                                                            <thead className='table_header'>
                                                                <tr >
                                                                    <th style={{ width: "50px" }}>CHECK</th>
                                                                    <th>USER</th>
                                                                    <th>EMAIL</th>
                                                                    <th>ATTENDENCE STATUS</th>
                                                                    <th> DATE</th>
                                                                </tr>
                                                            </thead>
                                                            {detailsData && detailsData && detailsData.attendends?.length ?
                                                                <tbody>
                                                                    {detailsData && detailsData && detailsData.attendends.map((value, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><input className="form-check-input"
                                                                                    checked={Array.isArray(permissionCheck) && permissionCheck.some((val) => val.user_id === value.user_id)}
                                                                                    onChange={() => handleCheckboxChange(value)}
                                                                                    type="checkbox" /></td>
                                                                                <td style={{ textTransform: 'capitalize' }}>{value && value.user && value.user[0] && value.user[0].name}</td>
                                                                                <td>{value && value.user && value.user[0] && value.user[0].email}</td>
                                                                                <td style={{ textTransform: 'uppercase' }} className={
                                                                                    value.attendence_status === 'present' ? 'badge pt-2  text-bg-success ms-3 mt-1' :
                                                                                        value.attendence_status === 'absent' ? "badge pt-2  text-bg-danger ms-3 mt-2" :
                                                                                            "badge pt-2  text-bg-warning ms-3 mt-1"} >{value.attendence_status}</td>
                                                                                <td>{new Date(value.added_date).toLocaleDateString('en-GB')}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody> :
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={5}>Attendees List Empty</td>
                                                                    </tr>
                                                                </tbody>
                                                            }
                                                        </Table>
                                                        {errors.attendance && touched.attendance ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.attendance}</span>) : null}
                                                        {detailsData?.status === 'cancelled' || detailsData?.status === 'completed' ? "" : detailsData?.attendends?.[0]?.attendence_status == 'pending' ? detailsData?.added_by === localStorage.getItem('id') ? <div className='d-flex justify-content-end mt-3'>
                                                            <SaveButton disabled={permissionCheck?.length > 0 ? false : true} name={'Submit'} />
                                                        </div> : '' : ""}
                                                    </Form>
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </Tab>

                                {/* ---------------------------------------------MOM SECTION-------------------------------------------------------------------- */}

                                <Tab eventKey="MOM" title={<span> MOM</span>}>
                                    <div className="details_section">
                                        <div className='ps-3 pe-3'>
                                            <div className='d-flex justify-content-end align-items-center'>

                                                {localStorage.getItem('type') === 'Admin' ? <a href='../Excel.xlsx' style={{ color: "white" }} download className='excel_dwl3 me-2 ms-2' data-tooltip-id='download2'   >
                                                    Excel Upload Template
                                                </a> : ""}
                                                {localStorage.getItem('type') === 'Admin' ?  <div>
                                                    <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} id="file" className="file " style={{ display: 'none' }}></input>
                                                    <label class=" excel_dwl2 " data-tooltip-id='upload' data-tooltip-content="Upload Excel !" data-tooltip-place="bottom" type="button" for="file"
                                                    ><FaFileUpload className='' size={22} style={{ color: 'white' }} />
                                                    </label>
                                                </div>: ""}


                                                {detailsData?.meetingminutes?.length ?
                                                    <span className='excel_dwl me-2 ms-2' data-tooltip-id='download' data-tooltip-content="Download Excel !" data-tooltip-place="bottom" type='button' onClick={() => handleExcel()} >
                                                        <FaRegFileExcel className='' size={25} style={{ color: 'white' }} />
                                                    </span> : ''}

                                                {detailsData?.status === 'cancelled' ? "" : detailsData?.added_by === localStorage.getItem('id') ? <div className='d-flex justify-content-end'>
                                                    <Button type='button' onClick={() => handleMinute()} variant="" className='save_btn ms-2'>ADD MOM</Button>
                                                </div> : ''}

                                                <Tooltip id='download' className=' tooltip_msg2' />
                                                <Tooltip id='download2' className=' tooltip_msg2' />
                                                <Tooltip id='upload' className=' tooltip_msg3' />
                                            </div>

                                            <Table striped bordered hover className='boot_table mt-3'>
                                                <thead className='table_header'>
                                                    <tr >
                                                        <th >S.NO</th>
                                                        <th> DATE</th>
                                                        <th> TOPIC</th>
                                                        <th> ACTION</th>
                                                    </tr>
                                                </thead>
                                                {detailsData?.meetingminutes?.length ?
                                                    <tbody>
                                                        {detailsData?.meetingminutes?.map((value, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{new Date(value.added_date).toLocaleDateString('en-GB')}</td>
                                                                    <td>{value.topic}</td>
                                                                    <td>
                                                                        <BiShow size={28} className='details text-success ms-3'
                                                                            onClick={() => handleView(value)}
                                                                            data-tooltip-id='details'
                                                                            data-tooltip-content="MOM VIEW!"
                                                                            data-tooltip-place="bottom"
                                                                        />
                                                                        {localStorage.getItem('type') === 'Admin' ? <GiCancel size={20} className='delete text-danger ms-3'
                                                                            onClick={() => mom_delete(value._id)}
                                                                            data-tooltip-id='del'
                                                                            data-tooltip-content="Delete MOM!"
                                                                            data-tooltip-place="bottom"
                                                                        /> :
                                                                            localStorage.getItem('type') === 'User' && localStorage.getItem('id') === detailsData?.added_by ?
                                                                                <GiCancel size={20} className='delete text-danger ms-3'
                                                                                    onClick={() => mom_delete(value._id)}
                                                                                    data-tooltip-id='del'
                                                                                    data-tooltip-content="Delete MOM!"
                                                                                    data-tooltip-place="bottom"
                                                                                /> :
                                                                                ""}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody> :
                                                    <tbody>
                                                        <tr>
                                                            <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={4}>MOM List Empty</td>
                                                        </tr>
                                                    </tbody>
                                                }
                                                <Tooltip id='details' className='bg-success tooltip_msg' />
                                                <Tooltip id='del' className='bg-danger tooltip_msg' />


                                            </Table>

                                        </div>
                                    </div>
                                </Tab>
                                {/* -----------------------------------------------------------------------------ADD FILE SECTION--------------------------------------------------------------------                              */}


                                <Tab eventKey="Files" title={<span> Files</span>}>
                                    <div className="details_section">
                                        <div className='ps-3 pe-3'>
                                            <Table striped bordered hover className='boot_table'>
                                                <thead className='table_header'>
                                                    <tr >
                                                        <th style={{ width: "20px" }}>S.NO</th>
                                                        <th> TITLE</th>
                                                        <th> USER</th>
                                                        <th> DATE</th>
                                                        <th>FILE</th>
                                                    </tr>
                                                </thead>
                                                {detailsData && detailsData && detailsData.attendeefile && detailsData.attendeefile.length ?
                                                    <tbody>
                                                        {detailsData && detailsData && detailsData.attendeefile.map((value, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{value.desc}</td>
                                                                    <td>{value?.user_id?.name}</td>
                                                                    <td>{new Date(value && value.added_date).toLocaleDateString('en-GB')}</td>
                                                                    <td><a href='' download={process.env.REACT_APP_IMAGE_URL + `${value.file_path}`}><FaFileDownload style={{ color: "green" }} size={20} /></a></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody> :
                                                    <tbody>
                                                        <tr>
                                                            <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={6}>File List Empty</td>
                                                        </tr>
                                                    </tbody>
                                                }
                                            </Table>
                                            {detailsData?.status === 'cancelled' ? "" : detailsData?.added_by === localStorage.getItem('id') ? <div className='d-flex justify-content-end'>
                                                <Button type='submit' onClick={() => handleFile()} variant="" className='save_btn'>ADD FILE</Button>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </Tab>


                            </Tabs>
                        </div>

                    </div>}
            </div>
            <AddAttendee show={show} setShow={setShow} data={data} bool={bool} setBool={setBool} />
            {/* <ExportExcel excel={excel} setExcel={setExcel} /> */}
            <AddMinutes minute={minute} setMinute={setMinute} data={data} bool={bool} setBool={setBool} momLoading={momLoading} setMomLoading={setMomLoading} />
            <AddFile file={file} setFile={setFile} data={data} bool={bool} setBool={setBool} />
            <MOMView view={view} currentDate={currentDate} setView={setView} data={data} bool={bool} setBool={setBool} momData={momData} momLoading={momLoading} />
            <UploadExcel uploadModel={uploadModel} data={data} bool={bool} setBool={setBool} setUploadModel={setUploadModel} excelData={excelData} setExcelData={setExcelData} />
            <ToastContainer />
        </>
    )
}
