import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddMeetingMinutRes;
export const AddMeetingMinutTodo = createAsyncThunk('AddMeetingMinut', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_meeting_minutes',
            data: {
                "meeting_id": data?.meetingId,
                "subtopic":data?.data,
                "topic":data?.title,
                "added_by":localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return AddMeetingMinutRes = res.data
        
    } catch (error) {
        return AddMeetingMinutRes = error.response.data
    }
})


