import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Loader from '../../../component/loader/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { TodoDetailsRes, TodoDetailsTodo } from '../../../redux/slices/admin-slices/todo/TodoDetails';
import { MdCancel } from 'react-icons/md';
import TextArea from '../../../component/input_filed/TextArea';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as Yup from "yup";
import { useFormik } from 'formik';
import SaveButton from '../../../component/buttons/SaveButton';
import { CompleteTaskRes, CompleteTaskTodo } from '../../../redux/slices/admin-slices/todo/CompleteTodo';
import { toast } from 'react-toastify';

export default function TodoDetails(props) {
    const { data, details, setDetails, bool, setBool } = props
    var todoId = data && data._id
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const detailsData = useSelector(state => state?.TodoDetails?.data?.data?.tododetail?.[0])
    const currentDate = useSelector(state => state?.TodoDetails?.data?.data?.currentDate)

    console.log("detailsData", detailsData)

    useEffect(() => {
        setLoading(true)
        dispatch(TodoDetailsTodo(todoId)).then(() => details_res())
    }, [todoId, bool])

    const details_res = () => {
        if (TodoDetailsRes && TodoDetailsRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const AddRemarkSchema = Yup.object({
        remark: Yup.string().required("Remark Is Required"),
    });
    const initialValues = {
        remark: '',
        todo_id: todoId && todoId,
        status: detailsData?.approved_required === 'yes' && detailsData?.status === 'pending' ? 'On Approval' : "completed"

    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddRemarkSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            dispatch(CompleteTaskTodo(values)).then(() => add_res(action))
            // console.log(values);
        }
    });
    const add_res = () => {
        if (CompleteTaskRes?.status) {
            toast.success(CompleteTaskRes?.message, { position: 'bottom-right' })
            setBool(!bool)
            setDetails(false);
        } else {
            toast.error(CompleteTaskRes?.message, { position: 'bottom-right' })

        }
    }

    return (
        <>

            <div className={details ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-md ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header d-flex justify-content-between"  >
                            <h5 className='text-center ' >Task Details</h5>
                            <span onClick={() => { setDetails(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5 pb-5'>
                                <Loader />
                            </div>
                            : 
                            <PerfectScrollbar suppressScrollX={true}  style={{ height: '70vh' }}>
                            <div className='modal-body '>
                            
                                    <div className='p-2 '>
                                        {/* <div className="row p-0"> */}
                                            <div className='d-flex  justify-content-between  align-items-center'>
                                                <p>Assign By :  </p>
                                                <span className='ms-2'> {detailsData && detailsData.added_by && detailsData.added_by.name}</span>
                                            </div>
                                            <div className='d-flex  justify-content-between  align-items-center'>
                                                <p>Assign To :  </p>
                                                <span className='ms-2'> {detailsData && detailsData.assign_to && detailsData.assign_to.name}</span>
                                            </div>

                                            <div className='d-flex mt-2 justify-content-between  align-items-center  '>
                                                <p>Start Date :</p>
                                                <span className='ms-2'>{new Date(detailsData && detailsData.startdate).toLocaleDateString('en-GB')}</span>
                                            </div>

                                            <div className='d-flex mt-2 justify-content-between align-items-center  '>
                                                <p>Deadline :</p>
                                                <span className='ms-2'>{new Date(detailsData && detailsData.deadline).toLocaleDateString('en-GB')}</span>
                                            </div>
                                            <div className='d-flex mt-2 justify-content-between align-items-center  '>
                                                <p>Time :</p>
                                                <span className='ms-2'>{detailsData?.time}</span>
                                            </div>
                                            {/* <div className='d-flex mt-2 justify-content-between align-items-center '>
                                            <p>Duration :</p>
                                            <span className='ms-2'>{detailsData && detailsData.duration} Hours</span>
                                        </div> */}
                                            <div className='d-flex mt-2 justify-content-between  align-items-center'>
                                                <p>Status :</p>
                                                {/* <span className='ms-2'>{detailsData && detailsData.status}</span> */}
                                                {new Date(currentDate).toLocaleDateString('en-GB') > new Date(detailsData?.deadline).toLocaleDateString('en-GB') &&
                                                    data?.status === 'pending' ? <h6 className='badge pt-2 mb-0 ms-2' style={{ backgroundColor: 'orangered' }}>OVERDUE</h6> :
                                                    data?.status === 'completed' ? <h6 className='badge pt-2 mb-0 ms-2 text-bg-success'>COMPLETED</h6> :
                                                        data?.status === 'cancelled' ? <h6 className='badge pt-2 ms-2 mb-0' style={{ backgroundColor: "#f16c4d" }}>CANCELLED</h6> :
                                                            data?.status === 'pending' ? <h6 className='badge pt-2 mb-0 ms-2 text-bg-danger'>PENDING</h6> :
                                                                data?.status === 'On Approval' ? <h6 className='badge pt-2  mb-0 ms-2  text-bg-warning'>On Approval</h6> :
                                                                    data?.status === 'rejected' ? <h6 className='badge pt-2 mb-0 ms-2 text-bg-danger'>Rejected</h6> :
                                                                        ""}
                                            </div>
                                            {data?.status === 'pending' ? "" :
                                                <div className='d-flex justify-content-between align-items-center  mt-2'>
                                                    <p>{data && data.status === 'completed' ? 'Completed Date' :
                                                        data && data.status === 'cancel' ? 'Cancle Date' :
                                                            data && data.status === 'On Approval' ? 'On Approval Date ' :
                                                                data && data.status === 'rejected' ? 'Rejected Date' :
                                                                    ""} </p>
                                                    <span className='ms-2'>{
                                                        data && data.status === 'completed' ? new Date(data && data.completedate).toLocaleDateString('en-GB') :
                                                            data && data.status === 'cancle' ? new Date(data && data.canceldate).toLocaleDateString('en-GB') :
                                                                data && data.status === 'On Approval' ? new Date(data && data.on_approval_date).toLocaleDateString('en-GB') :
                                                                    data?.status === 'rejected' ? new Date(data?.reject_date).toLocaleDateString('en-GB') :
                                                                        ''}</span>
                                                </div>
                                            }
                                            <div className=' mt-2'>
                                                <p>Title </p>
                                                <span className=''>{detailsData && detailsData.title}</span>
                                            </div>
                                            {detailsData?.status === 'completed' || detailsData?.status === 'rejected' ? <div className="col-md-12 mt-2">
                                                <div className='remark_section'>
                                                    <PerfectScrollbar >
                                                        <div className='p-2'>
                                                            <p className='complete_rem'>Completed Remark ( {detailsData?.status === 'rejected' ? new Date(detailsData?.on_approval_date.slice(0, 10)).toLocaleDateString('en-GB') : new Date(detailsData?.completedate.slice(0, 10)).toLocaleDateString('en-GB')} )</p>
                                                            <span className=''>{detailsData?.remarks}</span>
                                                        </div>
                                                    </PerfectScrollbar>
                                                </div>
                                            </div> : ''}
                                            {detailsData?.status === 'rejected' ? <div className="col-md-12 mt-2">
                                                <div className='remark_section'>
                                                    <PerfectScrollbar >
                                                        <div className='p-2'>
                                                            <p>Rejected Remark ( {new Date(detailsData?.reject_date.slice(0, 10)).toLocaleDateString('en-GB')} ) </p>
                                                            <span className='' >{detailsData?.reject_remarks}</span>
                                                        </div>
                                                    </PerfectScrollbar>
                                                </div>
                                            </div> : ''}
                                            {detailsData?.assign_to?._id === localStorage.getItem('id') && data?.status === 'On Approval' ? '' :
                                                detailsData?.assign_to?._id === localStorage.getItem('id') && data?.status === 'pending' ||
                                                    detailsData?.assign_to?._id === localStorage.getItem('id') && data?.status === 'rejected' ||
                                                    detailsData?.assign_to?._id === localStorage.getItem('id') && new Date().toLocaleDateString('en-GB') > new Date(detailsData?.deadline).toLocaleDateString('en-GB') && data?.status === 'pending' ?
                                                    <div className='mt-2'>
                                                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                            <div className="row">
                                                                <div className='col-md-12'>
                                                                    <TextArea
                                                                        label_name='Remark'
                                                                        placeholder=''
                                                                        rows={'2'}
                                                                        name='remark'
                                                                        value={values.remark || ''}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.remark && touched.remark ? (<span className='text-danger form_label' >{errors.remark}</span>) : null}
                                                                    />

                                                                </div>
                                                                <div className=' text-center '>
                                                                    <SaveButton name={'Save'} />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div> : ""}
                                        </div>
                                    {/* </div> */}
                             
                            </div>
                            </PerfectScrollbar>}
                    </div>
                </div>
            </div>

        </>
    )
}
