import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { GiCancel } from 'react-icons/gi'
import { CancelMeetingRes, CancelMeetingTodo } from '../../../../redux/slices/admin-slices/meeting/CancelMeeting'
import { BiShow } from 'react-icons/bi'

export default function MeetingAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    var permission = JSON.parse(localStorage.getItem('actions'))

    const meeting_details = () => {
        navigate('../admin/meeting-details', { state: props.data && props.data })
    }

    const meeting_delete = () => {

        Swal.fire({
            title: "Meeting Cancel",
            text: "Are You Sure Want To Cancel This Meeting ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CancelMeetingTodo(props.data._id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (CancelMeetingRes && CancelMeetingRes.status) {
            setBool(!bool)
            Swal.fire(
                "Meeting",
                'Meeting Cancel Successfully',
                'success'
            )
        } else {
            toast.error(CancelMeetingRes && CancelMeetingRes.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <div>
                {permission && permission.some(val => val.action_display_name === 'Meeting Details') ?
                    <BiShow size={28} className='details text-success ms-3'
                        onClick={() => meeting_details()}
                        data-tooltip-id='details'
                        data-tooltip-content="Meeting Details!"
                        data-tooltip-place="bottom"
                      type='button'
                    /> : ""}

                {props?.data?.added_by === localStorage.getItem('id') && props?.data?.status === 'pending' ? <GiCancel size={20} className='delete text-danger ms-3'
                    onClick={() => meeting_delete()}
                    data-tooltip-id='del'
                    data-tooltip-content="Meeting Cancle!"
                    data-tooltip-place="bottom"       
                    type='button'
                /> :  ''}


                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-success tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
