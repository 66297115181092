import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateModuleRes;
export const updateModuleTodo = createAsyncThunk('updateModule', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_mastermodule',
            data: {
                "id": data && data.id,
                "module_display_name": data && data.values && data.values.name,
                "module_url": data && data.values && data.values.module_url,
                "module_description": data && data.values && data.values.description,
                "module_priority": data && data.values && data.values.priority,
                "status": data && data.values && data.values.status,
                "menu_cstatus": data && data.values && data.values.show_in_menu,
                "modified_by": localStorage.getItem('id'),
                "module_icon": data && data.values && data.values.icon
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateModuleRes = res.data

    } catch (error) {
        return updateModuleRes = error.response.data
    }
})