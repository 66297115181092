    import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addDesignationRes;
export const addDesignationTodo = createAsyncThunk('addDesignation', async (data) => {
   
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_add_designation',
            data: {
                "name": data.designation,
                "status": data.status,
                "added_by": localStorage.getItem('id'),
                "company_id": null
            },
            headers: { "Content-Type": "application/json" }
        })
        return addDesignationRes = res.data

    } catch (error) {
        return addDesignationRes = error.response.data
    }
})

export var updateDesignationRes;
export const updateDesignationTodo = createAsyncThunk('updateDesignation', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_update_designation',
            data: {
                "id":data.id,
                "name": data.designation,
                "status": data.status,
                "modified_by": localStorage.getItem('id'),
                "company_id":null
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateDesignationRes = res.data

    } catch (error) {
        return updateDesignationRes = error.response.data
    }
})