import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var cityListRes;
export const cityListTodo = createAsyncThunk('cityList', async (id) => {

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'city',
            headers: { "Content-Type": "application/json" },
            data: {
                "state_id": id
            }
        })
        return cityListRes = res.data

    } catch (error) {
        return cityListRes = error.response.data
    }
});

const cityListSlice = createSlice({
    name: 'cityList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(cityListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(cityListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default cityListSlice.reducer; 