import React, { useState } from 'react'
import DepartmentList from './DepartmentList'
import DepartmentForm from '../../../../component/form/super-admin-form/master/department/DepartmentForm'

export default function AddDepartment() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Department</h3>
                        </div>
                        <DepartmentForm bool={bool} setBool={setBool} />
                        <div className='mt-2'>
                        <div className='layout_head'>
                            <h3> Department List</h3>
                        </div>
                        <DepartmentList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
