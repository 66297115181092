import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'


export default function PageNotFound() {

    const navigate = useNavigate()

    useEffect(() => {
        Swal.fire({
            title: 'Oops...',
            text: '404 Page Not Found!',
            icon: 'error',
            confirmButtonColor: '#121136',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1)
            }
        })
    }, [])

    return (
        <></>
    )
}