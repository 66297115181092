import React, { useState } from 'react'
import Text from '../../../../input_filed/Text';
import SelectStatus from '../../../../input_filed/SelectStatus';
import SaveButton from '../../../../buttons/SaveButton';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Loader from '../../../../loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateDesignationRes, updateDesignationTodo } from '../../../../../redux/slices/super-admin-slices/master/designation/AddDesignation';
import { DesignationSchema } from './Validation';

export default function UpdateDesignationForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation();
    var designation = state
    // console.log(designation)

    const initialValues = {
        id : designation && designation._id,
        designation: designation && designation.name,
        status: designation && designation.status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: DesignationSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateDesignationTodo(values)).then(() => updateDesignation_res(action))
        }
    })

    const updateDesignation_res = (action) => {
        if (updateDesignationRes && updateDesignationRes.status) {
            setLoading(false)
            toast.success(updateDesignationRes && updateDesignationRes.message, { position: 'bottom-right' })
            setTimeout(() => {
                navigate('../superadmin/designation')
            }, 1500);
            
        } else {
            setLoading(false)
            toast.success(updateDesignationRes && updateDesignationRes.message, { position: 'bottom-right' })

        }
    }
    return (
        <>
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            {loading ?
                                <div className='d-flex justify-content-center'>
                                    <Loader />
                                </div> :
                                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                    <div className='form_layout mt-2'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <Text
                                                    label_name='Designation'
                                                    placeholder=''
                                                    name='designation'
                                                    value={values.designation || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.designation && touched.designation ? (<span className='text-danger form_label' >{errors.designation}</span>) : null}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <SelectStatus
                                                    closeMenu={true}
                                                    label_name='Status'
                                                    disabled={false}
                                                    name='status'
                                                    defaultValue={designation.status}
                                                    onChange={(e) =>
                                                        setFieldValue('status', e.value)
                                                    }
                                                    onBlur={handleBlur}
                                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                                />
                                            </div>
                                            <div className='col-md-12 text-end mt-2'>
                                                <SaveButton name={'Update'} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>}
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </>
        </>
    )
}
