import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AdminDashboardRes;
export const AdminDashboardTodo = createAsyncThunk('AdminDashboard', async (data) => {
    // console.log("data", data)
    var type = localStorage.getItem('type')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_dashboard',
            data:
                {
                    "type": type === "Admin" ? '':'1',
                    'fk_user_type_id':type === "Admin" ? localStorage.getItem('userTypeId'):'' ,
                    "company_id": localStorage.getItem('customerId'),
                    "user_id": localStorage.getItem('id')
                },
            headers: { "Content-Type": "application/json" },
         
        })
        return AdminDashboardRes = res.data

    } catch (error) {
        return AdminDashboardRes = error.response.data
    }
});

const AdminDashboardSlice = createSlice({
    name: 'AdminDashboard',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(AdminDashboardTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(AdminDashboardTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default AdminDashboardSlice.reducer; 