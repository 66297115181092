import React, { useState } from 'react'
import AddPlanForm from '../../../component/form/super-admin-form/plan-form/AddPlanForm'
import PlanList from './PlanList'

export default function AddPlan() {
    const [bool, setBool] = useState(false)
    return (
        <>
           <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Plan</h3>
                        </div>
                        <AddPlanForm bool={bool} setBool={setBool} />
                        <div className='mt-2'>
                        <div className='layout_head'>
                            <h3> Plan List</h3>
                        </div>
                        <PlanList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
