import React from 'react'
import SuperAdminLoginForm from '../../../component/form/super-admin-form/login-form/SuperAdminLogin'
import logo from '../../../assest/images/rexosocial.png'
import sidelogo from '../../../assest/images/social-logo2.png'

export default function SuperAdminLogin() {
    return (
        <>
          <div className='login_bg'>
                <div className="container-fluid">
                    <div className=" login-container row d-flexh-100 g-0 justify-content-between">
                        <div className="col-sm-6 align-self-md-center my-auto">
                            <div className='side-logo'>
                                <img src={sidelogo} alt="" className='img-fluid logo-img' />
                            </div>
                        </div>
                        <div className="col-sm-6 align-self-md-center my-auto">
                            <div className='login-details'>
                                <div>
                                    <div className='finalLogo_section'>
                                        <img src={logo} alt="" className='img-fluid finalLogo' />
                                    </div>
                                    <h5 className='mt-3'>Please Log Into Your Account... ! </h5>
                                    <div className='mt-4'>
                                      <SuperAdminLoginForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
