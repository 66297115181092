import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../../component/loader/Loader'
import { staffListRes, staffListTodo } from '../../../../redux/slices/super-admin-slices/master/staff/StaffList'
import StaffAction from '../../../../component/data-table-action/table_action/super-admin-action/StaffAction'

export default function StaffList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const staffList = useSelector(state => state && state.StaffList && state.StaffList.data && state.StaffList.data.data)
    console.log(staffList)

    useEffect(() => {
        setLoading(true)
        dispatch(staffListTodo()).then(() => staffList_res())
    }, [bool])

    const staffList_res = () => {
        if (staffListRes && staffListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'email',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'CONTACT',
            field: 'phone_no',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DESIGNATION',
            field: 'designations.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DEPARTMENT',
            field: 'departments.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            width: 120

            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: StaffAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>


            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                staffList && staffList.length ?
                    <DataTable
                        rowData={staffList}
                        columnDefs={columnDefs}
                        height={'70vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

        </>
    )
}
