import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CancelMeetingRes;
export const CancelMeetingTodo = createAsyncThunk('department_delete', async (id) => {
    // console.log("data", data)
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'cancel_meeting',
            data: {
                "meeting_id":id,
                "company_id": localStorage.getItem('customerId') ,
                'user_id':localStorage.getItem('id')

            },
            headers: { "Content-Type": "application/json" }
        })
        return CancelMeetingRes = res.data

    } catch (error) {
        return CancelMeetingRes = error.response.data
    }
})