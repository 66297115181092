import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DeletePlanRes;
export const DeletePlanTodo = createAsyncThunk('DeletePlan', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'deleted_plan',
            data: {
                "id":data,
            },
            headers: { "Content-Type": "application/json" }
        })
        return DeletePlanRes = res.data
        
    } catch (error) {
        return DeletePlanRes = error.response.data
    }
})


