import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateAssignPlanRes;
export const updateAssignPlanTodo = createAsyncThunk('updateAssignPlan', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_update_department',
            data: {
                "id":data.id,
                "name":data.department,
                "status":data.status,
                "modified_by":localStorage.getItem('id'),
                "company_id":null
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateAssignPlanRes = res.data
        
    } catch (error) {
        return updateAssignPlanRes = error.response.data
    }
})