import * as Yup from "yup";

export const CustomerSchema = Yup.object({

    name: Yup.string().required("Enter Menu Name"),
    admin_name: Yup.string().required("Enter Menu Name"),
    short_code: Yup.string().required("Enter Unique Short Code"),
    admin_email: Yup.string().required(" Enter Email"),
    admin_psw: Yup.string().required(" Enter  Password"),
    admin_phn: Yup.string().required("Enter Mobile Number"),
    customer_logo: Yup.mixed().required('Image is required'),
    country: Yup.string().required("Select Country"),
    state: Yup.string().required("Select State"),
    city: Yup.string().required("Select City"),
    status: Yup.string().required("Select Status"),

});

export const UpdateCustomerSchema = Yup.object({

    name: Yup.string().required("Enter Menu Name"),
    admin_name: Yup.string().required("Enter Menu Name"),
    admin_email: Yup.string().required(" Enter Email"),
    admin_phn: Yup.string().required("Enter Mobile Number"),
    customer_logo: Yup.mixed().required('Image is required'),
    country: Yup.string().required("Select Country"),
    state: Yup.string().required("Select State"),
    city: Yup.string().required("Select City"),
    status: Yup.string().required("Select Status"),

});

