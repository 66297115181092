import React from 'react'
import logo from '../../../assest/images/deccon_copy.png'
import sidelogo from '../../../assest/images/Telework-rafiki.png'
import LoginForm from '../../../component/form/admin-form/admin-login/LoginForm'


export default function AdminLogin() {
    return (
        <>
            <div className='login_bg'>
                <div className="container-fluid">
                    <div className=" login-container row d-flexh-100 g-0 justify-content-between">
                        <div className="col-sm-6 align-self-md-center my-auto">
                            <div className='side-logo'>
                                <img src={sidelogo} alt="" className='img-fluid logo-img' />
                            </div>
                        </div>
                        <div className="col-sm-6 align-self-md-center my-auto">
                            <div className='login-details'>
                                <div>
                                    <div className='finalLogo_section2'>
                                        <img src={logo} alt="" className='img-fluid finalLogo2' />
                                    </div>
                                    <h5 className='mt-3'>Please Log Into Your Account... ! </h5>
                                    <div className='mt-4'>
                                     <LoginForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
