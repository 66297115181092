import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addDepartmentRes;
export const addDepartmentTodo = createAsyncThunk('addDepartment', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_add_department',
            data: {
                "name":data.department,
                "status":data.status,
                "added_by":localStorage.getItem('id'),
                "company_id": null
            },
            headers: { "Content-Type": "application/json" }
        })
        return addDepartmentRes = res.data
        
    } catch (error) {
        return addDepartmentRes = error.response.data
    }
})


export var updateDepartmentRes;
export const updateDepartmentTodo = createAsyncThunk('updateDepartment', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_update_department',
            data: {
                "id":data.id,
                "name":data.department,
                "status":data.status,
                "modified_by":localStorage.getItem('id'),
                "company_id":null
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateDepartmentRes = res.data
        
    } catch (error) {
        return updateDepartmentRes = error.response.data
    }
})