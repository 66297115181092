import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addAssignPlanRes;
export const addAssignPlanTodo = createAsyncThunk('addAssignPlan', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_assign_subscription',
            data: {
                "company_id":data.customer,
                "plan_id":data.plan,
                "startdate":data.start_date,
                "added_by":localStorage.getItem('id'),
            },
            headers: { "Content-Type": "application/json" }
        })
        return addAssignPlanRes = res.data
        
    } catch (error) {
        return addAssignPlanRes = error.response.data
    }
})


