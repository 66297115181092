import React from 'react'
import UpdateCustomerForm from '../../../component/form/super-admin-form/customer-form/UpdateCustomerForm'

export default function UpdateCustomer() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Customer </h3>
                        </div>
                        <UpdateCustomerForm />
                    </div>
                </div>
            </div>
        </>
    )
}
