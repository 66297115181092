import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AssignPlanListRes;
export const AssignPlanListTodo = createAsyncThunk('AssignPlanList', async (data) => {
    const Role = localStorage.getItem('userRole')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'assign_subscription_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "aid": Role === 'superadmin' ?'': localStorage.getItem('id')
            }
        })
        return AssignPlanListRes = res.data

    } catch (error) {
        return AssignPlanListRes = error.response.data
    }
});

const AssignPlanListSlice = createSlice({
    name: 'AssignPlanList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(AssignPlanListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(AssignPlanListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default AssignPlanListSlice.reducer; 