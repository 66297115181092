import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addUserPermissionRes;
export const addUserPermissionTodo = createAsyncThunk('addUserPermission', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_user_module_permission',
            data: data.user_modules,
            headers: { "Content-Type": "application/json" }
        })
        return addUserPermissionRes = res.data

    } catch (error) {
        return addUserPermissionRes = error.response.data
    }
})



