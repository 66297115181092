import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateCustomerRes;
export const updateCustomerTodo = createAsyncThunk('updateCustomer', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_mastercompany',
            data: data,
            headers: { "Content-Type": "multipart/form-data"}
        })
        return updateCustomerRes = res.data
        
    } catch (error) {
        return updateCustomerRes = error.response.data
    }
})