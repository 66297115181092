import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

export default function ReelsListAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const reels_details = () => {
        navigate('../admin/reels-details', { state: props.data && props.data })
    }
    var permission = JSON.parse(localStorage.getItem('actions'))
   
    return (
        <>
            <div>
            {permission && permission.some(val => val.action_display_name === 'Reels Details') ?
                <LuBadgeInfo size={20} className='details text-primary ms-3'
                    onClick={() => reels_details()}
                    data-tooltip-id='details'
                    data-tooltip-content="Reels Details!"
                    data-tooltip-place="bottom"
                />:""}


                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
