import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteUserTypeRes;
export const deleteUserTypeTodo = createAsyncThunk('deleteUserType', async (id) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_delete_usertype',
            data: {
                "id": id,
                "company_id": null
            },
            headers: { "Content-Type": "application/json" }
        })
        return deleteUserTypeRes = res.data

    } catch (error) {
        return deleteUserTypeRes = error.response.data
    }
})