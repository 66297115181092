import React, { useState } from 'react'
import Text from '../../../../input_filed/Text';
import SelectStatus from '../../../../input_filed/SelectStatus';
import SaveButton from '../../../../buttons/SaveButton';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Loader from '../../../../loader/Loader';
import { DepartmentSchema } from '../../../super-admin-form/master/department/Validation';
import { adminAddDepartmentRes, adminAddDepartmentTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-department/AddUpdateDepartment';

export default function AdminAddDepartmentForm(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    console.log(bool)

    const initialValues = {
        department: '',
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: DepartmentSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(adminAddDepartmentTodo(values)).then(() => addDepartment_res())
            action.resetForm()
        }
    })

    const addDepartment_res = () => {
        if (adminAddDepartmentRes && adminAddDepartmentRes.status) {
            toast.success(adminAddDepartmentRes && adminAddDepartmentRes.message, { position: 'bottom-right' })
            setLoading(false)
            setBool(!bool)
        } else {
            setLoading(false)
            toast.error(adminAddDepartmentRes && adminAddDepartmentRes.message, { position: 'bottom-right' })
        }
    }

    return (
        <>
         
                        {loading ?
                            <div className='d-flex justify-content-center'>
                                <Loader />
                            </div>
                            :
                            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                <div className='form_layout mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Text
                                                label_name='Department'
                                                placeholder=''
                                                name='department'
                                                value={values.department || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.department && touched.department ? (<span className='text-danger form_label' >{errors.department}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <SelectStatus
                                                closeMenu={true}
                                                label_name='Status'
                                                disabled={false}
                                                name='status'
                                                defaultValue={''}
                                                onChange={(e) =>
                                                    setFieldValue('status', e.value)
                                                }
                                                onBlur={handleBlur}
                                                error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-12 text-end mt-2'>
                                            <SaveButton name={'Save'} />
                                        </div>
                                    </div>
                                </div>
                            </Form>}
          

            <ToastContainer />
        </>
    )
}
