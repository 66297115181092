import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { DeleteGroupRes, DeleteGroupTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-group/DeleteGroup'

export default function AdminGroupAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    var permission = JSON.parse(localStorage.getItem('actions'))

    const edit_Group = () => {
        navigate('../admin/update-group', { state: props.data && props.data })
    }
    const del_Group = () => {

        Swal.fire({
            title: "Group Delete",
            text: "Are You Sure Want To Delete This Group ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteGroupTodo(props.data._id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (DeleteGroupRes && DeleteGroupRes.status) {
            setBool(!bool)
            Swal.fire(
                "Group",
                'Group Deleted Successfully',
                'success'
            )
        } else {
            toast.error(DeleteGroupRes && DeleteGroupRes.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>

                {permission && permission.some(val => val.action_display_name === 'Update Group') ?
                    <LuFileEdit size={20} className='edit text-success ms-3'
                        onClick={() => edit_Group()}
                        data-tooltip-id='edit'
                        data-tooltip-content="Edit Group!"
                        type='button'
                        data-tooltip-place="bottom"
                    />
                    : ""}

                {permission && permission.some(val => val.action_display_name === 'Delete Group') ?
                    <LuTrash2 size={20} className='delete text-danger ms-3'
                        onClick={() => del_Group()}
                        data-tooltip-id='del'
                        data-tooltip-content="Delete Group!"
                        data-tooltip-place="bottom"
                    /> : ''}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
