import React, { useState } from 'react'
import UserTypeList from './UserTypeList'
import AddUserTypeForm from '../../../component/form/super-admin-form/user-type/AddUserTypeForm'

export default function AddUserType() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Add User Type</h3>
                        </div>
                        <AddUserTypeForm  bool={bool} setBool={setBool}/>
                        <div className='mt-3'>
                            <UserTypeList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
