import React from 'react'
import UpdateAssignPlanForm from '../../../component/form/super-admin-form/assign-plan-form/UpdateAssignPlan'

export default function UpdateAssignPlan() {
    return (
        <>
          <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>Update Assign Plan</h3>
                        </div>
                        <UpdateAssignPlanForm />
                    </div>
                </div>
            </div>
        </>
    )
}
