import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var MeetingDetailsRes;
export const MeetingDetailsTodo = createAsyncThunk('MeetingDetails', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'meeting_details_byid',
            headers: { "Content-Type": "application/json" },
            data: {
                "id":id,
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return MeetingDetailsRes = res.data

    } catch (error) {
        return MeetingDetailsRes = error.response.data
    }
});

const MeetingDetailsSlice = createSlice({
    name: 'MeetingDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(MeetingDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(MeetingDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default MeetingDetailsSlice.reducer; 