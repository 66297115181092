import React, { useState } from 'react'
import AdminAddDesignationForm from '../../../../component/form/admin-form/admin-master-form/admin-designation-form/AdminAddDesignationForm'
import AdminDesignationList from './AdminDesignationList'

export default function AdminAddDesignation() {
    const [bool, setBool] = useState(false)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Designation</h3>
                            <AdminAddDesignationForm bool={bool} setBool={setBool}/>
                        </div>
                        <div className='mt-2'>
                            <div className='layout_head'>
                                <h3> Designation List</h3>
                                <AdminDesignationList bool={bool} setBool={setBool}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
