import React from 'react'
import UpdateActionForm from '../../../component/form/super-admin-form/action-form/UpdateActionForm'

export default function UpdateAction() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>Update Action </h3>
                        </div>
                        <UpdateActionForm/>
                    </div>
                </div>
            </div>
        </>
    )
}
