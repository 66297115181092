import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var customerListRes;
export const customerListTodo = createAsyncThunk('customerList', async (id) => {
    const Role = localStorage.getItem('userRole')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'mastercompany_list',
            data: {
                "uid": Role === 'superadmin' ? '' : localStorage.getItem('id')

            },
            headers: { "Content-Type": "application/json" },

        })
        return customerListRes = res.data

    } catch (error) {
        return customerListRes = error.response.data
    }
});

const customerListSlice = createSlice({
    name: 'customerList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(customerListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(customerListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default customerListSlice.reducer; 