import React, { useState } from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import TodoDetails from '../../../../pages/admin-pages/Todo/TodoDetails'
import { GiCancel } from 'react-icons/gi'
import { TodoCancelRes, TodoCancelTodo } from '../../../../redux/slices/admin-slices/todo/CancelTodo'
import { BiShow } from 'react-icons/bi'
import { MdAddTask } from 'react-icons/md'
import { ApproveRejectTaskRes, ApproveRejectTaskTodo } from '../../../../redux/slices/admin-slices/todo/ApproveRejectTask'

export default function TodoAction(props) {
    const { bool, setBool, details, setDetails, data, setData, reject, setReject } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const todo_details = () => {
        // navigate('../admin/todo-details', { state: props.data && props.data })
        setDetails(true)
        setData(props.data && props.data)
    }

    const todo_reject = () => {
        setReject(true)
        setData(props.data && props.data)
    }
    var permission = JSON.parse(localStorage.getItem('actions'))

    const todo_cancel = () => {

        Swal.fire({
            title: "Task Cancel",
            text: "Are You Sure Want To Cancel This Task ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(TodoCancelTodo(props.data._id)).then(() => del_res())
            } else if (result.dismiss === Swal.DismissReason.cancel) {

                console.log('Task Rejected');
            }
        })
    }

    const del_res = () => {
        if (TodoCancelRes && TodoCancelRes.status) {
            setBool(!bool)
            Swal.fire(
                "Task",
                'Task Cancel Successfully',
                'success'
            )
        } else {
            toast.error(TodoCancelRes && TodoCancelRes.message, { position: "bottom-right" })
        }
    }

    const todo_approval = () => {
        Swal.fire({
            title: "Task Approve or Reject",
            text: "Are You Sure Want To Approve or Reject This Task?",
            showDenyButton: true,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006400',
            confirmButtonText: "Approve",
            denyButtonText: `Reject`,
            denyButtonColor: `Reject`,
            cancelButtonText: 'Cancel',

        }).then((result) => {
            if (result.isConfirmed) {
                // Swal.fire("Approve!", "", "success");
                dispatch(ApproveRejectTaskTodo({ "task_id": props?.data?._id, 'status': 'completed' })).then(() => approve_res())
            } else if (result.isDenied) {
                // dispatch(ApproveRejectTaskTodo({ "task_id": props?.data?._id, 'status': 'rejected' })).then(() => reject_res())
                todo_reject()
            }
        })
    }

    const approve_res = () => {
        if (ApproveRejectTaskRes && ApproveRejectTaskRes.status) {
            setBool(!bool)
            Swal.fire(
                "Task",
                ApproveRejectTaskRes?.message,
                'success'
            )
        } else {
            toast.error(ApproveRejectTaskRes && ApproveRejectTaskRes.message, { position: "bottom-right" })
        }
    }
    const reject_res = () => {
        if (ApproveRejectTaskRes && ApproveRejectTaskRes.status) {
            setBool(!bool)
            Swal.fire(
                "Task",
                'Task Reject Successfully',
                'success'
            )
        } else {
            toast.error(ApproveRejectTaskRes && ApproveRejectTaskRes.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <div>
                {permission && permission.some(val => val.action_display_name === 'Task Details') ?
                    <BiShow size={28} className='details text-success ms-2'
                        onClick={() => todo_details()}
                        data-tooltip-id='details'
                        data-tooltip-content="Task Details!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ""}
                {props?.data?.added_by === localStorage.getItem('id') && props?.data?.status === 'pending' ||
                    props?.data?.added_by === localStorage.getItem('id') && props?.data?.status === 'rejected' ||
                    props?.data?.added_by === localStorage.getItem('id') && new Date().toLocaleDateString('en-GB') > new Date(props?.data?.deadline).toLocaleDateString('en-GB') && props?.data?.status === 'pending'
                    ? <GiCancel size={20} className='delete text-danger ms-2'
                        onClick={() => todo_cancel()}
                        data-tooltip-id='del'
                        data-tooltip-content="Task Cancle!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ''}
                {props?.data?.added_by === localStorage.getItem('id') && props?.data?.status === 'On Approval'
                    ? <MdAddTask size={22} className='delete text-info ms-2'
                        onClick={() => todo_approval()}
                        data-tooltip-id='edit'
                        data-tooltip-content="Task Approval/Reject!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ''}
                <Tooltip id='edit' className='bg-info tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-success tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>

        </>
    )
}
