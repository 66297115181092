import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateActionRes;
export const updateActionTodo = createAsyncThunk('updateAction', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_masteraction',
            data: {
                "id": data && data.id,
                "fk_master_module_id": data && data.values && data.values.module,
                "action_display_name": data && data.values && data.values.name,
                "action_url_name": data && data.values && data.values.action_url,
                "description": data && data.values && data.values.description,
                "action_priority": data && data.values && data.values.priority,
                "controller_name": data && data.values && data.values.controller,
                "action_status": data && data.values && data.values.status,
                "menu_status": data && data.values && data.values.showInmenu,
                "modified_by": localStorage.getItem('id')
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateActionRes = res.data

    } catch (error) {
        return updateActionRes = error.response.data
    }
})