import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var MeetingListRes;
export const    MeetingListTodo = createAsyncThunk('MeetingList', async (data) => {
    // console.log("data", data)
    const userType = localStorage.getItem('type')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'meeting_list',
            headers: { "Content-Type": "application/json" },
            data: {

                "company_id": localStorage.getItem('customerId'),
                "adduser": data?.values?.user ? data?.values?.user : "" ,
                "user_id":userType == 'Admin' ? '': localStorage.getItem('id'),
                "date": data?.values?.date ? data?.values?.date : "" ,
                "page": data?.pageSize ? data?.pageSize : "" ,
                // "search":data && data ? data && data.designation ? data && data.designation : "" : '',
                "department":data?.values?.department ? data?.values?.department : "" ,
                "pagelimit":  data?.pageLimit ? data?.pageLimit : "" ,
                "status": data?.values?.status ? data?.values?.status : "" ,
                "title": data?.title ? data?.title : "" ,
            }
        })
        return MeetingListRes = res.data

    } catch (error) {
        return MeetingListRes = error.response.data
    }
});

const MeetingListSlice = createSlice({
    name: 'MeetingList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(MeetingListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(MeetingListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default MeetingListSlice.reducer; 