import React, { useEffect, useState } from 'react'
import Text from '../../../../input_filed/Text';
import SelectStatus from '../../../../input_filed/SelectStatus';
import SingleSelect from '../../../../input_filed/SingleSelect';
import SaveButton from '../../../../buttons/SaveButton';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import Email from '../../../../input_filed/Email';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../loader/Loader';
import { StaffSchema, UpdateStaffSchema } from './Validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateStaffRes, updateStaffTodo } from '../../../../../redux/slices/super-admin-slices/master/staff/AddStaff';
import { userTypeListTodo } from '../../../../../redux/slices/super-admin-slices/user-type/UserTypeList';
import { departmentListTodo } from '../../../../../redux/slices/super-admin-slices/master/department/DepartmentList';
import { designationListTodo } from '../../../../../redux/slices/super-admin-slices/master/designation/DesignationList';

export default function UpdateStaffForm() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    // const customerList = useSelector(state => state && state.CustomerList && state.CustomerList.data && state.CustomerList.data.data)
    const userTypeList = useSelector(state => state && state.UserTypeList && state.UserTypeList.data && state.UserTypeList.data.data)
    const designationList = useSelector(state => state && state.DesignationList && state.DesignationList.data && state.DesignationList.data.data)
    const departmentList = useSelector(state => state && state.DepartmentList && state.DepartmentList.data && state.DepartmentList.data.data)
    const navigate = useNavigate()
    const { state } = useLocation();
    var staff = state

    const designation_opt = designationList && designationList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    const department_opt = departmentList && departmentList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    const usertype_opt = userTypeList && userTypeList.map(val => (
        { "value": val._id, "label": val.type }
    ))

    // const customer_opt = customerList && customerList.map(val => (
    //     { "value": val._id, "label": val.company_name }
    // ))

    const initialValues = {
        id: staff && staff._id,
        name: staff && staff.name,
        email: staff && staff.email,
        mobile_num: staff && staff.phone_no,
        designation: staff && staff.designation,
        department: staff && staff.department,
        user_type: staff && staff.usertype,
        // customer: staff && staff.customer,
        status: staff && staff.status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdateStaffSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateStaffTodo(values)).then(() => updateStaff_res())
            action.resetForm()
        }
    })

    const updateStaff_res = () => {
        if (updateStaffRes && updateStaffRes.status) {
            toast.success(updateStaffRes && updateStaffRes.message, { position: 'bottom-right' })
            setTimeout(() => {
                navigate('../superadmin/staff')
            }, 1500)
            setLoading(false)
        } else {
            toast.error(updateStaffRes && updateStaffRes.message, { position: 'bottom-right' })
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        // dispatch(customerListTodo()).then((res) => {
        //     if (res.payload.status) {
              
        //     } else {
        //         setLoading(false)
        //     }
        // })
        dispatch(userTypeListTodo()).then((res) => {
            if (res.payload.status) {
                dispatch(departmentListTodo()).then((res) => {
                    if (res.payload.status) {
                        dispatch(designationListTodo()).then((res) => {
                            if (res.payload.status) {
                                setLoading(false)
                            } else {
                                setLoading(false)
                            }
                        })
                    } else {
                        setLoading(false)
                    }
                })
            } else {
                setLoading(false)
            }
        })
    }, [])
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>

                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className='form_layout mt-2'>
                                {loading ?
                                    <div className='d-flex justify-content-center p-5'>
                                        <Loader />
                                    </div> :
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Text
                                                label_name='Name'
                                                placeholder=''
                                                name='name'
                                                value={values.name || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <Email
                                                label_name='Email'
                                                placeholder=''
                                                name='email'
                                                value={values.email || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.email && touched.email ? (<span className='text-danger form_label' >{errors.email}</span>) : null}
                                            />
                                        </div>

                                        <div className='col-md-4'>
                                            <Text
                                                label_name='Mobile Number'
                                                placeholder=''
                                                name='mobile_num'
                                                value={values.mobile_num || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mobile_num && touched.mobile_num ? (<span className='text-danger form_label' >{errors.mobile_num}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <SingleSelect
                                                closeMenu={true}
                                                label_name='Designation'
                                                disabled={false}
                                                option={designation_opt}
                                                name='designation'
                                                defaultValue={designation_opt && designation_opt.find((option) => option.value == values.designation)}
                                                onChange={(e) => {
                                                    setFieldValue('designation', e.value);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.designation && touched.designation ? (<span className='text-danger form_label' >{errors.designation}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <SingleSelect
                                                closeMenu={true}
                                                label_name='Department'
                                                disabled={false}
                                                option={department_opt}
                                                name='department'
                                                defaultValue={department_opt && department_opt.find((option) => option.value == values.department)}
                                                onChange={(e) => {
                                                    setFieldValue('department', e.value);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.department && touched.department ? (<span className='text-danger form_label' >{errors.department}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <SingleSelect
                                                closeMenu={true}
                                                label_name='User Type'
                                                disabled={false}
                                                option={usertype_opt}
                                                name='user_type'
                                                defaultValue={usertype_opt && usertype_opt.find((option) => option.value == values.user_type)}
                                                onChange={(e) => {
                                                    setFieldValue('user_type', e.value);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.user_type && touched.user_type ? (<span className='text-danger form_label' >{errors.user_type}</span>) : null}
                                            />
                                        </div>
                                        {/* <div className='col-md-4'>
                                            <SingleSelect
                                                closeMenu={true}
                                                label_name='Customer'
                                                disabled={false}
                                                option={customer_opt}
                                                name='customer'
                                                defaultValue={customer_opt && customer_opt.find((option) => option.value == values.customer)}
                                                onChange={(e) => {
                                                    setFieldValue('customer', e.value);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                error={errors.customer && touched.customer ? (<span className='text-danger form_label' >{errors.customer}</span>) : null}
                                            />
                                        </div> */}
                                        <div className='col-md-4'>
                                            <SelectStatus
                                                closeMenu={true}
                                                label_name='Status'
                                                disabled={false}
                                                name='status'
                                                defaultValue={values.status}
                                                onChange={(e) =>
                                                    setFieldValue('status', e.value)
                                                }
                                                onBlur={handleBlur}
                                                error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                            />
                                        </div>
                                        <div className='col-md-12 text-end mt-2'>
                                            <SaveButton name={'Update'} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
