import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UploadExcelRes;
export const UploadExcelTodo = createAsyncThunk('UploadExcel', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_mom_subtopic_excel',
            data: data,
            headers: { "Content-Type": "application/json" }
        })  
        return UploadExcelRes = res.data
        
    } catch (error) {
        return UploadExcelRes = error.response.data
    }
})


