import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminAddStaffRes;
export const adminAddStaffTodo = createAsyncThunk('adminAddStaff', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_staff',
            data: {
                "name": data.name,
                "phone_no": data.mobile_num,
                "email": data.email,
                "designation": data.designation,
                "department": data.department,
                "usertype": data.user_type,
                "password": data.password,
                "status": data.status,
                "added_by": localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminAddStaffRes = res.data

    } catch (error) {
        return adminAddStaffRes = error.response.data
    }
})


export var adminUpdateStaffRes;
export const adminUpdateStaffTodo = createAsyncThunk('adminUpdateStaff', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_staff',
            data: {
                "id": data.id,
                "name": data.name,
                "phone_no": data.mobile_num,
                "email": data.email,
                "designation": data.designation,
                "department": data.department,
                "usertype": data.user_type,
                "status": data.status,
                "modified_by": localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminUpdateStaffRes = res.data

    } catch (error) {
        return adminUpdateStaffRes = error.response.data
    }
})