import React, { useEffect, useState } from 'react';
import SingleSelect from '../../../input_filed/SingleSelect';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SaveButton from '../../../buttons/SaveButton';
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../../loader/Loader';
import { CustomerSchema } from './Validation';
import { customerModuleListTodo } from '../../../../redux/slices/super-admin-slices/permission/customer-permission/CustomerModuleList';
import { customerListTodo } from '../../../../redux/slices/super-admin-slices/customer/CustomerList';
import { addCustomerPermissionRes, addCustomerPermissionTodo } from '../../../../redux/slices/super-admin-slices/permission/customer-permission/AddCustomerPermission';

export default function CustomerPermissionForm() {
    const dispatch = useDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [loading, setLoading] = useState(false)
    const customerList = useSelector(state => state && state.CustomerList && state.CustomerList.data && state.CustomerList.data.data);
    const CustomerModuleList = useSelector(state => state && state.CustomerModuleList && state.CustomerModuleList.data && state.CustomerModuleList.data.data);
    const [checkedItems, setCheckedItems] = useState([]);
    const [companmyId, setCompanmyId] = useState()
    const [bool, setBool] = useState(false)



    const permissionFunction = (id) => {
        dispatch(customerModuleListTodo(id)).then((res) => {
            if (res && res.payload && res.payload.status) {
                res && res.payload && res.payload.data && res.payload.data.map(val => {
                    val && val.masteractions && val.masteractions.filter(data => data && data.customeraction && data.customeraction[0] && data.customeraction[0].status == "Active").map(custmVal => {
                        console.log("cust", val)
                        setCheckedItems((prev) => [...prev, {
                            "fk_master_module_id": custmVal && custmVal.customeraction && custmVal.customeraction[0] && custmVal.customeraction[0].fk_master_module_id,
                            "module_display_name": val.module_display_name,
                            "customer_module_icon": val.module_icon,
                            "menu_cstatus": val.menu_cstatus,
                            "module_priority":val.module_priority,
                            "masteractions": {
                                "status": custmVal && custmVal.customeraction && custmVal.customeraction[0] && custmVal.customeraction[0].status,
                                "action_url_name": custmVal && custmVal.action_url_name,
                                "action_display_name": custmVal && custmVal.customeraction && custmVal.customeraction[0] && custmVal.customeraction[0].action_display_name,
                                "fk_master_action_id": custmVal && custmVal.customeraction && custmVal.customeraction[0] && custmVal.customeraction[0].fk_master_action_id,
                                "fk_master_module_id": custmVal && custmVal.customeraction && custmVal.customeraction[0] && custmVal.customeraction[0].fk_master_module_id,
                                "action_url_name": custmVal && custmVal.action_url_name,
                                "menu_status": custmVal && custmVal.menu_status,
                            }
                        }])

                    })
                })
                setLoading(false)

            } else {
                setLoading(false)
            }
        });
    }

    const customer_option = customerList?.map(val => ({
        value: val._id,
        label: val.company_name
    }));

    useEffect(() => {
        dispatch(customerListTodo());
    }, [bool]);



    // Now you can use values.customer_name from initialValues
    const initialValues = {
        customer_name: companmyId,
        customer_modules: checkedItems, // You can directly assign defaultValues here
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: CustomerSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const mergedData = [];
            checkedItems.forEach(item => {
                console.log("item", item)

                const moduleId = item.fk_master_module_id;
                const moduleIndex = mergedData.findIndex(module => module.fk_master_module_id === moduleId);
                if (moduleIndex === -1) {
                    mergedData.push({
                        "fk_master_module_id": moduleId,
                        "module_display_name": item.module_display_name,
                        "customer_module_icon": item.customer_module_icon,
                        "menu_cstatus": item.menu_cstatus,
                        "module_priority":item.module_priority,
                        "masteractions": [{
                            "action_url_name": item.masteractions.action_url_name,
                            "action_display_name": item.masteractions.action_display_name,
                            "fk_master_action_id": item.masteractions.fk_master_action_id,
                            "fk_master_module_id": item.masteractions.fk_master_module_id,
                            "action_url_name": item.masteractions.action_url_name,
                            "menu_status": item.masteractions.menu_status,
                        }]
                    });
                } else {
                    mergedData[moduleIndex].masteractions.push({
                        "action_url_name": item.masteractions.action_url_name,
                        "action_display_name": item.masteractions.action_display_name,
                        "fk_master_action_id": item.masteractions.fk_master_action_id,
                        "fk_master_module_id": item.masteractions.fk_master_module_id,
                        "action_url_name": item.masteractions.action_url_name,
                        "menu_status": item.masteractions.menu_status,
                    });
                }
            });

            // Now mergedData contains the desired format

            dispatch(addCustomerPermissionTodo({ "company_id": companmyId, "data": mergedData })).then(() => permissionResFun())
            setLoading(true)
        }
    });

    const permissionResFun = () => {
        if (addCustomerPermissionRes && addCustomerPermissionRes.status) {
            setBool(!bool)
            toast.success(addCustomerPermissionRes && addCustomerPermissionRes.message, { position: "bottom-right" })
            setLoading(false)

        } else {
            toast.error(addCustomerPermissionRes && addCustomerPermissionRes.message, { position: "bottom-right" })
            setLoading(false)
        }
    }

    // ------------------------- PARENTCHECKBOX -------------------------

    const handleParentCheckboxChange = (parentData) => {
        var parentId = document.getElementById(parentData._id).checked

        if (parentId) {
            for (var i = 0, len = checkedItems.length; i < len; i++) {

                if (checkedItems[i].fk_master_module_id == parentData._id) {
                    setCheckedItems((current) =>
                        current.filter((val) => val.fk_master_module_id !== parentData._id)
                    )
                }
            }
            parentData.masteractions.map((val) => {
                setCheckedItems((prev) => [...prev, {
                    "fk_master_module_id": parentData._id,
                    "module_display_name": parentData.module_display_name,
                    "customer_module_icon": parentData.module_icon,
                    "menu_cstatus": parentData.menu_cstatus,
                    "module_priority": parentData.module_priority,
                    "masteractions": {
                        "action_url_name": val && val.action_url_name,
                        "action_display_name": val && val.action_display_name,
                        "fk_master_action_id": val && val._id,
                        "fk_master_module_id": val && val.fk_master_module_id,
                        "action_url_name": val && val.action_url_name,
                        "menu_status": val && val.menu_status,
                    }
                }])

            })

        } else {
            for (var i = 0, len = checkedItems.length; i < len; i++) {

                if (checkedItems[i].fk_master_module_id == parentData._id) {
                    setCheckedItems((current) =>
                        current.filter((val) => val.fk_master_module_id !== parentData._id)
                    )
                }
            }
        }
    }

    // ------------------------- CHILDCHECKBOXC --------------------------

    const handleChildCheckboxChange = (childData) => {
        setCheckedItems((prev) => [...prev, childData])

        for (var i = 0, len = checkedItems.length; i < len; i++) {

            if (checkedItems[i].masteractions.fk_master_action_id == childData.masteractions.fk_master_action_id) {
                setCheckedItems((current) =>
                    current.filter((val) => val.masteractions.fk_master_action_id !== childData.masteractions.fk_master_action_id)
                )
            }
        }
    }
    return (
        <>

            <Form onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>

                    <div className='row'>
                        <div className='col-md-12'>
                            <SingleSelect
                                closeMenu={true}
                                label_name='Customer Name'
                                disabled={false}
                                option={customer_option || []}
                                name='customer_name'
                                defaultValue={''}
                                onChange={e => {
                                    // console.log("dasdfas",e)
                                    setFieldValue('customer_name', e.value);
                                    setCompanmyId(e.value)
                                    // setCheckedItems([])
                                    permissionFunction(e.value)
                                    setLoading(true)
                                }}
                                onBlur={handleBlur}
                                error={errors.customer_name && touched.customer_name ? (<span className='text-danger form_label' >{errors.customer_name}</span>) : null}
                            />
                        </div>
                        <div>
                            {/* <div className=''>
                                    <Form.Check
                                        type='checkbox'
                                        id='custom-checkbox'
                                        label='Select All'
                                        className='head_check'
                                        checked={selectAll}
                                        onChange={toggleSelectAll}
                                    />
                                </div> */}

                            <div className='row mt-2'>
                                {loading ?
                                    <div className='d-flex justify-content-center p-5'>
                                        <Loader />
                                    </div>
                                    : CustomerModuleList?.map((val, insdex) => (
                                        <div className='col-md-4 mt-2' key={val._id}>
                                            <div className='card permission_card h-100'>
                                                <div className='card-header permission_card_head'>
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={val._id}
                                                        label={val.module_display_name}
                                                        className='menu_check'
                                                        checked={checkedItems.some(checkval =>
                                                            checkval.fk_master_module_id == val._id
                                                        )}
                                                        onChange={() => handleParentCheckboxChange(val)}
                                                    />
                                                </div>
                                                <div className='card-body permission_card_body'>
                                                    {val.masteractions.map((child_val, index) => (
                                                        <Form.Check
                                                            key={child_val._id}
                                                            type='checkbox'
                                                            id={child_val._id}
                                                            label={child_val.action_display_name}
                                                            className='sub_menu_check'
                                                            checked={checkedItems.some(val => val && val.masteractions.fk_master_action_id == child_val._id)}
                                                            onChange={() => handleChildCheckboxChange({
                                                                "fk_master_module_id": val._id,
                                                                "module_display_name": val && val.module_display_name,
                                                                "customer_module_icon": val.module_icon,
                                                                "menu_cstatus": val.menu_cstatus,
                                                                "module_priority": val.module_priority,
                                                                "masteractions": {
                                                                    "status": "Active",
                                                                    "action_url_name": child_val && child_val.action_url_name,
                                                                    "action_display_name": child_val && child_val.action_display_name,
                                                                    "fk_master_action_id": child_val && child_val._id,
                                                                    "fk_master_module_id": val._id,
                                                                    "action_url_name": child_val && child_val.action_url_name,
                                                                    "menu_status": child_val && child_val.menu_status,
                                                                }
                                                            })}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className='text-end mt-3 mb-3'>
                            <SaveButton name={'SAVE'} />
                        </div>
                    </div>

                </div>
            </Form>
            <ToastContainer />
        </>
    );
}

















