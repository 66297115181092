import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var GroupListRes;
export const GroupListTodo = createAsyncThunk('GroupList', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'group_list',
            headers: { "Content-Type": "application/json" },
         data:{
            "company_id": localStorage.getItem('customerId') 
         }
        })
        return GroupListRes = res.data

    } catch (error) {
        return GroupListRes = error.response.data
    }
});

const GroupListSlice = createSlice({
    name: 'GroupList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(GroupListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(GroupListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default GroupListSlice.reducer; 