import * as Yup from "yup";

export const StaffSchema = Yup.object({

    name: Yup.string().required("Enter Name"),
    // user_type: Yup.string().required("Select user_type"),
    department: Yup.string().required("Select Department"),
    designation: Yup.string().required("Select Designation"),
    type: Yup.string().transform(value => (typeof value === 'object' && value !== null ? value.value : value)).required("Select Type"),
    status: Yup.string().required("Select Status"),
    email: Yup.string().required(" Enter Email"),
    password: Yup.string().required(" Enter  Password"),
    // bio: Yup.string().required("Enter Bio"),
    confirm_psw: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    // profileImg: Yup.mixed().required('Image is required'),

});
export const UpdateStaffSchema = Yup.object({

    name: Yup.string().required("Enter Name"),
    email: Yup.string().required(" Enter Email"),
    // bio: Yup.string().required("Enter Bio"),
    // type: Yup.string().required("Select Type"),
    department: Yup.string().required("Select Department"),
    designation: Yup.string().required("Select Designation"),
    status: Yup.string().required("Select Status"), 
});