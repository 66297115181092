import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CompleteTaskRes;
export const CompleteTaskTodo = createAsyncThunk('CompleteTask', async (data) => {
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'complete_task',
            data: {
                
                "task_id": data?.todo_id,
                "status": data?.status,
                "remarks": data?.remark,
                "added_by":localStorage.getItem('id'),
                "company_id": localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return CompleteTaskRes = res.data
        
    } catch (error) {
        return CompleteTaskRes = error.response.data
    }
})

