import * as Yup from "yup";

export const AddModuleSchema = Yup.object({

    name: Yup.string().required("Enter Menu Name"),
    description: Yup.string().required("Enter Menu Description"),
  module_url: Yup.string().required("Enter Module URL"),
    priority: Yup.number().positive("Priority value can't be less than 0").integer("Number Should be Integer").required('Priority number is required'),
    show_in_menu: Yup.string().required("Select Menu Status"),
    status: Yup.string().required("Select Status"),
    icon: Yup.string().required("Enter Icon Name"),

});