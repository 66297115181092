import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DealerListRes;
export const DealerListTodo = createAsyncThunk('DealerList', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'dealer_list',
            headers: { "Content-Type": "application/json" },
            // data: {
            //     "company_id": null
            // }
        })
        return DealerListRes = res.data

    } catch (error) {
        return DealerListRes = error.response.data
    }
});

const DealerListSlice = createSlice({
    name: 'DealerList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(DealerListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(DealerListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default DealerListSlice.reducer; 