import React, { useState } from 'react'
import AddAssignPlanForm from '../../../component/form/super-admin-form/assign-plan-form/AddAssignPlan'
import AssignPlanList from './AssignPlanList'

export default function AddAssignPlan() {
  const [bool, setBool] = useState(false)
    return (
        <>
           <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Assign Plan</h3>
                        </div>
                        <AddAssignPlanForm bool={bool} setBool={setBool} />
                        <div className='mt-2'>
                        <div className='layout_head'>
                            <h3>Assign  Plan List</h3>
                        </div>
                        <AssignPlanList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
   </>
  )
}
