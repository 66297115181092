import React from 'react'
import AdminUpdateTypeForm from '../../../../component/form/admin-form/admin-master-form/admin-type-form/AdminUpdateTypeForm'

export default function AdminUpdateUserType() {
    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Update User Type</h3>
                        </div>
                        <AdminUpdateTypeForm  />
                        
                    </div>
                </div>
            </div>
        </>
    )
}
