import React,{useState} from 'react'
import AdminAddDepartmentForm from '../../../../component/form/admin-form/admin-master-form/admin-department-form/AdminAddDepartmentForm'
import AdminDepartmentList from './AdminDepartmentList'

export default function AdminAddDepartment() {
    const [bool, setBool] = useState(false)

    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Department</h3>
                        </div>
                        <AdminAddDepartmentForm bool={bool} setBool={setBool} />
                        <div className='mt-2'>
                        <div className='layout_head'>
                            <h3> Department List</h3>
                        </div>
                        <AdminDepartmentList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
