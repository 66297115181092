import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminDeleteStaffRes;
export const adminDeleteStaffTodo = createAsyncThunk('adminDeleteStaff', async (id) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'delete_staff',
            data: {
                "id": id,
                "company_id": localStorage.getItem('customerId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminDeleteStaffRes = res.data

    } catch (error) {
        return adminDeleteStaffRes = error.response.data
    }
})