import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var logInRes;
export const logInTodo = createAsyncThunk('logIn', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_login',
            data: {
                "email": data.email,
                "password": data.password,
                "deviceType": "web"
            },
            headers: { "Content-Type": "application/json" }
        })
        return logInRes = res.data

    } catch (error) {
        return logInRes = error.response.data
    }
})
