import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ReelsListRes;
export const ReelsListTodo = createAsyncThunk('ReelsList', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'reels_details',
            headers: { "Content-Type": "application/json" },
            data: {

                "company_id": localStorage.getItem('customerId'),
                "user_id": data && data ? data && data.user ? data && data.user : "" : '',
                "date":data && data ? data && data.date ? data && data.date : "" : '',
                "page":data && data ? data && data.pageSize ? data && data.pageSize : "" : '',
                "pagelimit": data && data ? data && data.pageLimit ? data && data.pageLimit : "" : '',
                "title":data && data ? data && data.title ? data && data.title : "" : '',

            }
        })
        return ReelsListRes = res.data

    } catch (error) {
        return ReelsListRes = error.response.data
    }
});

const ReelsListSlice = createSlice({
    name: 'ReelsList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ReelsListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(ReelsListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ReelsListSlice.reducer; 