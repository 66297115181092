import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import LoginButton from '../../../buttons/LoginButton'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { superAdminSchema } from './validation';
import { superAdminLoginRes, superAdminLoginTodo } from '../../../../redux/slices/super-admin-slices/loginslice/SuperAdminLogin';

export default function SuperAdminLoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [spiner, setSpiner] = useState(false)
  const location = useLocation()
  console.log(location)

  const initiaValues = {
    email: '',
    password: ''
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initiaValues,
    validationSchema: superAdminSchema,

    onSubmit: (values, action) => {
      // console.log(values)
      setSpiner(true)
      dispatch(superAdminLoginTodo(values)).then(() => superAdminLogin_res())
    }
  })

  let routeURL = [
    { "action_display_name": "Dashboard", "action_url_name": "/superadmin/dashboard" },
    { "action_display_name": "Add Designation", "action_url_name": "/superadmin/designation" },
    { "action_display_name": "Update Designation", "action_url_name": "/superadmin/update-designation" },
    { "action_display_name": "Delete Designation", "action_url_name": "/delete-designation" },
    { "action_display_name": "Add Department", "action_url_name": "/superadmin/department" },
    { "action_display_name": "Update Department", "action_url_name": "/superadmin/update-department" },
    { "action_display_name": "Delete Department", "action_url_name": "/delete-department" },
    { "action_display_name": "Add User Type", "action_url_name": "/superadmin/user-type" },
    { "action_display_name": "Update User Type", "action_url_name": "/superadmin/update-usertype" },
    { "action_display_name": "Delete User Type", "action_url_name": "/delete-user-type" },
    { "action_display_name": "Add Staff", "action_url_name": "/superadmin/staff" },
    { "action_display_name": "Update Staff", "action_url_name": "/superadmin/update-staff" },
    { "action_display_name": "Delete Staff", "action_url_name": "/delete-staff" },
    { "action_display_name": "Add Customer", "action_url_name": "/superadmin/add-customer" },
    { "action_display_name": "Update Customer", "action_url_name": "/superadmin/update-customer" },
    { "action_display_name": "Customer List", "action_url_name": "/superadmin/customer-list" },
    { "action_display_name": "Add Module", "action_url_name": "/superadmin/add-module" },
    { "action_display_name": "Update Module", "action_url_name": "/superadmin/update-module" },
    { "action_display_name": "Module List", "action_url_name": "/superadmin/module-list" },
    { "action_display_name": "Add Action", "action_url_name": "/superadmin/add-action" },
    { "action_display_name": "Update Action", "action_url_name": "/superadmin/update-action" },
    { "action_display_name": "Action List", "action_url_name": "/superadmin/action-list" },
    { "action_display_name": "Customer Permission", "action_url_name": "/superadmin/customer-permission" },
    { "action_display_name": "User Permission", "action_url_name": "/superadmin/user-permission" },
    { "action_display_name": "Add Dealer", "action_url_name": "/superadmin/add-dealer" },
    { "action_display_name": "Update Dealer", "action_url_name": "/superadmin/update-dealer" },
    { "action_display_name": "Dealer List", "action_url_name": "/superadmin/dealer-list" },
    { "action_display_name": "Add Plan", "action_url_name": "/superadmin/plan" },
    { "action_display_name": "Update Plan", "action_url_name": "/superadmin/update-plan" },
    { "action_display_name": "Add Assign Plan", "action_url_name": "/superadmin/assign-plan" },
    { "action_display_name": "Update Assign Plan", "action_url_name": "/superadmin/update-assign-plan" },
  ]

  let dealerRouteURL =[
    { "action_display_name": "Add Customer", "action_url_name": "/superadmin/add-customer" },
    { "action_display_name": "Update Customer", "action_url_name": "/superadmin/update-customer" },
    { "action_display_name": "Customer List", "action_url_name": "/superadmin/customer-list" },
    { "action_display_name": "Add Assign Plan", "action_url_name": "/superadmin/assign-plan" },
    { "action_display_name": "Update Assign Plan", "action_url_name": "/superadmin/update-assign-plan" },
  ]

  const superAdminLogin_res = () => {
    if (superAdminLoginRes && superAdminLoginRes.status) {
      toast.success(superAdminLoginRes && superAdminLoginRes.message, { position: "bottom-right" });
      localStorage.setItem('id', superAdminLoginRes.data.id)
      localStorage.setItem('name', superAdminLoginRes.data.username)
      localStorage.setItem('userRole',superAdminLoginRes.data.is_super_admin === '1' ? 'superadmin': 'dealer')
      // localStorage.setItem('userRoleByID',superAdminLoginRes.data.is_super_admin)
      // localStorage.setItem('customerId', superAdminLoginRes.data.fk_master_comp_id)
      localStorage.setItem("actions", superAdminLoginRes.data.is_super_admin === '1' ? JSON.stringify(routeURL) : JSON.stringify(dealerRouteURL))
      navigate(superAdminLoginRes.data.is_super_admin === '1' ? '/superadmin/dashboard' : '/superadmin/customer-list')
    } else {
      toast.error(superAdminLoginRes && superAdminLoginRes.message, { position: "bottom-right" });
      setSpiner(false)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>

        <div className="input-group  input_section" style={{ position: 'relative' }}>
          <span className='input_icon'> <AiOutlineMail className='mb-1 me-2 ' style={{ color: "#ff2b49" }} size={20} /></span>
          <input type="text" className="form-control form-login-input login_placeholder "
            placeholder="Username"
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

        </div>
        {errors.email && touched.email ? (<span className='text-danger ' >{errors.email}</span>) : null}
        <div className="input-group mt-3 input_section" style={{ position: 'relative' }}>
          <span className='input_icon'> <RiLockPasswordLine style={{ color: "#ff2b49" }} className='mb-1 me-2' size={20} /></span>
          <input type="password" className="form-control form-login-input "
            placeholder="Password"
            name='password'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />

        </div>
        {errors.password && touched.password ? (<span className='text-danger text-start' >{errors.password}</span>) : null}

        <div className='mt-4 d-flex justify-content-center'>
          <LoginButton name={'Login'} spiner={spiner} />
        </div>
      </Form>
      <ToastContainer />
    </>
  )
}
