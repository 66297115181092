import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import SaveButton from '../../../component/buttons/SaveButton';
import TextArea from '../../../component/input_filed/TextArea';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { AddMeetingMinutRes, AddMeetingMinutTodo } from '../../../redux/slices/admin-slices/meeting/AddMeetingMinut';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { AddMinutesSchema, UpdateMinutesSchema } from './Validation';
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList';
import Flatpickr from 'react-flatpickr'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import "flatpickr/dist/themes/light.css"
import moment from 'moment';
import DatePicker from '../../../component/input_filed/DatePicker';
import Loader from '../../../component/loader/Loader';
import { UpdateMomRes, UpdateMomTodo } from '../../../redux/slices/admin-slices/meeting/UpdateMom';
import TimePicker from '../../../component/input_filed/TimePicker';

export default function MOMView(props) {
    const { view, setView, data, currentDate, bool, setBool, momData, momLoading, setMomLoading } = props;
    const handleClose = () => setView(false);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const userList = useSelector(state => state?.UserList?.data?.data)
    // console.log("view", view)

    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    // const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
    const formattedTime24hr = `${hours}:${minutes.toString().padStart(2, '0')}`;
    // var momUser = momData?.subtopics

    var UserOpt = data?.attendends?.map(val => (
        { "value": val?.user?.[0]?._id, "label": val?.user?.[0]?.name }
    ))
    const userId = localStorage.getItem('id')
    const approve_opt = [
        { "value": 'yes', "label": 'Yes' },
        { "value": 'no', "label": "No" }
    ]

    var content_data = []
    setTimeout(() => {
        setLoading(false)
    }, 1600)


    useEffect(() => {
        if (view) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [view]);

    const cont_data = momData?.subtopics?.map((value) => content_data.push({

        subtopic_id: value?._id,
        subtopic_desc: value?.subtopic_desc,
        user_id: [{ "value": value?.user_details?._id, "label": value?.user_details?.name }],
        deadline: value?.todos?.deadline,
        time: value?.todos?.time,
        status: value?.status,
        approved_required: value?.todos?.approved_required,
        task_status: value?.todos?.status
    }))

    const initialValues = {
        id: momData?._id,
        meetingId: data?._id,
        title: momData?.topic,
        data: content_data

    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: UpdateMinutesSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            const transformedValues = {
                ...values,
                data: values.data.map(item => ({
                    ...item,
                    user_id: item?.user_id.map(user => user?.value)
                }))
            };
            dispatch(UpdateMomTodo(transformedValues)).then(() => add_res(action))
        }
    });

    console.log(formik.values)

    const add_res = (action) => {
        if (UpdateMomRes?.status) {
            toast.success(UpdateMomRes?.message, { position: 'bottom-right' })
            setBool(!bool)
            setView(false);
        } else {
            toast.error(UpdateMomRes?.message, { position: 'bottom-right' })
        }
    }

    return (
        <>
            <Modal show={view} onHide={handleClose} size="xl" aria-labelledby="example-modal-sizes-title-xl">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Update Meeting Minutes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        <div className='d-flex justify-content-center mt-5 mb-5'>
                            <Loader />
                        </div>
                        :
                        <FormikProvider value={formik}>
                            <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                                <div className="row mb-3">
                                    <div className="col-md-10">
                                        <Form.Group className="mb-3" controlId="text">
                                            <Form.Label className='form_label mb-0'>Topic</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className='form_input'
                                                name='title'
                                                disabled={data?.added_by === userId ? false : true}
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.title && formik.touched.title ? (<span className='text-danger form_label' >{formik.errors.title}</span>) : null}

                                        </Form.Group>
                                    </div>
                                </div>

                                <FieldArray
                                    name="data"
                                    render={(arrayHelpers) => (
                                        <>
                                            <div className='p-0' style={{ height: '55vh' }}>
                                                <PerfectScrollbar >
                                                    <div className='ps-3 pe-4'>
                                                        {formik && formik.values && formik.values.data && formik.values.data.map((field, index) => (
                                                            // console.log("field",field)
                                                            formik.values.data[index].status == 'Active' ?
                                                                <div className="row " key={index}>
                                                                    <div className='col-md-4 '>
                                                                        <Form.Group controlId="text">
                                                                            <Form.Label className='form_label mb-0'>Sub Topic</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                className='form_input'
                                                                                disabled={data?.added_by !== userId || formik.values.data[index].task_status === 'completed' || formik.values.data[index].task_status === 'cancelled' ? true : false}
                                                                                name={`data[${index}].subtopic_desc`}
                                                                                value={formik.values.data[index].subtopic_desc}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </Form.Group>
                                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].subtopic_desc && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].subtopic_desc ?
                                                                            (<span className='text-danger form_label'> {formik.errors.data[index].subtopic_desc}</span>) : null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4 ">
                                                                        <Form.Group className="" controlId="text">
                                                                            <Form.Label className='form_label mb-0'>User</Form.Label>
                                                                            <Select
                                                                                closeMenu={true}
                                                                                label_name=''
                                                                                isDisabled={formik.values.data[index].subtopic_id ? true : false}
                                                                                options={UserOpt || []}
                                                                                name={`data[${index}].user_id`}
                                                                                className='react_select'
                                                                                classNamePrefix="select"
                                                                                defaultValue={formik.values.data[index].user_id}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`data[${index}].user_id`, e);
                                                                                }
                                                                                }
                                                                                isMulti
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </Form.Group>
                                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].user_id && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].user_id ?
                                                                            (<span className='text-danger form_label'> {formik.errors.data[index].user_id}</span>) : null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4 ">
                                                                        <Form.Group className="" controlId="text">
                                                                            <Form.Label className='form_label mb-0'>Closure Approval Required</Form.Label>
                                                                            <Select
                                                                                closeMenu={true}
                                                                                label_name=''
                                                                                isDisabled={data?.added_by !== userId || formik.values.data[index].task_status === 'completed' || formik.values.data[index].task_status === 'cancelled' ? true : false}
                                                                                options={approve_opt || []}
                                                                                name={`data[${index}].approved_required`}
                                                                                className='react_select'
                                                                                classNamePrefix="select"
                                                                                defaultValue={approve_opt && approve_opt.find((option) => option.value === formik.values.data[index].approved_required)}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`data[${index}].approved_required`, e.value);
                                                                                }
                                                                                }
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </Form.Group>
                                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].approved_required && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].approved_required ?
                                                                            (<span className='text-danger form_label'> {formik.errors.data[index].approved_required}</span>) : null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4 ">
                                                                        <Form.Group className="" controlId="text">
                                                                            <Form.Label className='form_label mb-0'>Deadline</Form.Label>
                                                                            <DatePicker
                                                                                label_name=''
                                                                                placeholder={'Choose Date'}
                                                                                disabled={data?.added_by !== userId || formik.values.data[index].task_status === 'completed' || formik.values.data[index].task_status === 'cancelled' ? true : false}
                                                                                name={`data[${index}].deadline`}
                                                                                option={{
                                                                                    altInput: true,
                                                                                    altFormat: "F j, Y",
                                                                                    dateFormat: 'Y-m-d',
                                                                                    minDate: new Date().toISOString().split("T")[0],
                                                                                    maxDate: '',
                                                                                    disable: [],
                                                                                    mode: "single",
                                                                                    defaultDate: [],
                                                                                    conjunction: "",
                                                                                    static: true
                                                                                }}
                                                                                value={formik.values.data[index].deadline || ''}
                                                                                onChange={(e) =>
                                                                                {
                                                                                    formik.setFieldValue(`data[${index}].deadline`, moment(e[0]).format("YYYY-MM-DD"))
                                                                                    formik.setFieldValue(`data[${index}].time`,'')
                                                                                }
                                                                                }
                                                                                onBlur={formik.handleBlur}
                                                                                error={''}
                                                                            />
                                                                            {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].deadline && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].deadline ?
                                                                                (<span className='text-danger form_label'> {formik.errors.data[index].deadline}</span>) : null
                                                                            }
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-4 ">
                                                                        <Form.Group className="" controlId="text">
                                                                            <Form.Label className='form_label mb-0'>Time</Form.Label>
                                                                            <TimePicker
                                                                                label_name=''
                                                                                placeholder={'Choose Time'}
                                                                                disabled={data?.added_by !== userId ||  formik.values.data[index].task_status === 'completed' || formik.values.data[index].task_status === 'cancelled'   ? true : false}
                                                                                name={`data[${index}].time`}
                                                                                option={{
                                                                                    enableTime: true,
                                                                                    noCalendar: true,
                                                                                    dateFormat: 'G:i K', // Customize date format as needed
                                                                                    time_24hr: false,
                                                                                    minTime:  '', // Before dates disabled
                                                                                    maxTime: '', // After dates disabled
                                                                                    disable: [], // Disable particular date ranges
                                                                                    defaultDate: [formik.values.data[index].time],
                                                                                    static: true

                                                                                }}
                                                                                onChange={(e) =>
                                                                                    formik.setFieldValue(`data[${index}].time`, moment(e[0]).format('hh:mm A'))
                                                                                }
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.data[index].time || ''}
                                                                                error={''}
                                                                            />
                                                                            {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].time && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].time ?
                                                                                (<span className='text-danger form_label'> {formik.errors.data[index].time}</span>) : null
                                                                            }
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='col-md-2 p-1'>
                                                                        <Form.Group className="" controlId="text">
                                                                            <Form.Label className='form_label mb-0 ms-1 '>Status</Form.Label>
                                                                            <div className='mt-1'>

                                                                                {/* <span>{formik.values.data[index].task_status}</span> */}
                                                                                {new Date(currentDate).toLocaleDateString('en-GB') > new Date(formik.values.data[index]?.deadline).toLocaleDateString('en-GB') &&
                                                                                    formik.values.data[index].task_status === 'Active' ? <h6 className='badge font-size mb-0 ms-1' style={{ backgroundColor: 'orangered', }}>OVERDUE</h6>
                                                                                    :
                                                                                    formik.values.data[index].task_status === 'completed' ? <h6 className='badge font-size mb-0 ms-1 text-bg-success' >COMPLETED</h6> :
                                                                                        formik.values.data[index].task_status === 'cancelled' ? <h6 className='badge font-size ms-1 mb-0' style={{ backgroundColor: '#f16c4d', }}>CANCELLED</h6> :
                                                                                            formik.values.data[index].task_status === 'pending' ? <h6 className='badge font-size mb-0 ms-1 text-bg-danger'>PENDING</h6> :
                                                                                                formik.values.data[index].task_status === 'On Approval' ? <h6 className='badge font-size  mb-0 ms-1  text-bg-warning'>On Approval</h6> :
                                                                                                    formik.values.data[index].task_status === 'rejected' ? <h6 className='badge font-size mb-0 ms-1 text-bg-danger'>Rejected</h6> :
                                                                                                        formik.values.data[index].task_status === 'Active' ? <h6 className='badge font-size mb-0 ms-1 text-bg-success'>Active</h6> :
                                                                                                            ""}
                                                                            </div>
                                                                        </Form.Group>

                                                                        {/* {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].subtopic_desc && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].subtopic_desc ?
                                                                (<span className='text-danger form_label'> {formik.errors.data[index].subtopic_desc}</span>) : null
                                                            } */}
                                                                    </div>
                                                                    <div className={index == 0 ? 'col-md-2  mt-4 ' : 'col-md-2  mt-4'}>
                                                                        <div className='d-flex justify-content-end'>
                                                                            <button type='button'
                                                                                disabled={data?.added_by === userId ? false : true} className='btn add_btn2 me-2' onClick={() => arrayHelpers.push({ subtopic_desc: "", user_id: '', deadline: "", time: '', status: "Active", subtopic_id: '', approved_required: '' })}>
                                                                                <FaPlus size={18} />
                                                                            </button>

                                                                            {formik?.values?.data?.length == 1 ? "" : <button type='button' disabled={data?.added_by !== userId || formik.values.data[index].task_status === 'completed' || formik.values.data[index].task_status === 'cancelled' ? true : false} className='btn del_btn' onClick={() => { formik.values.data[index].subtopic_id === '' ? arrayHelpers.remove(index) : formik.setFieldValue(`data[${index}].status`, 'Deleted') }}>
                                                                                <FaTrash size={18} />
                                                                            </button>}



                                                                        </div>
                                                                    </div>
                                                                    <hr className='mt-2' />
                                                                </div>
                                                                : ''
                                                        ))}
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </>
                                    )}
                                />
                                {data?.added_by === userId ? <div className='col-md-12 text-end mt-2'>
                                    <SaveButton name={'update'} />
                                </div> : ''}
                            </form>
                        </FormikProvider>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
