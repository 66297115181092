import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addModuleRes;
export const addModuleTodo = createAsyncThunk('addModule', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_mastermodule',
            data: {
    
                "module_display_name": data.name,
                "module_url": data.module_url,
                "module_description": data.description,
                "module_priority":data.priority,
                "status": data.status,
                "menu_cstatus": data.show_in_menu,
                "added_by": localStorage.getItem('id'),
                "module_icon":data.icon
            },
            headers: { "Content-Type": "application/json" }
        })
        return addModuleRes = res.data
        
    } catch (error) {
        return addModuleRes = error.response.data
    }
})