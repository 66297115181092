import React from 'react'
import UpdateGroupForm from '../../../../component/form/admin-form/admin-master-form/admin-group-form/UpdateGroup'

export default function UpdateGroup() {
    return (
        <>
         <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Group</h3>
                        </div>
                        <UpdateGroupForm/>
                        
                    </div>
                </div>
        </>
    )
}
