import React, { useState } from 'react'
import AddDesignationForm from '../../../../component/form/super-admin-form/master/designation-form/AddDesignationForm'
import DesignationList from './DesignationList'

export default function AddDesignation() {
    const [bool, setBool] = useState(false)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add Designation</h3>
                            <AddDesignationForm bool={bool} setBool={setBool} />
                        </div>
                        <div className='mt-2'>
                            <div className='layout_head'>
                                <h3> Designation List</h3>
                                <DesignationList bool={bool} setBool={setBool} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
