import React, { useState } from 'react'
import AdminAddStaffForm from '../../../../component/form/admin-form/admin-master-form/admin-staff-form/AdminAddStaffForm'
import AdminStaffList from './AdminStaffList'

export default function AdminAddStaff() {
    const [bool,setBool] = useState(false)
    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Add User</h3>
                            <AdminAddStaffForm bool={bool} setBool={setBool} />
                        </div>
                        {/* <div className='layout_head mt-3'>
                            <h3> User List</h3>
                            <AdminStaffList bool={bool} setBool={setBool} />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
