import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var TodoDetailsRes;
export const TodoDetailsTodo = createAsyncThunk('TodoDetails', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'todo_details_byid',
            headers: { "Content-Type": "application/json" },
            data: {
                "id":id,
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return TodoDetailsRes = res.data

    } catch (error) {
        return TodoDetailsRes = error.response.data
    }
});

const TodoDetailsSlice = createSlice({
    name: 'TodoDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(TodoDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(TodoDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default TodoDetailsSlice.reducer; 