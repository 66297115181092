import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addStaffRes;
export const addStaffTodo = createAsyncThunk('addStaff', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_add_staff',
            data: {
                "name": data.name,
                "phone_no": data.mobile_num,
                "email": data.email,
                "designation": data.designation,
                "department": data.department,
                "usertype":'',
                "password": data.password,
                "status": data.status,
                "added_by": localStorage.getItem('id'),
                "company_id": null
            },
            headers: { "Content-Type": "application/json" }
        })
        return addStaffRes = res.data

    } catch (error) {
        return addStaffRes = error.response.data
    }
})


export var updateStaffRes;
export const updateStaffTodo = createAsyncThunk('updateStaff', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_updated_staff',
            data: {
                "id": data.id,
                "name": data.name,
                "phone_no": data.mobile_num,
                "email": data.email,
                "designation": data.designation,
                "department": data.department,
                "usertype": data.user_type,
                "status": data.status,
                "modified_by": localStorage.getItem('id'),
                "company_id": null
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateStaffRes = res.data

    } catch (error) {
        return updateStaffRes = error.response.data
    }
})