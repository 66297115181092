import React from 'react'
import { Button } from 'react-bootstrap'

export default function AddButton(props) {
    return (
        <>
            <Button type='button' variant="" className='add_btn ps-3 pe-3'>{props.name}</Button>
        </>
    )
}
