import React from 'react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import AdminNavbar from './layout/navbar/AdminNavbar';
import Swal from 'sweetalert2'
import SuperAdminNavbar from './layout/navbar/SuperAdminNavbar';


const useAuth = () => {
    let user = localStorage.getItem('id');
    // console.log(user)
    if (user) {
        try {
            if ((user)) {
                return user;
            }
        } catch (error) {
            return false
        }

    } else {
        return false
    }
}

const ProtectedRoutes = (props) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    var permission = JSON.parse(localStorage.getItem('actions'))

    const isRouteAuthorized = permission && permission.some(
        (item) => item.action_url_name === location.pathname

    );

    // console.log("location",location.pathname)
    // console.log("isRouteAuthorized",isRouteAuthorized)


    if (!auth) {
        return <Navigate to="../admin/login"/>
    }
    else if (!isRouteAuthorized) {
        Swal.fire({
            title: 'Access Denied',
            text: "You do not have permission to access this page. Please contact your Administrator to request access.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1)
            }
        })
        return null;
    }
    else {
        return (
            <div>
                {location?.pathname.slice(0, 11) == '/superadmin' ? <SuperAdminNavbar /> : <AdminNavbar />}
                <div id="content">
                    <Outlet />
                </div>
            </div>
        )
    }
}

export default ProtectedRoutes;
