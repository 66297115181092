import React from 'react'
import UpdateDealerForm from '../../../component/form/super-admin-form/dealer-form/UpdateDealer'

export default function UpdateDealer() {
    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Dealer </h3>
                        </div>
                        <UpdateDealerForm/>
                    </div>
                </div>
            </div>
        </>
    )
}
