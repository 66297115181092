import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateUserTypeRes;
export const updateUserTypeTodo = createAsyncThunk('updateUserType', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_update_usertype',
            data: {
                'id': data && data.id,
                "type": data && data.values && data.values.user_type,
                "status": data && data.values && data.values.status,
                "added_by": localStorage.getItem('id'),
                "company_id": null
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateUserTypeRes = res.data

    } catch (error) {
        return updateUserTypeRes = error.response.data
    }
})