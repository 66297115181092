import React, { useState } from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { DeleteDealerRes, DeleteDealerTodo } from '../../../../redux/slices/super-admin-slices/dealer/DeleteDealer'
import { MdLockOutline } from 'react-icons/md'
import DealerPswUpdate from '../../../../pages/super-admin-pages/dealer/DealerPswUpdate'

export default function DealerAction(props) {
    const { bool, setBool,psw,setPsw,dealer,setDealer } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const edit_customer = () => {
        navigate('../superadmin/update-dealer', { state: props.data && props.data })
    }

    const handlePsw = () => {
        setPsw(true)
        setDealer(props.data._id)
    }

    const del_customer = () => {

        Swal.fire({
            title: "Dealer Delete",
            text: "Are You Sure Want To Delete This Dealer ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteDealerTodo(props.data._id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (DeleteDealerRes && DeleteDealerRes.status) {
            setBool(!bool)
            Swal.fire(
                "Dealer",
                'Dealer Deleted Successfully',
                'success'
            )
        } else {
            toast.error(DeleteDealerRes && DeleteDealerRes.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <div>
                {/* {props.data && props.data.isActive ?
                <LuEyeOff size={20} className='eye text-secondary'
                    onClick={() => change_status('false')}
                    data-tooltip-id='deactivate'
                    data-tooltip-content="De-Activate!"
                    data-tooltip-place="bottom"
                />
                :
                <LuEye size={20} className='eye text-secondary'
                    onClick={() => change_status('true')}
                    data-tooltip-id='active'
                    data-tooltip-content="Activate!"
                    data-tooltip-place="bottom"
                />
            } */}

                <LuFileEdit size={20} className='edit text-success ms-3'
                    onClick={() => edit_customer()}
                    data-tooltip-id='edit'
                    data-tooltip-content="Edit Dealer!"
                    data-tooltip-place="bottom"
                />
                <MdLockOutline size={22} className='details text-primary ms-3'
                    onClick={() => handlePsw()}
                    data-tooltip-id='details'
                    data-tooltip-content="Password Update!"
                    data-tooltip-place="bottom"
                />
                <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_customer()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Dealer!"
                    data-tooltip-place="bottom"
                />

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
          
        </>
    )
}
