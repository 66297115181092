import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DeleteMOMRes;
export const DeleteMOMTodo = createAsyncThunk('department_delete', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'mom_deleted',
            data: {
                
                "id":data?.id,
                "meeting_id": data?.meetingId,
                "company_id": localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return DeleteMOMRes = res.data

    } catch (error) {
        return DeleteMOMRes = error.response.data
    }
})