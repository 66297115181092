import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../component/loader/Loader'
import { customerListRes, customerListTodo } from '../../../redux/slices/super-admin-slices/customer/CustomerList'
import CustomerAction from '../../../component/data-table-action/table_action/super-admin-action/CustomerAction'

export default function CustomerList() {
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const customerList = useSelector(state => state && state.CustomerList && state.CustomerList.data && state.CustomerList.data.data)

    const customerList_res = () => {
        if (customerListRes && customerListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(customerListTodo()).then(() => customerList_res())
    }, [bool])


    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: ' CUSTOMER ',
            field: 'company_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: ' ADMIN ',
            field: 'contact_person',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'email_id',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'CONTACT',
            field: 'phone_no',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'CODE',
            field: 'cust_code',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: CustomerAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
            },
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Customer List</h3>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <Loader />
                            </div>
                            :
                            customerList && customerList.length ?
                                < div >
                                    < DataTable
                                        rowData={customerList}
                                        columnDefs={columnDefs}
                                        height={'70vh'}
                                    />
                                </div>
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }

                    </div>
                </div>
            </div >
        </>
    )
}
