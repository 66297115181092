import React from 'react'
import AdminUpdateDepartmentForm from '../../../../component/form/admin-form/admin-master-form/admin-department-form/AdminUpdateDepartment'

export default function AdminUpdateDepartment() {
    return (
        <>
         <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Department</h3>
                        </div>
                        <AdminUpdateDepartmentForm  />
                        
                    </div>
                </div>
        </>
    )
}
