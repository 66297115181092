import React from 'react'
import ActionForm from '../../../component/form/super-admin-form/action-form/ActionForm'

export default function AddAction() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-md-12'>
                        <div className='layout_head'>
                            <h3>Add Action</h3>
                        </div>
                        <ActionForm />
                    </div>
                </div>
            </div>
        </>
    )
}
