import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import Loader from '../../../component/loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import DealerAction from '../../../component/data-table-action/table_action/super-admin-action/DealerTableAction'
import { DealerListRes, DealerListTodo } from '../../../redux/slices/super-admin-slices/dealer/DealerList'
import DealerPswUpdate from './DealerPswUpdate'

export default function DealerList() {
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [psw, setPsw] = useState(false)
    const [dealer, setDealer] = useState('')
    const dealerList = useSelector(state => state?.DealerList?.data?.data)
    console.log(dealerList)
    const dealerList_res = () => {
        if (DealerListRes && DealerListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(DealerListTodo()).then(() => dealerList_res())
    }, [bool])


    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },

        {
            headerName: ' DEALER ',
            field: 'username',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'email',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'CONTACT',
            field: 'phone',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: DealerAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                psw: psw,
                setPsw: setPsw,
                dealer: dealer,
                setDealer: setDealer
            },
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Dealer List</h3>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <Loader />
                            </div>
                            :
                            dealerList && dealerList.length ?
                            < div >
                                < DataTable
                                    rowData={dealerList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                />
                            </div>
                            :
                            <div className='empty_section'>
                                <span>DATA NOT FOUND</span>
                            </div>
                        }

                    </div>
                </div>
            </div >
            <DealerPswUpdate psw={psw} setPsw={setPsw} dealer={dealer} setDealer={setDealer} />
        </>
    )
}
