import React from 'react'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/light.css"
import { Form } from 'react-bootstrap'

export default function TimePicker(props) {

    const { label_name, placeholder, name, onChange,disabled, option, onBlur, error } = props

    return (
        <>
            <Form.Group className="mb-2" controlId="time">
            {label_name === '' ? '' : <Form.Label className='form_label'>{label_name}</Form.Label>}
                <Flatpickr
                    className='form-control form_input'
                    placeholder={placeholder}
                    options={option}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    onBlur={onBlur}
                />
                {error}
            </Form.Group>
        </>
    )
}
