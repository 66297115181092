import React, { useState } from 'react'
import { MdCancel } from "react-icons/md";
import { BiHide, BiShow } from "react-icons/bi";
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { DealerPswUpdateRes, DealerPswUpdateTodo } from '../../../redux/slices/super-admin-slices/dealer/DealerUpdatePsw';
import SaveButton from '../../../component/buttons/SaveButton';
import { PasswordSchema } from '../../../component/form/super-admin-form/dealer-form/Validation';

export default function DealerPswUpdate(props) {
    const { psw, setPsw,dealer } = props;
    const [newPswShow, setNewPswShow] = useState(false);
    const [confirmPswShow, setconfirmPswShow] = useState(false);
  
    const dispatch = useDispatch()

    const togglePassword = () => {
        setNewPswShow(!newPswShow);
    }
    const togglePassword1 = () => {
        setconfirmPswShow(!confirmPswShow);
    }

    const initialValues = {
        id: dealer && dealer,
        new_psw: "",
        confirm_psw: ""

    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: PasswordSchema,
        enableReinitialize:'true',
        onSubmit: (values, action) => {
            dispatch(DealerPswUpdateTodo(values)).then(() => pswChange())
            action.resetForm()
        },
    })

    const pswChange = () => {
        if (DealerPswUpdateRes && DealerPswUpdateRes.status) {
            setTimeout(() => {
                setPsw(false);
            }, 1300);
            toast.success(DealerPswUpdateRes && DealerPswUpdateRes.message, { position: 'bottom-right' })
        } else {
            toast.error(DealerPswUpdateRes && DealerPswUpdateRes.message, { position: 'bottom-right' })
        }
    }
    return (
        <>
         <div className={psw ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-md  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header  "  >
                            <h5 className='text-center ' > Password Change</h5>
                            <span  onClick={() => { setPsw(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body  p-4 '>
                            <form onSubmit={handleSubmit}>

                                <div className='mt-2 ps-3 pe-3' style={{ position: 'relative' }}>
                                    <label htmlFor="user_name" className="form-label form_label">New Password</label>
                                    {newPswShow ? <span className="psw_icon" onClick={togglePassword} ><BiShow size={20}  style={{color:"#9d9d9d"}}/></span> : <span className="psw_icon" onClick={togglePassword} ><BiHide size={20}  style={{color:"#9d9d9d"}}/></span>}
                                    <input type={newPswShow ? "text" : "password"}
                                        name='new_psw'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.new_psw}
                                        className='form-control form_input' />
                                    {errors.new_psw && touched.new_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.new_psw}</span>) : null}
                                </div>

                                <div className='mt-2 mb-4 ps-3 pe-3' style={{ position: 'relative' }}>
                                    <label htmlFor="user_name" className="form-label form_label">Conform Password</label>
                                    {confirmPswShow ? <span className="psw_icon" onClick={togglePassword1} ><BiShow size={20} style={{color:"#9d9d9d"}}/></span> : <span className="psw_icon" onClick={togglePassword1} ><BiHide size={20}  style={{color:"#9d9d9d"}}/></span>}
                                    <input type={confirmPswShow ? "text" : "password"} onChange={handleChange} onBlur={handleBlur}
                                        value={values.confirm_psw} name='confirm_psw' className='form-control form_input' />

                                    {errors.confirm_psw && touched.confirm_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.confirm_psw}</span>) : null}
                                </div>

                                <div className='mt-3 d-flex justify-content-end'>
                                <SaveButton name={'Update'} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}
