import React from 'react'
import UpdatePlanForm from '../../../component/form/super-admin-form/plan-form/UpdatePlanForm'

export default function UpdatePlan() {
    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h3>Update Plan</h3>
                        </div>
                        <UpdatePlanForm />
                    </div>
                </div>
            </div>
        </>
    )
}
