import React, { useEffect, useRef, useState } from 'react'
import DataTablePagination from '../../../component/data-table/DataTablePagination'
import { useDispatch, useSelector } from 'react-redux'
import { PostListRes, PostListTodo } from '../../../redux/slices/admin-slices/post/PostList'
import Loader from '../../../component/loader/Loader'
import PostListAction from '../../../component/data-table-action/table_action/admin-action/PostListAction'
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu'
import { Form } from 'react-bootstrap'
import SaveButton from '../../../component/buttons/SaveButton'
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList'
import { useFormik } from 'formik'
import Select from 'react-select'
import { IoRefreshOutline } from 'react-icons/io5'

export default function PostList() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const postList = useSelector(state => state && state.PostList && state.PostList.data && state.PostList.data.data && state.PostList.data.data.postData)
    const userList = useSelector(state => state && state.UserList && state.UserList.data && state.UserList.data.data)
    const TotalCount = useSelector(state => state && state.PostList && state.PostList.data && state.PostList.data.data && state.PostList.data.data.Totalpostcount)
    const [pageLimit, setPageLimit] = useState(10)
    const [pageSize, setPageSize] = useState(0)
    const [filterLoad, setFilterLoad] = useState(false)
    const [bool, setBool] = useState(false)

    const user_option = userList && userList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    const status_option = [
        { "value": 'Publish', "label": "Publish" },
        { "value": 'Unpublish', "label": "Unpublish" },
        { "value": 'Scheduled', "label": "Scheduled" }
    ]

    const paginationInc = postList && postList.length

    useEffect(() => {
        setLoading(true)
        dispatch(PostListTodo({ "pageLimit": pageLimit, "pageSize": pageSize })).then(() => postlist_res())
    }, [bool])

    useEffect(() => {
        dispatch(PostListTodo({ "pageLimit": pageLimit, "pageSize": pageSize }))
    }, [pageLimit, pageSize])


    const postlist_res = () => {
        if (PostListRes && PostListRes.status) {
            dispatch(UserListTodo()).then(() => userList_res())

        } else {
            setLoading(false)
        }
    }
    const userList_res = () => {
        if (UserListRes && UserListRes.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const initialValues = {
        user: '',
        date: "",
        status:''

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,

        onSubmit: (values, action) => {
            setFilterLoad(true)
            dispatch(PostListTodo(values)).then(() => filter_res())
        }
    })

    const filter_res = () => {
        if (PostListRes && PostListRes.status) {
            setFilterLoad(false)
        } else {
            setFilterLoad(false)
        }
    }

    const handleSearch = (data) => {
        dispatch(PostListTodo({"title":data})).then(() => filter_res())
    }

    const selectRef = useRef();
    const selectStatusRef = useRef();

    const handleReset = () => {
        selectRef && selectRef.current && selectRef.current.clearValue();
        selectStatusRef && selectStatusRef.current && selectStatusRef.current.clearValue();
        values.date = ""
        setBool(!bool)
    };

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 80
        },
        {
            headerName: ' POST TITLE ',
            field: 'post_title',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },
        // {
        //     headerName: ' DESCRIPTION ',
        //     field: 'post_description',
        //     filter: 'agTextColumnFilter',
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        // },
        {
            headerName: 'TYPE',
            field: 'post_type',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },
        {
            headerName: 'USER',
            field: 'users[0].name',
            cellRenderer: params => <span>{params && params.data && params.data.users && params.data.users[0] && params.data.users[0].name}</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },
        {
            headerName: 'DATE',
            field: 'post_date',
            filter: 'agTextColumnFilter',
            cellRenderer: params => new Date(params.data.post_date).toLocaleDateString('en-GB'),
            suppressMenu: true,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },

        {
            headerName: 'STATUS',
            field: 'post_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.post_status === 'Publish' ? <span className='badge text-bg-success'>Publish</span> : params.data.post_status === 'Unpublish' ? <span className='badge text-bg-danger'>Unpublish</span> : params.data.post_status === 'Scheduled' ? <span className='badge text-bg-warning'>Scheduled</span> : '',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },

        {
            field: 'ACTIONS',
            cellRenderer: PostListAction,
            cellRendererParams: {
            },
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    return (
        <>
            {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Post List</h3>
                        </div>
                        {loading ?
                            <div>
                                <Loader />
                            </div>
                            :
                            <div>
                                <div className=' pgn_tbl_head mb-2'>
                                    <div className='data-table-extensions-filter'>
                                        <input
                                            type="text"
                                            id="filter-text-box"
                                            placeholder="Search"
                                            // onInput={onFilterTextBoxChanged}
                                            style={{ width: '250px' }}
                                            className="form-control form_input"
                                        />
                                    </div>
                                </div>
                                <DataTablePagination
                                    rowData={postList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                />
                                <div className='text-end pgn_tbl_footer mb-2'>
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className="d-flex align-items-center">
                                            <label htmlFor="" className="form-label form_label" style={{ marginBottom: 0 }}>Page Size</label>
                                            <select className="form-select form_input ms-2" style={{ width: '12vh' }} onChange={(e) => { setPageLimit(e.target.value); }}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center ms-3 table_footer_pagination' style={{ marginRight: '15px' }}>
                                            <button type='button' disabled={pageSize == 0 ? true : false} onClick={() => { setPageSize(prev => prev - 1); }} className='btn pg_btn'><span className='me-2'><LuChevronLeft style={{ marginBottom: '2px' }} size={20} /></span></button>
                                            <p><span>{(pageSize * pageLimit) + 1}</span> - <span>{TotalCount < (pageSize + 1) * pageLimit ? TotalCount : (pageSize + 1) * pageLimit}</span> of <span>{TotalCount}</span></p>
                                            <button type='button' disabled={paginationInc < pageLimit ? true : false} onClick={() => { setPageSize(prev => prev + 1); }} className='btn pg_btn'>   <span className='ms-2'><LuChevronRight style={{ marginBottom: '2px' }} size={20} /></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                    </div>
                </div>
            </div > */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Post List</h3>
                        </div>
                        {loading ?
                            <div>
                                <Loader />
                            </div>
                            :

                            <div >
                                <div className=" row ">
                                    <div className="col-md-2">
                                        <input
                                            type="text"
                                            id="filter-text-box"
                                            placeholder="Search"
                                            onChange={(e) => handleSearch(e.target.value)}
                                            className="form-control form_input"
                                        />
                                    </div>
                                    <div className=" col-md-10 ">
                                        <form onSubmit={handleSubmit}>
                                            <div className="d-flex justify-content-end align-items-center ">
                                                <div className='ms-2'>
                                                    <Select
                                                        closeMenu={true}
                                                        ref={selectRef}
                                                        placeholder={'Select User..'}
                                                        disabled={false}
                                                        options={user_option}
                                                        name='user'
                                                        className='react_select'
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        onChange={(e) => { setFieldValue('user', e && e.value); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                <div className='ms-2'>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder='Select Date'
                                                        name='date'
                                                        className='form_input'
                                                        value={values.date || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                <div className='ms-2'>
                                                    <Select
                                                        closeMenu={true}
                                                        ref={selectStatusRef}
                                                        placeholder={'Select Status..'}
                                                        disabled={false}
                                                        options={status_option}
                                                        name='status'
                                                        className='react_select'
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        onChange={(e) => { setFieldValue('status', e && e.value); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                <div className='ms-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <SaveButton name={'Filter'} />
                                                        <span className='ms-2 refresh_btn' onClick={handleReset} ><IoRefreshOutline style={{ color: 'white' }} size={25} /></span>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {
                                    filterLoad ?
                                        <div>
                                            <Loader />
                                        </div> :
                                        postList && postList.length ?
                                            <div className='mt-3'>
                                                <DataTablePagination
                                                    rowData={postList}
                                                    columnDefs={columnDefs}
                                                    height={'65vh'}
                                                />
                                                <div className='text-end pgn_tbl_footer mb-2'>
                                                    <div className='d-flex justify-content-end align-items-center'>
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="" className="form-label form_label" style={{ marginBottom: 0 }}>Page Size</label>
                                                            <select className="form-select form_input ms-2" style={{ width: '12vh' }} onChange={(e) => { setPageLimit(e.target.value); }}>
                                                                <option value="10">10</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className='d-flex align-items-center ms-3 table_footer_pagination' style={{ marginRight: '15px' }}>
                                                            <button type='button' disabled={pageSize == 0 ? true : false} onClick={() => { setPageSize(prev => prev - 1); }} className='btn pg_btn'><span className='me-2'><LuChevronLeft style={{ marginBottom: '2px' }} size={20} /></span></button>
                                                            <p><span>{(pageSize * pageLimit) + 1}</span> - <span>{TotalCount < (pageSize + 1) * pageLimit ? TotalCount : (pageSize + 1) * pageLimit}</span> of <span>{TotalCount}</span></p>
                                                            <button type='button' disabled={TotalCount <= (pageSize + 1) * pageLimit ? true : false} onClick={() => { setPageSize(prev => prev + 1); }} className='btn pg_btn'>   <span className='ms-2'><LuChevronRight style={{ marginBottom: '2px' }} size={20} /></span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='empty_section mt-3'>
                                                <span>DATA NOT FOUND</span>
                                            </div>
                                }
                            </div>}
                    </div>
                </div>
            </div >
        </>
    )
}
