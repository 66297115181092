import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var countryListRes;
export const countryListTodo = createAsyncThunk('countryList', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'country',
            headers: { "Content-Type": "application/json" },
        })
        return countryListRes = res.data

    } catch (error) {
        return console.log(error.response.data)
    }
});

const countryListSlice = createSlice({
    name: 'countryList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(countryListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(countryListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default countryListSlice.reducer; 