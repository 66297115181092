import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var departmentListRes;
export const departmentListTodo = createAsyncThunk('departmentList', async (data) => {
    const adminRole = localStorage.getItem('name')

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'master_department_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "company_id": null
            }
        })
        return departmentListRes = res.data

    } catch (error) {
        return departmentListRes = error.response.data
    }
});

const departmentListSlice = createSlice({
    name: 'departmentList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(departmentListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(departmentListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default departmentListSlice.reducer; 