import React from 'react'
import UpdateStaffForm from '../../../../component/form/super-admin-form/master/staff-form/UpdateStaffForm'

export default function UpdateStaff() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3>Update Staff</h3>
                            <UpdateStaffForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
