import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../../loader/Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserTypeSchema } from './Validation'
import { updateUserTypeRes, updateUserTypeTodo } from '../../../../redux/slices/super-admin-slices/user-type/UpdateUserType'


export default function UpdateUserTypeForm() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { state } = useLocation();
    var userType = state

    const initialValues = {
        user_type: userType.type,
        status: userType.status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UserTypeSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateUserTypeTodo({ "values": values, "id": userType._id })).then(() => updateUserType_res())
        }
    })

    const updateUserType_res = (action) => {
        if (updateUserTypeRes && updateUserTypeRes.status) {
            setLoading(false)
            toast.success(updateUserTypeRes && updateUserTypeRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../superadmin-user-type')
            }, 1500);

        } else {
            setLoading(false)
            toast.error(updateUserTypeRes && updateUserTypeRes.message, { position: "bottom-right" });
        }
    }

    return (
        <>

            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='User Type'
                                    placeholder=''
                                    name='user_type'
                                    value={values.user_type || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.user_type && touched.user_type ? (<span className='text-danger form_label' >{errors.user_type}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={userType.status}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
