import './App.css';
import '../src/assest/styles/login-page/LoginPage.css'
import '../src/assest/styles/layout/Navbar.css'
import '../src/assest/styles/layout/Sidebar.css'
import '../src/assest/styles/Pages/PageLayout.css'
import '../src/assest/styles/Pages/dashboard.css'
import '../src/assest/styles/component/InputFiled.css'
import '../src/assest/styles/component/Form.css'
import '../src/assest/styles/component/Button.css'
import '../src/assest/styles/component/DataTable.css'
import '../src/assest/styles/Pages/DetailsPage.css'
import '../src/assest/styles/Pages/ChatPage.css'
import '../src/assest/styles/Pages/FAQ.css'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import SuperAdminRoute from './routes/SuperAdminRoute';
import AdminRoute from './routes/AdminRoute';


function App() {
  return (
    <div className="App">
      <AdminRoute />
      {/* <SuperAdminRoute /> */}
    </div>
  );
}

export default App;
