import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ReelsDetailsRes;
export const ReelsDetailsTodo = createAsyncThunk('ReelsDetails', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'reels_detail_byid',
            headers: { "Content-Type": "application/json" },
            data: {
                "id":id,
                "company_id": localStorage.getItem('customerId'),
            }
        })
        return ReelsDetailsRes = res.data

    } catch (error) {
        return ReelsDetailsRes = error.response.data
    }
});

const ReelsDetailsSlice = createSlice({
    name: 'ReelsDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ReelsDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(ReelsDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ReelsDetailsSlice.reducer; 