import React, { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList'
import { ChatListRes, ChatListTodo } from '../../../redux/slices/admin-slices/chat/ChatList';
import moment from 'moment';
import Loader from '../../../component/loader/Loader';
import Select from 'react-select'
import chatBg from '../../../assest/images/chat_img-removebg-preview-removebg-preview.png'
import chatImg from '../../../assest/images/hornet.webp'
import { GrDocumentDownload, GrDocumentUpdate } from 'react-icons/gr';
import { adminDepartmentListTodo } from '../../../redux/slices/admin-slices/admin-master/admin-department/AdminDepartmentList';
import { ChatUserListRes, ChatUserListTodo } from '../../../redux/slices/admin-slices/User/ChatUserList';

export default function ChatList() {

    const [loading, setLoading] = useState(false)
    const [chatLoad, setChatLoad] = useState(false)
    const dispatch = useDispatch()
    const userList = useSelector(state => state && state.ChatUserList && state.ChatUserList.data && state.ChatUserList.data.data)
    const chatList = useSelector(state => state && state.ChatList && state.ChatList.data && state.ChatList.data.data)
    // console.log("chatList", chatList)
    // const chatListAsse = [...chatList?.chat || []].reverse();
    // console.log(chatListAsse)
    const departmentList = useSelector(state => state?.AdminDepartmentList?.data?.data)

    const department_opt = departmentList && departmentList.map(val => (
        { "value": val._id, "label": val.name }
    ))

    const type = localStorage.getItem('type')

    useEffect(() => {
        setLoading(true)
        dispatch(ChatUserListTodo({ "type": '',  "id": '1' })).then(() => list_res())
        dispatch(ChatListTodo())
    }, [])

    const list_res = () => {
        if (ChatUserListRes && ChatUserListRes.status) {
            dispatch(adminDepartmentListTodo()).then((department_res) => {
                if (department_res?.payload?.status) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }

    const handleMsg = (id) => {
        setChatLoad(true)
        dispatch(ChatListTodo(id)).then(() => chat_res())
    }

    const chat_res = () => {
        if (ChatListRes && ChatListRes.status) {
            setChatLoad(false)
        } else {
            setChatLoad(false)
        }
    }

    const handleFilter = (id) => {
        // console.log(e)
        dispatch(ChatUserListTodo({ "department": id, }))
    }

    return (
        <>
            <div className="container-fluid">
                <div className='layout_head'>
                    <h3> Chat List </h3>
                </div>
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row " style={{ padding: '0px', marginLeft: '5px' }}>
                        <div className="col-md-3 ps-0 pe-0">
                            <div className='msg_sidebar '>
                                {/* <input class="form-control form_input me-2" type="search" placeholder="Search" aria-label="Search" /> */}
                                <div className='ps-3 pe-3 pt-3'>
                                    <Select
                                        closeMenu={true}
                                        // ref={selectRef}
                                        placeholder={'Select Department'}
                                        disabled={false}
                                        isClearable
                                        options={department_opt}
                                        name='department'
                                        className='react_select'
                                        classNamePrefix="select"
                                        defaultValue={""}
                                        onChange={(e) => handleFilter(e?.value)}
                                    />
                                </div>
                                <div className='side_sec'>
                                    <PerfectScrollbar>
                                        <div className='p-3'>
                                            {userList && userList.map((value, index) =>
                                                <button onClick={() => handleMsg(value._id)} type='button' className='msg_btn'>
                                                    <div class="d-flex  align-items-center">
                                                        <div class="me-2">
                                                            <FaUserCircle style={{ color: '#4b4a4a', marginBottom: "2px" }} size={30} />
                                                        </div>
                                                        <div class="mt-2">
                                                            <h6 style={{ textTransform: 'capitalize', textAlign: 'start', marginBottom: "0px" }}>{value.name}</h6>
                                                            <span className='d-flex' style={{ textTransform: 'capitalize', fontSize: "13px" }}>{(value?.departments?.[0]?.name)}</span>
                                                        </div>
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                    </PerfectScrollbar>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-9 ps-0 pe-0">
                            {
                                chatLoad ?
                                    <div className='d-flex justify-content-center mt-5 pt-5'>
                                        <Loader />
                                    </div> :
                                    chatList && chatList.chat && chatList.chat.length ?
                                        <div className='msg_section'>
                                            <div className='msg_head_2 d-flex  align-items-center'>
                                                <FaUserCircle style={{ color: '#616161' }} size={30} />
                                                <p style={{ textTransform: 'capitalize' }}>{chatList && chatList.Ruser}</p>
                                            </div>
                                            <div className='msg_section_center'>
                                                <PerfectScrollbar>
                                                    <div className='p-3'>
                                                        {
                                                            chatList?.chat?.map((value, index) =>
                                                                <div key={index} className={value && value.sender_id && value.sender_id.name === chatList.user ? 'd-flex justify-content-end align-items-end' : 'd-flex justify-content-start align-items-end'}>
                                                                    {value && value.sender_id && value.sender_id.name === chatList.user ? '' : <span className='send-icon pb-5 me-2'><FaUserCircle size={20} style={{ color: 'rgb(230, 43, 64)' }} /></span>}
                                                                    <div className={value && value.sender_id && value.sender_id.name === chatList.user ? 'recieve_section text-end' : 'send_section text-start'} >
                                                                        <div className={value && value.sender_id && value.sender_id.name === chatList.user ? 'recieve_msg' : 'send_msg'} >
                                                                            {value?.file_type === '' ? <h6>{value.message}</h6> :
                                                                                value?.file_type === "png" || value?.file_type === 'jpg' || value?.file_type === 'jpeg' ?
                                                                                    <div className='chat_img_sec mt-2'>
                                                                                        <img src={process.env.REACT_APP_SOCKET_URL + `${value?.media_file}`} className='img-fluid chat_img' alt="" />
                                                                                    </div> :
                                                                                    value?.file_type === "mp4" ?
                                                                                        <div>
                                                                                            <video width="300" height="200" controls>
                                                                                                <source src="movie.mp4" style={{ borderRadius: '15px' }} type="video/mp4" />
                                                                                                Your browser does not support the video tag.
                                                                                            </video>
                                                                                        </div> : value?.file_type === "pdf" ?
                                                                                            <div className='mt-2'>
                                                                                                <button className=' btn doc_down'><GrDocumentDownload size={30} style={{ color: "white" }} /> </button>
                                                                                            </div> : ''}

                                                                        </div>
                                                                        <p className={value && value.sender_id && value.sender_id.name === chatList.user ? 'text-end ' : 'test-start '} style={{ textTransform: 'capitalize' }}> {value && value.sender_id && value.sender_id.name}  {type === 'Admin' && value && value.sender_id && value.sender_id.name === chatList.user ? <span>to {value && value.receiver_id && value.receiver_id.name}</span> : ''}</p>
                                                                        <span style={{ fontSize: '12px' }} className={value && value.sender_id && value.sender_id.name === chatList.user ? 'text-end ' : 'test-start '}>{new Date(value?.date).toLocaleDateString('en-GB')},{moment(value && value.time, "HH:mm:ss").format('hh:mm A')}</span>
                                                                    </div>
                                                                    {value && value.sender_id && value.sender_id.name === chatList.user ? <span className='recieve-icon pb-5 ms-2'><FaUserCircle size={20} style={{ color: '#105684' }} /></span> : ''}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </div> :
                                        <div className='msg_section '>
                                            <div className='msg_section_center' style={{ height: '80vh' }}>
                                                <div className='chatimg_section'>
                                                    <img src={chatBg} alt="" className='chat_img' />
                                                </div>
                                                <h5 style={{ color: "grey" }} className='text-center'>CHAT NOT AVAILABLE</h5>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>}
            </div>
        </>
    )
}
