import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { adminDeleteStaffRes, adminDeleteStaffTodo } from '../../../../../redux/slices/admin-slices/admin-master/admin-staff/AdminDeleteStaff'
import { RiLockPasswordLine } from 'react-icons/ri'

export default function AdminStaffAction(props) {
    const { bool, setBool, show, setShow, user, setuser } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    var permission = JSON.parse(localStorage.getItem('actions'))


    const edit_staff = () => {
        navigate('../admin/update-user', { state: props.data && props.data })
    }


    const handlePswChnge = () => {
        setShow(true)
        setuser(props?.data?._id)
    }

    const del_staff = () => {

        Swal.fire({
            title: "Staff Delete",
            text: "Are You Sure Want To Delete This Staff ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminDeleteStaffTodo(props.data._id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (adminDeleteStaffRes && adminDeleteStaffRes.status) {
            setBool(!bool)
            Swal.fire(
                "Staff",
                'Staff Deleted Successfully',
                'success'
            )
        } else {
            toast.error(adminDeleteStaffRes && adminDeleteStaffRes.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                <RiLockPasswordLine size={20} className='details text-primary ms-3'
                    onClick={() => handlePswChnge()}
                    data-tooltip-id='psw'
                    data-tooltip-content="Update Password!"
                    type='button'
                    data-tooltip-place="bottom"
                />
                {permission && permission.some(val => val.action_display_name === 'Update User') ?
                    <LuFileEdit size={20} className='edit text-success ms-3'
                        onClick={() => edit_staff()}
                        data-tooltip-id='edit'
                        data-tooltip-content="Edit Staff!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ""}
                {permission && permission.some(val => val.action_display_name === 'Delete User') ?
                    <LuTrash2 size={20} className='delete text-danger ms-3'
                        onClick={() => del_staff()}
                        data-tooltip-id='del'
                        data-tooltip-content="Delete Staff!"
                        type='button'
                        data-tooltip-place="bottom"
                    /> : ""}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
