import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import SaveButton from '../../../component/buttons/SaveButton';
import TextArea from '../../../component/input_filed/TextArea';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { AddMeetingMinutRes, AddMeetingMinutTodo } from '../../../redux/slices/admin-slices/meeting/AddMeetingMinut';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { AddMinutesSchema } from './Validation';
import { UserListRes, UserListTodo } from '../../../redux/slices/admin-slices/User/UserList';
import Flatpickr from 'react-flatpickr'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import "flatpickr/dist/themes/light.css"
import moment from 'moment';
import DatePicker from '../../../component/input_filed/DatePicker';
import Loader from '../../../component/loader/Loader';
import TimePicker from '../../../component/input_filed/TimePicker';

export default function AddMinutes(props) {
    const { minute, setMinute, data, bool, setBool, momLoading } = props;
    const handleClose = () => setMinute(false);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const userList = useSelector(state => state?.UserList?.data?.data)

    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedTime24hr = `${hours}:${minutes.toString().padStart(2, '0')}`;

    var UserOpt = data?.attendends?.map(val => (
        { "value": val?.user?.[0]?._id, "label": val?.user?.[0]?.name }
    ))

    const approve_opt = [
        { "value": 'yes', "label": 'Yes' },
        { "value": 'no', "label": "No" }

    ]


    var content_data = [

        {
            subtopic_desc: '',
            user_id: [],
            deadline: '',
            time: '',
            approved_required: ''
        }
    ]


    const initialValues = {
        meetingId: data?._id,
        title: '',
        data: content_data,
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: AddMinutesSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            const transformedValues = {
                ...values,
                data: values.data.map(item => ({
                    ...item,
                    user_id: item.user_id.map(user => user.value)
                }))
            };
            dispatch(AddMeetingMinutTodo(transformedValues)).then(() => add_res(action))
        }
    });



    const add_res = (action) => {
        if (AddMeetingMinutRes?.status) {
            toast.success(AddMeetingMinutRes?.message, { position: 'bottom-right' })
            setLoading(false)
            action.resetForm()
            setBool(!bool)
            setMinute(false);
        } else {
            setLoading(false)
            toast.error(AddMeetingMinutRes?.message, { position: 'bottom-right' })
        }
    }
    return (
        <>
            <Modal show={minute} onHide={handleClose} size="xl" aria-labelledby="example-modal-sizes-title-xl">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Add Meeting Minutes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {momLoading || loading ?
                        <div className='d-flex justify-content-center mt-5 mb-5'>
                            <Loader />
                        </div>
                        :
                        <FormikProvider value={formik}>
                            <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                                <div className="row mb-3">
                                    <div className="col-md-10">
                                        <Form.Group className="mb-3" controlId="text">
                                            <Form.Label className='form_label mb-0'>Topic</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className='form_input'
                                                name='title'
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.title && formik.touched.title ? (<span className='text-danger form_label' >{formik.errors.title}</span>) : null}

                                        </Form.Group>
                                    </div>
                                </div>

                                <FieldArray
                                    name="data"
                                    render={(arrayHelpers) => (
                                        <>
                                            <div className='p-0' style={{ height: '55vh' }}>
                                                <PerfectScrollbar >
                                                    <div className='ps-3 pe-4'>
                                                        {formik && formik.values && formik.values.data && formik.values.data.map((field, index) => (
                                                            <div className="row  " key={index}>
                                                                <div className='col-md-4 p-1'>
                                                                    <Form.Group className="" controlId="text">
                                                                        <Form.Label className='form_label mb-0'>Sub Topic</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            className='form_input'
                                                                            name={`data[${index}].subtopic_desc`}
                                                                            value={formik.values.data[index].subtopic_desc}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </Form.Group>
                                                                    {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].subtopic_desc && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].subtopic_desc ?
                                                                        (<span className='text-danger form_label'> {formik.errors.data[index].subtopic_desc}</span>) : null
                                                                    }
                                                                </div>
                                                                <div className="col-md-4 p-1">
                                                                    <Form.Group className="" controlId="text">
                                                                        <Form.Label className='form_label mb-0'>User</Form.Label>
                                                                        <Select
                                                                            closeMenu={false}
                                                                            label_name=''
                                                                            disabled={false}
                                                                            options={UserOpt || []}
                                                                            name={`data[${index}].user_id`}
                                                                            className='react_select'
                                                                            classNamePrefix="select"
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(`data[${index}].user_id`, e);
                                                                            }
                                                                            }
                                                                            onBlur={formik.handleBlur}
                                                                            isMulti
                                                                        />
                                                                    </Form.Group>
                                                                    {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].user_id && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].user_id ?
                                                                        (<span className='text-danger form_label'> {formik.errors.data[index].user_id}</span>) : null
                                                                    }
                                                                </div>
                                                                <div className="col-md-4 p-1">
                                                                    <Form.Group className="" controlId="text">
                                                                        <Form.Label className='form_label mb-0'>Closure Approval Required</Form.Label>
                                                                        <Select
                                                                            closeMenu={true}
                                                                            label_name=''
                                                                            disabled={false}
                                                                            options={approve_opt || []}
                                                                            name={`data[${index}].approved_required`}
                                                                            className='react_select'
                                                                            classNamePrefix="select"
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(`data[${index}].approved_required`, e.value);
                                                                            }
                                                                            }
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </Form.Group>
                                                                    {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].approved_required && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].approved_required ?
                                                                        (<span className='text-danger form_label'> {formik.errors.data[index].approved_required}</span>) : null
                                                                    }
                                                                </div>
                                                                <div className="col-md-4 p-1">
                                                                    <Form.Group className="" controlId="text">
                                                                        <Form.Label className='form_label mb-0'>Deadline</Form.Label>
                                                                        <DatePicker
                                                                            label_name=''
                                                                            placeholder={'Choose Date'}
                                                                            name={`data[${index}].deadline`}
                                                                            option={{
                                                                                altInput: true,
                                                                                altFormat: "F j, Y",
                                                                                dateFormat: 'Y-m-d',
                                                                                minDate: new Date().toISOString().split("T")[0],
                                                                                maxDate: '',
                                                                                disable: [],
                                                                                mode: "single",
                                                                                defaultDate: [],
                                                                                conjunction: "",
                                                                                static: true
                                                                            }}
                                                                            value={formik.values.deadline || ''}
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(`data[${index}].deadline`, moment(e[0]).format("YYYY-MM-DD"))
                                                                                formik.setFieldValue(`data[${index}].time`, '')
                                                                            }}
                                                                            onBlur={formik.handleBlur}
                                                                            error={''}
                                                                        />
                                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].deadline && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].deadline ?
                                                                            (<span className='text-danger form_label'> {formik.errors.data[index].deadline}</span>) : null
                                                                        }
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-md-4 p-1">
                                                                    <Form.Group className="" controlId="text">
                                                                        <Form.Label className='form_label mb-0'>Time</Form.Label>
                                                                        <TimePicker
                                                                            label_name=''
                                                                            placeholder={'Choose Time'}
                                                                            name={`data[${index}].time`}
                                                                            option={{
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat: 'G:i K', // Customize date format as needed
                                                                                time_24hr: false,
                                                                                // minTime: new Date(`data[${index}].deadline`).toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB') ? formattedTime24hr : '', // Before dates disabled
                                                                                minTime:'',
                                                                                maxTime: '', // After dates disabled
                                                                                disable: [], // Disable particular date ranges
                                                                                static:true

                                                                            }}
                                                                            onChange={(e) =>
                                                                                formik.setFieldValue(`data[${index}].time`, moment(e[0]).format('hh:mm A'))
                                                                            }
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.time || ''}
                                                                            error={''}
                                                                        />
                                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].time && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].time ?
                                                                            (<span className='text-danger form_label'> {formik.errors.data[index].time}</span>) : null
                                                                        }
                                                                    </Form.Group>
                                                                </div>


                                                                <div className={index == 0 ? 'col-md-1 mt-4 ' : 'col-md-1  mt-4 '}>
                                                                    <div className='d-flex'>
                                                                        <button type='button' className='btn add_btn2 me-2' onClick={() => arrayHelpers.push({ subtopic_desc: "", user_id: '', deadline: "", time: '', approved_required: '' })}>
                                                                            <FaPlus size={18} />
                                                                        </button>

                                                                        {formik?.values?.data?.length == 1 ? "" : <button type='button' className='btn del_btn' onClick={() => arrayHelpers.remove(index)}>
                                                                            <FaTrash size={18} />
                                                                        </button>}



                                                                    </div>
                                                                </div>
                                                                <hr />
                                                            </div>))}
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </>

                                    )
                                    }
                                />
                                <div className='col-md-12 text-end mt-2'>
                                    <SaveButton name={'Save'} />
                                </div>
                            </form>
                        </FormikProvider>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
