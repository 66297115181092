import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import Select from 'react-select'
import Loader from '../../../../component/loader/Loader'
import { staffListRes, staffListTodo } from '../../../../redux/slices/super-admin-slices/master/staff/StaffList'
import StaffAction from '../../../../component/data-table-action/table_action/super-admin-action/StaffAction'
import AdminStaffAction from '../../../../component/data-table-action/table_action/admin-action/admin-master-action/AdminStaffAction'
import { UserListRes, UserListTodo } from '../../../../redux/slices/admin-slices/User/UserList'
import AddButton from '../../../../component/buttons/AddButton'
import { Link } from 'react-router-dom'
import SaveButton from '../../../../component/buttons/SaveButton'
import { useFormik } from 'formik'
import { IoRefreshOutline } from 'react-icons/io5'
import { adminDepartmentListTodo } from '../../../../redux/slices/admin-slices/admin-master/admin-department/AdminDepartmentList'
import { adminDesignationListTodo } from '../../../../redux/slices/admin-slices/admin-master/admin-designation/AdminDesignationList'
import UpdatePsw from '../../../../component/form/admin-form/admin-login/UpdatePsw'

export default function AdminStaffList() {
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch()
    var permission = JSON.parse(localStorage.getItem('actions'))
    const [filterLoad, setFilterLoad] = useState(false)
    const adminStaffList = useSelector(state => state && state.UserList && state.UserList.data && state.UserList.data.data)
    const departmentList = useSelector(state => state?.AdminDepartmentList?.data?.data)
    const [show, setShow] = useState(false)
    const [user, setuser] = useState('')

    // const designationList = useSelector(state =>  state?.AdminDesignationList?.data?.data)

    const department_opt = departmentList && departmentList.map(val => (
        { "value": val._id, "label": val.name }
    ))
    // const designation_opt = designationList && designationList.map(val => (
    //     { "value": val._id, "label": val.name }
    // ))
    const status_option = [
        { "value": 'Active', "label": "Active" },
        { "value": 'Inactive', "label": "Inactive" },
    ]

    useEffect(() => {
        setLoading(true)
        dispatch(UserListTodo({ 'type': '1' })).then(() => staffList_res())
    }, [bool])

    const staffList_res = () => {
        if (UserListRes && UserListRes.status) {
            dispatch(adminDepartmentListTodo()).then((department_res) => {
                if (department_res?.payload?.status) {
                    setLoading(false)
                    // dispatch(adminDesignationListTodo()).then((designation_res) => {
                    //     if (designation_res?.payload?.status) {
                    //         setLoading(false)
                    //     } else {
                    //         setLoading(false)
                    //     }
                    // })
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }

    const initialValues = {
        department: '',
        designation:'',
        status: ''

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,

        onSubmit: (values, action) => {
            setFilterLoad(true)
            dispatch(UserListTodo({ 'type': '1', values })).then(() => filter_res())
        }
    })

    const filter_res = () => {
        if (UserListRes && UserListRes.status) {
            setFilterLoad(false)
        } else {
            setFilterLoad(false)
        }
    }

    const selectRef = useRef();
    // const selectDesignationRef = useRef()
    const selectStatusRef = useRef();
    const handleReset = () => {
        selectRef && selectRef.current && selectRef.current.clearValue();
        // selectDesignationRef && selectDesignationRef.current && selectDesignationRef.current.clearValue();
        selectStatusRef && selectStatusRef.current && selectStatusRef.current.clearValue();
        setBool(!bool)
    };


    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'email',
            cellRenderer: params => params?.data?.email,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            // wrapText: true,
            // autoHeight: true,
            floatingFilter: false,
        },

        // {
        //     headerName: 'USER ROLE',
        //     field: 'userRole',
        //     filter: 'agTextColumnFilter',
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        //     // wrapText: true,
        //     // autoHeight: true,
        // },
        {
            headerName: 'PROFILE',
            field: 'profileImage',
            cellRenderer: params => <div className='table_img_sec'>
                {params?.data?.profileImage === "" ? '-' : <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params?.data?.profileImage} alt="image" />}
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            autoHeight: true,
            filter: true,
            maxWidth: 120,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DEPARTMENT',
            field: 'departments[0].name',
            cellRenderer: params => params?.data?.departments?.[0]?.name,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DESIGNATION',
            field: 'designations[0].name',
            cellRenderer: params => params?.data?.designations?.[0]?.name,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            // wrapText: true,
            // autoHeight: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge   text-bg-success'>Active</span> : <span className='badge   text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 120,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: AdminStaffAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                show: show,
                setShow: setShow,
                setuser: setuser,
                user: user
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            maxWidth: 120,
            pinned: 'right',
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='d-flex justify-content-between align-items-center layout_head mb-2'>
                            <h3> User List</h3>

                            <div className='d-flex align-items-center mb-2'>
                                <form onSubmit={handleSubmit}>
                                    <div className="d-flex justify-content-end align-items-center ">
                                        <div className='ms-2'>
                                            <Select
                                                closeMenu={true}
                                                ref={selectRef}
                                                placeholder={'Select Department'}
                                                disabled={false}
                                                options={department_opt}
                                                name='department'
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                onChange={(e) => { setFieldValue('department', e && e.value); }}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        {/* <div className='ms-2'>
                                            <Select
                                                closeMenu={true}
                                                ref={selectDesignationRef}
                                                placeholder={'Select Designation'}
                                                disabled={false}
                                                options={designation_opt}
                                                name='designation'
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                onChange={(e) => { setFieldValue('designation', e && e.value); }}
                                                onBlur={handleBlur}
                                            />
                                        </div> */}
                                        <div className='ms-2'>
                                            <Select
                                                closeMenu={true}
                                                ref={selectStatusRef}
                                                placeholder={'Select Status'}
                                                disabled={false}
                                                options={status_option}
                                                name='status'
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                onChange={(e) => { setFieldValue('status', e && e.value); }}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className='ms-2'>
                                            <div className='d-flex align-items-center'>
                                                <SaveButton name={'Filter'} />
                                                <span className='ms-2 refresh_btn' type='button' onClick={handleReset} ><IoRefreshOutline style={{ color: 'white' }} size={25} /></span>
                                            </div>

                                        </div>

                                    </div>
                                </form>
                                {permission && permission.some(val => val.action_display_name === 'Add User') ? <div className='ms-2'> <Link to='/admin/add-user'><AddButton name={'Add User'} /></Link>  </div> : ""}
                            </div>


                        </div>

                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            adminStaffList && adminStaffList.length ?
                                <DataTable
                                    rowData={adminStaffList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                />
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <UpdatePsw show={show} setShow={setShow} user={user} setuser={setuser} />
        </>
    )
}
