import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Form, Modal } from 'react-bootstrap';
import Select from 'react-select'
import { FaPlus, FaTrash } from 'react-icons/fa';
import DatePicker from '../../../component/input_filed/DatePicker';
import moment from 'moment';
import TimePicker from '../../../component/input_filed/TimePicker';
import SaveButton from '../../../component/buttons/SaveButton';
import Loader from '../../../component/loader/Loader';
import { ExcelUploadSchema } from './Validation';
import { useDispatch } from 'react-redux';
import { UploadExcelRes, UploadExcelTodo } from '../../../redux/slices/admin-slices/meeting/UploadExcel';
import { toast } from 'react-toastify';


export default function UploadExcel(props) {
  const { data, uploadModel, setUploadModel,bool,setBool, excelData, setExcelData } = props
  const [loading, setLoading] = useState(true)
  console.log("excelData", excelData)
  const dispatch = useDispatch()

  const handleClose = () =>{ setUploadModel(false)};

  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const formattedTime24hr = `${hours}:${minutes.toString().padStart(2, '0')}`;

  const approve_opt = [
    { "value": 'yes', "label": 'Yes' },
    { "value": 'no', "label": "No" }

  ]

  // console.log(data)

  var UserOpt = data?.attendends?.map(val => (
    { "value": val?.user?.[0]?._id, "label": val?.user?.[0]?.name }
  ))


  console.log(UserOpt)
 
  setTimeout(() => {
    setLoading(false)
  }, 1600)


  useEffect(() => {
    if (uploadModel) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [uploadModel]);

  const getUserByName = (name) => {
    return UserOpt.find(user => user.label === name) || null;
};




  const initialValues = {

    data: excelData?.map(value => ({
      meeting_id: data?._id,
      topic: value?.topic_discussed,
      company_id: localStorage.getItem('customerId'),
      added_by: data?.added_by,
      subtopic: value?.subtopic?.map(val2 => ({
        subtopic_desc: val2?.action_plan,
        approved_required: '',
        deadline: val2?.doc,
        time: val2?.time,
        user_id: getUserByName(val2?.responsibility)? [getUserByName(val2?.responsibility)]:'', // Set user ID based on responsibility
      }))
    })),
  };



  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ExcelUploadSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const transformedValues = {
        ...values,
        data: values.data.map(item => ({
          ...item,
          subtopic: item.subtopic.map(user => ({
            ...user,
            user_id: user.user_id.map(user => user.value)

          }))
        }))
      };
      // console.log("transformedValues", transformedValues)
      dispatch(UploadExcelTodo(transformedValues)).then(() => upload_res(action))

    }
  });

  console.log("formki",formik.values)

  const upload_res = (action) => {
    if (UploadExcelRes?.status) {
      toast.success(UploadExcelRes?.message, { position: 'bottom-right' })
      setBool(!bool)
      setUploadModel(false);
      
    } else {
      toast.error(UploadExcelRes?.message, { position: 'bottom-right' })

    }
  }
  // console.log("values1", formik.values)


  return (
    <>
      <Modal show={uploadModel} onHide={handleClose} size="xl" aria-labelledby="example-modal-sizes-title-xl">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Upload Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <div className='d-flex justify-content-center mt-5 mb-5'>
              <Loader />
            </div>
            :
            <FormikProvider value={formik}>
              <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                <div className='p-0' style={{ height: '75vh' }}>
                  <PerfectScrollbar >
                    <div className='ps-3 pe-3'>
                      <FieldArray
                        name="data"
                        render={(arrayHelpers) => (
                          <>

                            {formik?.values?.data?.map((field, index) => (
                              <div className='row'>
                                <div className="col-md-10">
                                  <Form.Group className="mb-3" controlId="text">
                                    <Form.Label className='form_label mb-0'>Topic</Form.Label>
                                    <Form.Control
                                      type="text"
                                      className='form_input'
                                      name={`data[${index}].topic`}
                                      // disabled={data?.added_by === userId ? false : true}
                                      value={field?.topic}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].topic && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].topic ?
                                      (<span className='text-danger form_label'> {formik.errors.data[index].topic}</span>) : null
                                    }

                                  </Form.Group>
                                </div>

                                <FieldArray
                                  name={`data.${index}.subtopic`}
                                  render={(arrayHelpers2) => (
                                    <>
                                      {field?.subtopic?.map((val3, index2) => (
                                        <div className="row">
                                          <div className="col-md-4">
                                            <Form.Group controlId="text">
                                              <Form.Label className='form_label mb-0'>Sub Topic</Form.Label>
                                              <Form.Control
                                                type="text"
                                                className='form_input'
                                                name={`data[${index}].subtopic[${index2}].subtopic_desc`}
                                                value={val3?.subtopic_desc}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                              />
                                            </Form.Group>
                                            {formik?.errors?.data?.[index]?.subtopic?.[index2]?.subtopic_desc ?
                                              (<span className='text-danger form_label'> {formik?.errors?.data?.[index]?.subtopic?.[index2]?.subtopic_desc}</span>) : null
                                            }
                                          </div>
                                          <div className="col-md-4 ">
                                            <Form.Group className="" controlId="text">
                                              <Form.Label className='form_label mb-0'>User</Form.Label>
                                              <Select
                                                closeMenu={true}
                                                label_name=''
                                                // isDisabled={formik.values.data[index].subtopic_id ? true : false}
                                                options={UserOpt || []}
                                                name={`data[${index}].subtopic[${index2}].user_id`}
                                                className='react_select'
                                                classNamePrefix="select"
                                                defaultValue={val3?.user_id}
                                                onChange={(e) => {
                                                  formik.setFieldValue(`data[${index}].subtopic[${index2}].user_id`, e);
                                                }
                                                }
                                                isMulti
                                                onBlur={formik.handleBlur}
                                              />
                                            </Form.Group>
                                            {formik?.errors?.data?.[index]?.subtopic?.[index2]?.user_id ?
                                              (<span className='text-danger form_label'> {formik?.errors?.data?.[index]?.subtopic?.[index2]?.user_id}</span>) : null
                                            }
                                          </div>
                                          <div className="col-md-4 p-1">
                                            <Form.Group className="" controlId="text">
                                              <Form.Label className='form_label mb-0'>Closure Approval Required</Form.Label>
                                              <Select
                                                closeMenu={true}
                                                label_name=''
                                                disabled={false}
                                                options={approve_opt || []}
                                                name={`data[${index}].subtopic[${index2}].approved_required`}
                                                className='react_select'
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                  formik.setFieldValue(`data[${index}].subtopic[${index2}].approved_required`, e.value);
                                                }
                                                }
                                                onBlur={formik.handleBlur}
                                              />
                                            </Form.Group>
                                            {formik?.errors?.data?.[index]?.subtopic?.[index2]?.approved_required ?
                                              (<span className='text-danger form_label'> {formik?.errors?.data?.[index]?.subtopic?.[index2]?.approved_required}</span>) : null
                                            }
                                          </div>
                                          <div className="col-md-4 ">
                                            <Form.Group className="" controlId="text">
                                              <Form.Label className='form_label mb-0'>Deadline</Form.Label>
                                              <DatePicker
                                                label_name=''
                                                placeholder={'Choose Date'}
                                                name={`data[${index}].subtopic[${index2}].deadline`}
                                                option={{
                                                  altInput: true,
                                                  altFormat: "F j, Y",
                                                  dateFormat: 'Y-m-d',
                                                  minDate: new Date().toISOString().split("T")[0],
                                                  maxDate: '',
                                                  disable: [],
                                                  mode: "single",
                                                  defaultDate: [],
                                                  conjunction: "",
                                                  static: true
                                                }}
                                                value={val3?.deadline || ''}
                                                onChange={(e) =>
                                                {
                                                  formik.setFieldValue(`data[${index}].subtopic[${index2}].deadline`, moment(e[0]).format("YYYY-MM-DD"))
                                                  formik.setFieldValue(`data[${index}].subtopic[${index2}].time`, '')
                                                }}
                                                onBlur={formik.handleBlur}
                                                error={''}
                                              />
                                              {formik?.errors?.data?.[index]?.subtopic?.[index2]?.deadline ?
                                                (<span className='text-danger form_label'> {formik?.errors?.data?.[index]?.subtopic?.[index2]?.deadline}</span>) : null
                                              }
                                            </Form.Group>
                                          </div>
                                          <div className="col-md-4 ">
                                            <Form.Group className="" controlId="text">
                                              <Form.Label className='form_label mb-0'>Time</Form.Label>
                                              <TimePicker
                                                label_name=''
                                                placeholder={'Choose Time'}
                                                name={`data[${index}].subtopic[${index2}].time`}
                                                option={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: 'G:i K', // Customize date format as needed
                                                  time_24hr: false,
                                                  minTime: new Date(formik.values.data[index].subtopic[index2].deadline).toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')
                                                  ? formattedTime24hr : '',
                                                  maxTime: '', // After dates disabled
                                                  disable: [], // Disable particular date ranges
                                                  defaultDate: [val3?.time],
                                                  static: true

                                                }}
                                                onChange={(e) =>
                                                  formik.setFieldValue(`data[${index}].subtopic[${index2}].time`, moment(e[0]).format('hh:mm A'))
                                                }
                                                onBlur={formik.handleBlur}
                                                value={val3?.time || ''}
                                                error={''}
                                              />
                                              {formik?.errors?.data?.[index]?.subtopic?.[index2]?.time ?
                                                (<span className='text-danger form_label'> {formik?.errors?.data?.[index]?.subtopic?.[index2]?.time}</span>) : null
                                              }
                                            </Form.Group>
                                          </div>
                                          <div className={ 'col-md-1  mt-4  ms-2' }>
                                            <div className='d-flex justify-content-end'>
                                              <button type='button'
                                                className='btn add_btn2 me-2' onClick={() => arrayHelpers2.push({
                                                  subtopic_desc: '',
                                                  deadline: '',
                                                  time: '',
                                                  user_id: '',
                                                  approved_required: ''
                                                })}
                                              >
                                                <FaPlus size={18} />
                                              </button>

                                              {field?.subtopic?.length == 1 ? "" : <button type='button' className='btn del_btn' onClick={() => { arrayHelpers2.remove(index2) }} >
                                                <FaTrash size={18} />
                                              </button>}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )} />
                                <hr className='mt-2' />
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </PerfectScrollbar>
                </div>
                <div className='col-md-12 text-end '>
                  <SaveButton name={'Save'} />
                </div>
              </form>
            </FormikProvider>}
        </Modal.Body>
      </Modal>
    </>
  )
}
