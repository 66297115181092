import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddMeetingRes;
export const AddMeetingTodo = createAsyncThunk('AddMeeting', async (data) => {
    const adminRole = localStorage.getItem('name')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_meeting',
            data: {
                "title": data.title,
                "desc": data.description,
                "user_id":data.filter_typ === 'user' ?data?.attendees?.map(item => item.value):[],
                "time":data.time,
                "date": data.start_date,
                "duration": data.duration,
                "group_id": data.group,
                "attendee_type": data.filter_typ,
                'meeting_type':data.type,
                'meeting_location':data.location,
                "type":"owner",
                "added_by":localStorage.getItem('id'),
                "company_id":localStorage.getItem('customerId') 
            },
            headers: { "Content-Type": "application/json" }
        })
        return AddMeetingRes = res.data
        
    } catch (error) {
        return AddMeetingRes = error.response.data
    }
})

