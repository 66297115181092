import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var customerModuleListRes;
export const customerModuleListTodo = createAsyncThunk('customerModuleList', async (id) => {
// console.log(id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'customer_mastermodule_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "customer_id": id
            }

        })
        return customerModuleListRes = res.data

    } catch (error) {
        return customerModuleListRes = error.response.data
    }
});

const customerModuleListSlice = createSlice({
    name: 'customerModuleList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(customerModuleListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(customerModuleListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default customerModuleListSlice.reducer; 