import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var TodoListRes;
export const TodoListTodo = createAsyncThunk('TodoList', async (data) => {
    const userType = localStorage.getItem('type')
    console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'todo_assign',
            headers: { "Content-Type": "application/json" },
            data: {
                "type": "task",
                "status": "all",
                "webtype": "web",
                "filtertype":data?.filtertype,
                "department": data?.values?.department ? data?.values?.department : "",
                'title': data?.title ? data?.title : "",
                "tasktype":data?.values?.type ? data?.values?.type : "",
                "tstatus": data?.values?.status ? data?.values?.status : "" ,
                "company_id": localStorage.getItem('customerId'),
                "user_id": userType === 'Admin' ? '' : localStorage.getItem('id'),
                "assign_to":  data?.values?.user ? data?.values?.user : "",
                "date": data?.values?.date ? data?.values?.date : "",
                "page": data?.pageSize ? data?.pageSize : "",
                "pagelimit": data?.pageLimit ? data?.pageLimit : ""

            }
        })
        return TodoListRes = res.data

    } catch (error) {
        return TodoListRes = error.response.data
    }
});

const TodoListSlice = createSlice({
    name: 'TodoList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(TodoListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(TodoListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default TodoListSlice.reducer; 