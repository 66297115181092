import * as Yup from "yup";

export const AddActionSchema = Yup.object({
    module: Yup.string().required("Select Module"),
    name: Yup.string().required("Enter Action Name"),
    description: Yup.string().required("Enter Action Description"),
    action_url: Yup.string().required("Enter Action URL"),
    priority: Yup.number().positive("Priority value can't be less than 0").integer("Number Should be Integer").required('Priority number is required'),
    showInmenu: Yup.string().required("Select Menu Status"),
    status: Yup.string().required("Select Status"),
    controller: Yup.string().required("Enter Controller"),

});