import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import DataTable from '../../../component/data-table/DataTable'
import Loader from '../../../component/loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import PlanAction from '../../../component/data-table-action/table_action/super-admin-action/PlanTableAction'
import { PlanListRes, PlanListTodo } from '../../../redux/slices/super-admin-slices/plan/PlanList'

export default function PlanList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const planList = useSelector(state => state?.PlanList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(PlanListTodo()).then(() => List_res())
    }, [bool])

    const List_res = () => {
        if (PlanListRes?.status) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'PLAN NAME',
            field: 'plan_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'VALIDITY',
            field: 'validity',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'AMOUNT',
            field: 'amount',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: PlanAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                planList && planList.length ?
                <DataTable
                    rowData={planList}
                    columnDefs={columnDefs}
                    height={'70vh'}
                />
                :
                <div className='empty_section'>
                    <span>DATA NOT FOUND</span>
                </div>
            }
            <ToastContainer />
        </>
    )
}
