import React from 'react'
import UpdateUserTypeForm from '../../../component/form/super-admin-form/user-type/UpdateUserTypeForm'

export default function UpdateUserType() {
    return (
        <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h3> Update User Type</h3>
                        </div>
                        <UpdateUserTypeForm/>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
