import React, { useEffect } from 'react'
import userlogo from '../../assest/images/profile.png'
import { MdDashboard, MdPendingActions, MdPayment, MdLeaderboard } from "react-icons/md";
import { BiChevronRight, BiClinic } from "react-icons/bi";
import { MdOutlineViewModule } from "react-icons/md";
import { FaRegUser, FaRegBuilding, FaUserLock } from "react-icons/fa";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { NavLink } from 'react-router-dom';
import { LuPackage, LuPackageCheck } from 'react-icons/lu';
import { Accordion } from 'react-bootstrap';

export default function SuperAdminSidebar() {
    const userRole = localStorage.getItem('userRole')
    console.log(userRole)

    // let routeURL = [
    //     { "action_display_name": "Dashboard", "action_url_name": "/superadmin/dashboard" },
    //     { "action_display_name": "Add Designation", "action_url_name": "/superadmin/designation" },
    //     { "action_display_name": "Update Designation", "action_url_name": "/superadmin/update-designation" },
    //     { "action_display_name": "Delete Designation", "action_url_name": "/delete-designation" },
    //     { "action_display_name": "Add Department", "action_url_name": "/superadmin/department" },
    //     { "action_display_name": "Update Department", "action_url_name": "/superadmin/update-department" },
    //     { "action_display_name": "Delete Department", "action_url_name": "/delete-department" },
    //     { "action_display_name": "Add User Type", "action_url_name": "/superadmin/user-type" },
    //     { "action_display_name": "Update User Type", "action_url_name": "/superadmin/update-usertype" },
    //     { "action_display_name": "Delete User Type", "action_url_name": "/delete-user-type" },
    //     { "action_display_name": "Add Staff", "action_url_name": "/superadmin/staff" },
    //     { "action_display_name": "Update Staff", "action_url_name": "/superadmin/update-staff" },
    //     { "action_display_name": "Delete Staff", "action_url_name": "/delete-staff" },
    //     { "action_display_name": "Add Customer", "action_url_name": "/superadmin/add-customer" },
    //     { "action_display_name": "Update Customer", "action_url_name": "/superadmin/update-customer" },
    //     { "action_display_name": "Customer List", "action_url_name": "/superadmin/customer-list" },
    //     { "action_display_name": "Add Module", "action_url_name": "/superadmin/add-module" },
    //     { "action_display_name": "Update Module", "action_url_name": "/superadmin/update-module" },
    //     { "action_display_name": "Module List", "action_url_name": "/superadmin/module-list" },
    //     { "action_display_name": "Add Action", "action_url_name": "/superadmin/add-action" },
    //     { "action_display_name": "Update Action", "action_url_name": "/superadmin/update-action" },
    //     { "action_display_name": "Action List", "action_url_name": "/superadmin/action-list" },
    //     { "action_display_name": "Customer Permission", "action_url_name": "/superadmin/customer-permission" },
    //     { "action_display_name": "User Permission", "action_url_name": "/superadmin/user-permission" },
    //     { "action_display_name": "Add Dealer", "action_url_name": "/superadmin/add-dealer" },
    //     { "action_display_name": "Update Dealer", "action_url_name": "/superadmin/update-dealer" },
    //     { "action_display_name": "Dealer List", "action_url_name": "/superadmin/dealer-list" },
    //     { "action_display_name": "Add Plan", "action_url_name": "/superadmin/plan" },
    //     { "action_display_name": "Update Plan", "action_url_name": "/superadmin/update-plan" },
    //     { "action_display_name": "Add Assign Plan", "action_url_name": "/superadmin/assign-plan" },
    //     { "action_display_name": "Update Assign Plan", "action_url_name": "/superadmin/update-assign-plan" },
    // ]
    // useEffect(() => {
    //     localStorage.setItem("actions", JSON.stringify(routeURL))
    // }, [])

    return (
        <>
            <div id="dashboard-sidebar">
                <div className=" profile-header ">
                    <div className='text-center sidebar-logo-section '>
                        <img src={userlogo} alt="img" className='img-fluid user-img' />
                        <h5>Admin </h5>
                    </div>
                </div>
                <div className="offcanvas-body profile-sidebar-body ">
                    <PerfectScrollbar>
                        <Accordion >
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="0">
                                <NavLink to="../superadmin/dashboard">   <Accordion.Header >  <MdDashboard className='mb-1 me-2' size={20} /> Dashboard</Accordion.Header> </NavLink>
                            </Accordion.Item> : ''}
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="3">
                                <Accordion.Header >  <MdLeaderboard className='mb-1 me-1' size={25} />  Master</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <NavLink to='../superadmin/designation'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Designation</li></NavLink>
                                            <NavLink to='../superadmin/department'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Department</li></NavLink>
                                            <NavLink to='../superadmin/user-type'> <li ><BiChevronRight className='mb-1 me-0' size={20} />   User Type</li></NavLink>
                                            <NavLink to='../superadmin/staff'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> : ""}
                            <Accordion.Item className='acc_btn' eventKey="4">
                                <Accordion.Header>  <FaRegUser className='mb-1 me-2' size={20} />  Customers</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to='../staff-list'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff   </li></Link> */}
                                            <NavLink to='../superadmin/add-customer'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Add Customer</li></NavLink>
                                            <NavLink to='../superadmin/customer-list'> <li ><BiChevronRight className='mb-1 me-0' size={20} />   Customer List</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="5">
                                <Accordion.Header><FaRegUser className='mb-1 me-2' size={20} />  Dealer</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to='../staff-list'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff   </li></Link> */}
                                            <NavLink to='../superadmin/add-dealer'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Add Dealer</li></NavLink>
                                            <NavLink to='../superadmin/dealer-list'> <li ><BiChevronRight className='mb-1 me-0' size={20} />   Dealer List</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> : ""}
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="6">
                                <NavLink to="../superadmin/plan">   <Accordion.Header >    <LuPackage className='mb-1 me-2' size={20} /> Subscription</Accordion.Header> </NavLink>
                            </Accordion.Item> : ''}
                            <Accordion.Item className='acc_btn' eventKey="7">
                            <NavLink to="../superadmin/assign-plan">   <Accordion.Header >    <LuPackageCheck className='mb-1 me-2' size={20} />Assign Subscription</Accordion.Header> </NavLink>
                            </Accordion.Item>
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="8">
                                <Accordion.Header><MdOutlineViewModule className='mb-1 me-2' size={22} />  Modules</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to='../staff-list'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff   </li></Link> */}
                                            <NavLink to='../superadmin/add-module'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Add Module</li></NavLink>
                                            <NavLink to='../superadmin/module-list'>  <li ><BiChevronRight className='mb-1 me-0' size={20} />   Module List</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> : ""}
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="9">
                                <Accordion.Header> <MdPendingActions className='mb-1 me-2' size={20} />  Actions</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to='../staff-list'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff   </li></Link> */}
                                            <NavLink to='../superadmin/add-action'>  <li ><BiChevronRight className='mb-1 me-0' size={20} />  Add Action</li></NavLink>
                                            <NavLink to="../superadmin/action-list">  <li ><BiChevronRight className='mb-1 me-0' size={20} />   Action List</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> : ""}
                            {userRole === 'superadmin' ? <Accordion.Item className='acc_btn' eventKey="10">
                                <Accordion.Header>  <FaUserLock className='mb-1 me-2' size={20} />  Permission</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to='../staff-list'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff   </li></Link> */}
                                            <NavLink to='../superadmin/customer-permission'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Customer Permission</li></NavLink>
                                            <NavLink to='../superadmin/user-permission'><li ><BiChevronRight className='mb-1 me-0' size={20} />   User Permission</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> : ""}
                        </Accordion>
                    </PerfectScrollbar>
                </div>
            </div>
        </>
    )
}
