import React, { useState } from 'react'
import SaveButton from '../../../buttons/SaveButton';
import Text from '../../../input_filed/Text';
import Loader from '../../../loader/Loader';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { addPlanRes, addPlanTodo } from '../../../../redux/slices/super-admin-slices/plan/AddPlan';
import { toast } from 'react-toastify';
import { PlanSchema } from './Validation';

export default function AddPlanForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const initialValues = {
        name: '',
        validity: "",
        amount: ""
    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: PlanSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(addPlanTodo(values)).then(() => add_res(action))
        }
    })

    const add_res = (action) => {
        if (addPlanRes?.status) {
            setLoading(false)
            setBool(!bool)
            toast.success(addPlanRes?.message, { position: "bottom-right" })
            action.resetForm()

        } else {
            setLoading(false)
            toast.error(addPlanRes?.message, { position: "bottom-right" });
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Plan Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Plan Validity'
                                    placeholder=''
                                    name='validity'
                                    value={values.validity || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.validity && touched.validity ? (<span className='text-danger form_label' >{errors.validity}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Amount'
                                    placeholder=''
                                    name='amount'
                                    value={values.amount || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.amount && touched.amount ? (<span className='text-danger form_label' >{errors.amount}</span>) : null}
                                />
                            </div>


                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
        </>
    )
}
