import * as Yup from "yup";

export const CustomerSchema = Yup.object({
    customer_name: Yup.string().required(" Select Customer"),
    
});
export const UserSchema = Yup.object({
    customer_name: Yup.string().required(" Select Customer"),
    user_typ: Yup.string().required(" Select User"),
    
});