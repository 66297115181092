import React from 'react'
import SaveButton from '../../../component/buttons/SaveButton';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { useFormik } from 'formik';
import TextArea from '../../../component/input_filed/TextArea';
import File from '../../../component/input_filed/File';
import { AddFileRes, AddFileTodo } from '../../../redux/slices/admin-slices/meeting/AddFile';
import { toast } from 'react-toastify';

export default function AddFile(props) {

    const { file, setFile, data, bool, setBool } = props;
    const dispatch = useDispatch()
    const handleClose = () => setFile(false);

    const FILE_SIZE = 2 * 1024 * 1024;
    const AddAttendeeSchema = Yup.object({
        description: Yup.string().required("Enter Description"),
        file: Yup.mixed() .required('File is required')
        .test(
          'fileSize',
          'File size is too large. Maximum size is 2MB.',
          value => !value || (value && value.size <= FILE_SIZE)
        ),
    });

    const initialValues = {
        description: '',
        file: "",
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddAttendeeSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            dispatch(AddFileTodo(bodyFormData)).then(() => add_res(action))
            // console.log(values);
        }
    });

    let bodyFormData = new FormData();
    bodyFormData.append("user_id", localStorage.getItem('id'))
    bodyFormData.append("company_id", localStorage.getItem('customerId'))
    bodyFormData.append("meeting_id", data?._id)
    bodyFormData.append("desc", values.description)
    bodyFormData.append("path", "category")
    bodyFormData.append("file_path", values.file)
  

    const add_res = () => {
        if (AddFileRes?.status) {
            toast.success(AddFileRes?.message, { position: 'bottom-right' })
            setFile(false)
            setBool(!bool)
        } else {
            toast.success(AddFileRes?.message, { position: 'bottom-right' })

        }
    }

    return (
        <>
            <Modal show={file} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Add File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='p-3'>
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className='row'>
                                <div className='col-md-4 '>
                                    <File
                                        name='file'
                                        label_name={'File (file size maximum 2 MB)'}
                                        onBlur={handleBlur}
                                        onChange={(e) => setFieldValue("file", e.currentTarget.files[0])}
                                        error={errors.file && touched.file ? (<span className='text-danger form_label' >{errors.file}</span>) : null}
                                    />

                                </div>
                                <div className='col-md-8'>
                                    <TextArea
                                        label_name='Description'
                                        placeholder=''
                                        rows={'1'}
                                        name='description'
                                        value={values.description || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.description && touched.description ? (<span className='text-danger form_label' >{errors.description}</span>) : null}
                                    />
                                </div>
                            </div>

                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}
