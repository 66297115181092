import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ChatUserListRes;
export const ChatUserListTodo = createAsyncThunk('ChatUserList', async (data) => {
    // console.log(data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'user_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "webtype": "web",
                'department': data?.values ? data?.values?.department : data?.department ? data?.department : '',
                "designation":  '',
                "status":  '',
                "id": data.id === '' ? localStorage.getItem('id') : [localStorage.getItem('id')],
                "company_id": localStorage.getItem('customerId'),
                "type": data.type ? data.type : '',
                "user_id": localStorage.getItem('id') ,
                "chattype": localStorage.getItem('type') === "User" ? "user" : ''
              
            }
        })
        return ChatUserListRes = res.data

    } catch (error) {
        return ChatUserListRes = error.response.data
    }
});

const ChatUserListSlice = createSlice({
    name: 'ChatUserList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ChatUserListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('ChatuserList', state.data)
        });
        builder.addCase(ChatUserListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ChatUserListSlice.reducer; 