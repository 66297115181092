import AdminSidebar from '../sidebar/AdminSidebar'
import React, { useEffect, useState } from 'react';
import { FaRegBell, FaUserCircle } from "react-icons/fa";
import { MdOutlineEmail, MdLogout } from "react-icons/md";
import { IoReorderThree } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import logo from '../../assest/images/rexoteams.png'
import companyLogo from '../../assest/images/deccon_copy.png'
import { useNavigate } from 'react-router-dom';
import UpdatePsw from '../../component/form/admin-form/admin-login/UpdatePsw';

export default function AdminNavbar() {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('../admin/login');
    }
    const [sidebar, setSidebar] = useState(true);
    const [show, setShow] = useState(false)


    const handlePswChnge = () => {
        setShow(true)
    }
    useEffect(() => {
        if (sidebar) {
            var marginAll = document.getElementById("content");
            marginAll && marginAll.classList && marginAll.classList.add("margin-all-page")
        } else {
            var marginAll = document.getElementById("content");
            marginAll.classList.remove("margin-all-page")
        }
    })
  return (
    <>
    <nav style={{ position: 'relative' }}>
                <div className='container-fluid p-0' style={{ position: 'fixed', zIndex: "999", top: '0', backgroundColor: "white" }}>
                    <header className=' ' id='property-header'>
                        <div className="row  ">
                            <div className="col-md-12 ps-3 pe-3">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=" d-flex align-items-center">
                                        <div className='header-logo-section ' id='header-dekstop-logo'>
                                            <img src={logo} className='img-fluid header-logo ' alt="property-logo" />
                                        </div>
                                        <div>
                                            <a type='button' className='sidebar-btn d-none d-lg-block ' style={{ marginTop: "1px" }} onClick={() => setSidebar(!sidebar)} > <IoReorderThree style={{ color: "#105684" }} size={35} /></a>
                                        </div>
                                        <div className='header-logo-section2 ms-2' id='header-dekstop-logo'>
                                            <img src={companyLogo} className='img-fluid header-logo2 ' alt="property-logo" />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='ms-4'>
                                            <div className="dropdown" style={{ marginBottom: "-12px" }}>
                                                <p className=' profile_drop_toggal dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span style={{ fontSize: "18px", fontWeight: '500' }}>Welcome ! {localStorage.getItem('name')} <FaUserCircle size={25} style={{ color: "#e62b40" }} /> </span>
                                                </p>
                                                <ul className="dropdown-menu profile_drop">
                                                    <li><button className="dropdown-item" type='button' onClick={handlePswChnge}><RiLockPasswordLine className=' me-1 ' size={25} style={{ color: '#e62b40' }} /> Change Password</button></li>
                                                    <li><button className="dropdown-item" type='button' onClick={logout} ><MdLogout className=' me-1 ' size={25} style={{ color: '#e62b40' }} /> Logout</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </nav>
            {sidebar && ( <AdminSidebar /> )}
            <UpdatePsw show={show} setShow={setShow}/>
    </>
  )
}
